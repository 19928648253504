import {defineStore} from 'pinia'
import type {AuthInfo} from '@/types/api/auth/auth-info.type'
import type Right from '@/types/api/right.type'
import {RightName} from '@/types/api/right.type'

interface State {
  userRights: Right[]
  rights: Right[]
}

/**
 * store for authorization
 */
export const useRightStore = defineStore('right', {
  // see https://pinia.vuejs.org/core-concepts/state.html
  state: (): State => ({
    // define initial values
    userRights: [] as Right[],
    rights: [] as Right[],
  }),
  // see https://pinia.vuejs.org/core-concepts/getters.html
  getters: {
    // define getters to access compound or calculated data
    hasFilterPermission() {
      this.userRights.filter(right => right.name === RightName.UI_FILTER)
    },
  },
  // see https://pinia.vuejs.org/core-concepts/actions.html
  actions: {

    /**
         * fills the local store
         *
         * @param authInfo
         */
    fillLocalStore(authInfo: AuthInfo) {
      if (authInfo.rights)
        localStorage.setItem('rights', JSON.stringify(authInfo.rights))
    },

    /**
         * resets the store to the initial state.
         */
    clearAuthorization() {
      this.$reset()
    },
  },
})
