import type {Ref} from 'vue'
import {computed, watchEffect} from 'vue'
import {dateFormatter} from '@/utils/formatter'
import type {DataTimestampParams} from '@/api/modules/data-timestamps'
import {useTimestampStore} from '@/stores/timestamp'
import type {DataTimestampModel} from '@/types/api/data-timestamp.type'
import type DataTimestamp from '@/types/api/data-timestamp.type'

// models = ['accounts', 'branches', 'customer_classifications', 'customer_division', 'employees', 'customers',
// 'partners', 'hierarchies', 'materials', 'contracts', 'orders', 'offers', 'invoices']
export function useLatestTimestamp(model?: string) {
  const store = useTimestampStore()

  store.loadLatestTimestamps()

  const timestamps = computed(() => store.latestTimestamps.data)

  const latestTimestamp = computed(() => {
    const date = new Date(Math.max.apply(null, timestamps.value.map((timestamp) => {
      if (timestamp.fetched !== undefined)
        return timestamp.fetched?.getTime()
      else
        return 0
    })))
    return {
      dateString: dateFormatter(date),
      date,
    }
  })

  const latestSpecificImport = computed(() => {
    if (model) {
      for (const timestamp of timestamps.value) {
        if (timestamp.model === model) {
          const date = timestamp.fetched
          return {
            dateString: dateFormatter(date),
            date,
          }
        }
      }
    }
    return null
  })

  return {latestTimestamp, latestSpecificImport}
}

export function useImportTimestamps(
  page?: Ref<number>,
  latest?: Ref<boolean>,
  after?: Ref<Date>,
  before?: Ref<Date>,
  model?: Ref<DataTimestampModel>,
) {
  const store = useTimestampStore()

  const config: DataTimestampParams = {
    page: page?.value,
    pagesize: 1000,
    latest: latest?.value,
    model: model?.value,
    before: before?.value,
    after: after?.value,
  }

  watchEffect(() => {
    store.loadTimestamps(config) // TODO fetch all data
  })

  const loadingTimestamps = computed(() => store.timestamps.pending)
  const timestamps = computed(() => {
    return store.timestamps.data as DataTimestamp[]
  })

  const totalTimestamps = computed(() => store.timestamps.total)

  return {timestamps, totalTimestamps, loadingTimestamps}
}

export function getTodaysDate() {
  return computed(() => {
    const now = new Date()
    now.setHours(0, 0, 0, 0)
    return {
      dateString: dateFormatter(now),
      date: now,
    }
  })
}
