import type {RadioOption} from '@singularit/kurz-components'
import {computed} from 'vue'
import useUrlParam from '@/utils/behaviours/use-url-param'
import {radioOptionValueReverseMapping} from '@/utils/url/url-from-string'

export default function useAcuteness(
  onGet?: (value?: number) => number | null,
  onSet?: (value?: number) => void) {
  const acutenessOptions: Array<RadioOption> = [
    {label: 'PH1', value: 1},
    {label: 'PH2', value: 2},
    {label: 'PH3', value: 3},
  ]

  const acutenessDefault = acutenessOptions[0].value
  const selectedLevel = useUrlParam(
    'hsa',
    (number: number) => number.toString(),
    radioOptionValueReverseMapping(acutenessOptions),
    acutenessDefault)

  const selectedAcuteness = computed({
    get() {
      let result = selectedLevel.value
      if (onGet) {
        const temp = onGet(result)
        if (temp)
          result = temp
      }
      return result
    },
    set(value?: number) {
      if (value) selectedLevel.value = value
      if (onSet)
        onSet(value)
    },
  })

  return {acutenessOptions, selectedAcuteness, acutenessDefault}
}
