<template>
  <div class="h-full flex flex-col overflow-hidden">
    <div class="flex">
      <h1 class="page-head">
        Kunden
      </h1>
      <ImportTimestamp
        class="items-center mr-4 ml-auto"
        dependant-on="customers"
      />
    </div>
    <k-card>
      <template #title>
        <div class="card-head flex">
          <span class="flex items-center">
            <h2>Alle Kunden</h2>
          </span>
          <div class="w-10 mt-1 flex items-center justify-center cursor-pointer">
            <font-awesome-icon
              :icon="[isFilterActive ? 'fas' : 'far', 'filter']"
              class="text-xl self-center"
              @click="showFilter = true"
            />
          </div>
        </div>
      </template>
      <k-table
        v-model:page="activePage"
        class="flex-1"
        :head="customerTableHeads"
        :data="customers"
        :total-rows="totalCustomers"
        :searchable="true"
        :data-loading="loadingList"
        async
        @reload="reload"
        @change-page="activePage = $event"
        @row-select="openDetailView"
      >
        <template #branch-slot="{ content }">
          <span v-if="!content.branch">-</span>
          <span
            v-else
            class="whitespace-nowrap"
          >
            {{ typeof content.branch === "number" ? content.branch : content.branch.name }}
          </span>
        </template>
        <template #partner-slot="{ content }">
          <span
            v-if="content.partners && content.partners.length > 0"
            class="whitespace-nowrap"
          >
            {{ partnerString(content) }}
          </span>
          <span v-else>-</span>
        </template>
        <template #my-customers-filter="{ filter }">
          <div>
            {{ filter.displayText }}
            <input
              id="customer-filter"
              v-model="myCustomersFilterActive"
              type="checkbox"
              class="ml-5"
            >
          </div>
        </template>
      </k-table>
    </k-card>
    <k-modal
      :modal-active="showFilter"
      @close="showFilter=false"
    >
      <template #head>
        <h1 class="page-head">
          Filter
        </h1>
      </template>
      <hr>
      <div
        v-if="filtersLoading"
        class="flex justify-center py-10"
      >
        <k-kurz-dot-loader />
      </div>
      <div
        v-else
        class="flex flex-col pb-5"
      >
        <div class="filter-list flex flex-col gap-5 py-5">
          <div class="flex gap-5">
            <k-kurz-multi-select
              v-model="filteredIds"
              :options="idOptions"
              placeholder="IDs"
              clearable
            />
            <info-toggle
              v-model="includeIds"
              class="self-center"
              :options="includeToggleOptions"
              :prefix-label="includeToggleOptions.falseLabel"
              :suffix-label="includeToggleOptions.trueLabel"
              off-color="bg-blue-kurz-600"
            />
          </div>
          <div class="flex gap-5">
            <k-kurz-multi-select
              v-model="filteredBranches"
              :options="branchOptions"
              placeholder="Branches"
              clearable
            />
            <info-toggle
              v-model="includeBranches"
              class="self-center"
              :options="includeToggleOptions"
              :prefix-label="includeToggleOptions.falseLabel"
              :suffix-label="includeToggleOptions.trueLabel"
              off-color="bg-blue-kurz-600"
            />
          </div>
          <div class="flex gap-5">
            <k-kurz-multi-select
              v-model="filteredCountries"
              :options="countryOptions"
              placeholder="Länder"
              clearable
            />
            <info-toggle
              v-model="includeCountries"
              class="self-center"
              :options="includeToggleOptions"
              :prefix-label="includeToggleOptions.falseLabel"
              :suffix-label="includeToggleOptions.trueLabel"
              off-color="bg-blue-kurz-600"
            />
          </div>
          <hr>
          <div class="flex flex-row gap-5">
            <k-select-input
              v-model="responsibleEmployee"
              searchable
              class="mt-3"
              :options="employeeOptions"
              :disabled="myCustomersFilterActive"
              placeholder="zuständiger Mitarbeiter"
              clearable
            />
            <k-tooltip
              class="self-center"
              text="Nur aktiv, wenn der 'meine Kunden' Filter inaktiv ist"
            />
          </div>
          <hr>
          <div class="flex flex-row gap-20 ml-1">
            <label>
              <input
                id="my-customer-filter"
                v-model="myCustomersFilterActive"
                type="checkbox"
              >
              <span>
                Nur meine Kunden anzeigen
              </span>
            </label>
            <label>
              <input
                id="archived-customers-filter"
                v-model="showArchived"
                type="checkbox"
              >
              <span>
                Archivierte Kunden anzeigen
              </span>
            </label>
          </div>
        </div>
        <hr>
        <div class="flex justify-between pt-5">
          <k-kurz-button
            text="Filter laden"
            @click="setUserFilter"
          />
          <k-kurz-button
            text="Filter speichern"
            @click="saveFilter"
          />
        </div>
      </div>
    </k-modal>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, ref, unref, watchEffect} from 'vue'
import {useRouter} from 'vue-router'
import {customerTableHeads} from '@/views/customer/customer-table-heads'
import useTableQuery from '@/utils/use-table-query'
import type {CustomerListQLParams} from '@/api/modules/customers'
import ImportTimestamp from '@/components/timestamp/ImportTimestamp.vue'
import {useCustomerStore} from '@/stores/customer'
import type Customer from '@/types/api/customer.type'
import {useAuthStore} from '@/stores/auth'
import useIncludeExcludeFilters from '@/utils/use-include-exclude-filters'
import useUiFilter from '@/utils/use-ui-filter'
import InfoToggle from '@/components/toggle/InfoToggle.vue'
import usePartnersFilter from '@/views/customer/use-partners-filter'
import useUrlParam from '@/utils/behaviours/use-url-param'

export default defineComponent({
  name: 'CustomersView',
  components: {InfoToggle, ImportTimestamp},
  setup() {
    const store = useCustomerStore()
    const authStore = useAuthStore()
    const router = useRouter()

    const showFilter = ref(false)

    const {
      filteredIds, includeIds, filteredBranches, includeBranches, filteredCountries, includeCountries,
      idOptions, branchOptions, countryOptions, includeToggleOptions, includeExcludeConfig,
      filtersLoading: includeExcludeFiltersLoading,
    } = useIncludeExcludeFilters()

    const {employees, responsibleEmployee, employeeOptions, employeesLoading} = usePartnersFilter()

    const filtersLoading = computed(() => {
      return includeExcludeFiltersLoading.value || employeesLoading.value
    })

    const isFilterActive = computed(() => {
      return filteredIds.value.length > 0 || filteredBranches.value.length > 0
          || filteredCountries.value.length > 0 || responsibleEmployee.value !== undefined
    })

    const myCustomersFilterActive = useUrlParam(
      'mycu',
      (value: boolean) => value.toString(),
      (string: string | string[]) => {
        return string.includes('true')
      },
      false,
    )

    // const noOptionsText = 'im Moment ist dieser Filter noch nicht funktionsfähig'

    const currentUserEmployeeId = computed(() => {
      const accountId = authStore.getAccountId
      const currentUserEmployee = employees.value.filter((employee) => {
        return employee.account === accountId
      })
      return currentUserEmployee.length === 1 ? currentUserEmployee[0].id : undefined
    })

    const showArchived = useUrlParam(
      'arch',
      (value: boolean) => value.toString(),
      (string: string | string[]) => {
        return string.includes('true')
      },
      false,
    )

    const activePage = ref(1)
    const {query, reload} = useTableQuery()
    const tableElements = '{ id, name, partners { employee }, branch, country_code }'
    const config = computed(() => {
      const config: CustomerListQLParams = {
        page: unref(activePage),
        tableQuery: unref(query),
        query: tableElements,
        archived: showArchived.value,
        partners: myCustomersFilterActive.value ? currentUserEmployeeId.value : responsibleEmployee.value?.id,
      }
      return {...config, ...includeExcludeConfig.value}
    })

    watchEffect(() => {
      store.loadCustomers(config.value)
    })

    const loadingList = computed(() => {
      return store.customers.pending
    })

    const customers = computed(() => {
      return store.customers.data as Customer[]
    })

    const totalCustomers = computed(() => {
      return store.customers.total
    })

    // TODO Abhängigkeit von Rolle des Angestellten
    function partnerString(customer: Customer) {
      if (customer.partners && customer.partners.length > 1 && customer.partners[0].employee)
        return `${customer.partners[0].employee.firstName} ${customer.partners[0].employee.lastName} und ${customer.partners.length - 1} weitere`

      else if (customer.partners && customer.partners.length === 1 && customer.partners[0].employee)
        return `${customer.partners[0].employee.firstName} ${customer.partners[0].employee.lastName}`
    }

    function openDetailView(data: Customer) {
      router.push({
        name: 'customer-detail',
        params: {
          customerId: data.id,
        },
      })
    }

    const {setUserFilter, saveFilter, loadFilter, hasFilterPermission} = useUiFilter('customer-view')

    return {
      loadingList,
      activePage,
      customers,
      totalCustomers,
      openDetailView,
      customerTableHeads,
      partnerString,
      reload,
      showFilter,
      isFilterActive,
      includeToggleOptions,
      filteredIds,
      includeIds,
      filteredBranches,
      includeBranches,
      filteredCountries,
      includeCountries,
      responsibleEmployee,
      myCustomersFilterActive,
      showArchived,
      filtersLoading,

      idOptions,
      branchOptions,
      countryOptions,
      employeeOptions,

      setUserFilter,
      saveFilter,
      loadFilter,
      hasFilterPermission,
    }
  },
})
</script>
