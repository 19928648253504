<template>
  <div
    id="app"
    class="h-screen bg-lightwhite overflow-hidden"
  >
    <span v-if="!authenticated">
      <Login
        v-if="login"
        @redirect="login = !login"
      />
      <Register
        v-else
        @redirect="login = !login"
      />
    </span>
    <span v-else>
      <k-nav-bar
        :entries="navigationEntries"
        :active-route="activeRoute"
      >
        <template #footer>
          <div class="flex justify-between mr-3">
            <button @click="logout">
              Logout
            </button>
            <!--            <k-kurz-button-->
            <!--              text="Logout"-->
            <!--              no-style-->
            <!--              @clicked="logout()"-->
            <!--            />-->
            <font-awesome-icon
              icon="sign-out-alt"
              class="opacity-50"
            />
          </div>
        </template>
      </k-nav-bar>
      <div class="h-full ml-16 overflow-y-auto overflow-x-hidden">
        <router-view />
      </div>
    </span>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, ref, watchEffect} from 'vue'
import {useRoute} from 'vue-router'
import Login from '@/views/LoginView.vue'
import Register from '@/views/RegisterView.vue'
import {calcChurnEntry} from '@/utils/use-churn-months'
import {navEntries} from '@/router'
import {useAuthStore} from '@/stores/auth'
import {useRightStore} from '@/stores/right'
import {useUiFilterStore} from '@/stores/ui-filter'
import type {UiFilterParams} from '@/api/modules/ui-filter'

export default defineComponent({
  name: 'App',
  components: {Login, Register},
  setup() {
    const authStore = useAuthStore()
    const rightStore = useRightStore()
    const uiFilterStore = useUiFilterStore()

    const login = ref(true)

    // initially load authentication from store
    authStore.loadAuthFromLocalStore()

    const filterConfig = computed(() => {
      const config: UiFilterParams = {
        user: authStore.getAccountId,
      }
      return config
    })

    uiFilterStore.loadLocalUIFilters()
    // load ui filters when auth store is ready
    watchEffect(() => {
      if (filterConfig.value.user !== undefined)
        uiFilterStore.loadUserUiFilters(filterConfig.value)
    })

    const authenticated = computed(() => {
      return authStore.isAuthenticated
    })

    function logout() {
      authStore.logout()
    }

    const userRights = computed(() => {
      return rightStore.userRights
    })

    const navigationEntries = computed(() => {
      const navEnt = navEntries(userRights.value)
      navEnt.push(
        calcChurnEntry(),
      )
      return navEnt
    })

    const activeRoute = computed(() => {
      const path = useRoute().path
      if (path) {
        if (path === '/')
          return '/vkl-dashboard'
        else if (path.includes('churn'))
          return path
        else
          return `/${path.split('/')[1]}`
      }
      else {
        return ''
      }
    })

    return {authenticated, logout, login, navigationEntries, activeRoute, filterConfig}
  },

})
</script>

<style>
@import '/src/assets/styles/index.css';
/*@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400&display=swap');*/

#app {
  font-family: 'Inter', sans-serif;
}
</style>
