import {ObjectTableHead, SlotTableHead} from '@singularit/kurz-components'
import {dateFormatter, tableCurrencyFormatter} from '@/utils/formatter'

const invoicesTableHeads = [
  new ObjectTableHead({
    title: 'Beleg',
    attribute: 'receipt',
    sortable: true,
    id: 1,
  }),

  new ObjectTableHead({
    title: 'Pos.',
    attribute: 'position',
    id: 2,
  }),
  new SlotTableHead({
    title: 'Warenempfänger',
    sortable: true,
    id: 3,
    sortKey: 'customer__name',
    slotName: 'customer-slot',
  }),

  new SlotTableHead({
    title: 'Material',
    sortable: true,
    id: 4,
    sortKey: 'material__material_description',
    slotName: 'material-slot',
  }),
  new SlotTableHead({
    title: 'Menge',
    slotName: 'quantity-slot',
    sortKey: 'quantity',
    id: 5,
  }),
  new ObjectTableHead({
    title: 'Umsatz',
    attribute: 'revenue',
    sortable: true,
    id: 6,
    formatter: tableCurrencyFormatter,
  }),
  new ObjectTableHead({
    title: 'Datum',
    attribute: 'invoiceDate',
    sortable: true,
    sortKey: 'invoice_date',
    id: 7,
    formatter: dateFormatter,
    alignment: 'right',
  }),
]

export {invoicesTableHeads}
