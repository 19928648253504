import {computed, ref, unref, watchEffect} from 'vue'
import type {AccountListParams} from '@/api/modules/accounts'
import {useAccountStore} from '@/stores/account'
import type Account from '@/types/api/account.type'
import useTableQuery from '@/utils/use-table-query'

export default function useAccountList(
) {
  const store = useAccountStore()

  const activePage = ref(1)
  const {query, reload} = useTableQuery()

  const config = computed(() => {
    const config: AccountListParams = {
      page: unref(activePage),
      query: unref(query),
    }
    return config
  })

  watchEffect(() => {
    store.loadAccounts(config.value)
    // store.dispatch(ActionTypes.GET_ACCOUNTS, config.value)
  })

  const loadingAccounts = computed(() => store.accounts.pending)
  const accounts = computed(() => {
    return store.accounts.data as Account[] // TODO
  })

  const totalAccounts = computed(() => {
    return store.accounts.total
  })

  return {
    accounts,
    totalAccounts,
    activePage,
    loadingAccounts,
    reload,
  }
}
