export interface AggregationParams {
  orderBy?: string
  groupBy?: string
  aggregation: AggregationFunction
  aggregationField?: string
}

export enum AggregationFunction {
  SUM = 'sum',
}
