import {defineStore} from 'pinia'
import api from '@/api'
import type {EmployeeListParams} from '@/api/modules/employees'
import {PaginatedData} from '@/stores/index'
import type Employee from '@/types/api/employee.type'

interface State {
  employees: PaginatedData<Employee>
}

export const useEmployeeStore = defineStore('employee', {
  // see https://pinia.vuejs.org/core-concepts/state.html
  state: (): State => ({
    // define initial values
    employees: new PaginatedData<Employee>(),
  }),
  // see https://pinia.vuejs.org/core-concepts/getters.html
  getters: {
    // define getters to access compound or calculated data
  },
  // see https://pinia.vuejs.org/core-concepts/actions.html
  actions: {

    /**
         * load employees
         * @param {EmployeeListParams} config - the config for the call
         * @param cancel
         * @param requestID
         */
    async loadEmployees(config: EmployeeListParams, cancel = true, requestID?: string) {
      const id = requestID || api.employees.getEmployees.name
      if (cancel)
        api.employees.cancel(id)
      this.employees.pending = true
      return api.employees.cancelableCall(api.employees.getEmployees, config, id)
        .then((data) => {
          const page = data.data
          this.employees.total = page.count
          this.employees.data = page.results
          if (!api.employees.hasPending(id))
            this.employees.pending = false
        }).catch((error) => {
          if (!api.employees.hasPending(id))
            this.employees.pending = false
          throw error
        })
    },

    /**
         * resets the store to the initial state.
         */
    clearEmployees() {
      this.$reset()
    },
  },
})
