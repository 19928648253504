<template>
  <div class="flex flex-col w-full">
    <div class="flex">
      <h1
        class="page-head"
      >
        Angebot {{ offerReceipt }}
      </h1>
      <ImportTimestamp
        class="items-center mr-4 ml-auto"
        dependant-on="offers"
      />
    </div>
    <div
      v-if="offer"
      class="offer-details-layout grid grid-cols-2"
    >
      <k-card class="col-span-2 lg:col-span-1">
        <template #title>
          <span class="card-head">
            <h2>Allgemeine Informationen</h2>
          </span>
        </template>
        <div
          v-if="loadingData"
          class="h-32 w-full flex justify-center"
        >
          <k-kurz-dot-loader class="self-center" />
        </div>
        <k-list-layout
          v-else
          class="flex-1"
        >
          <k-list-entry
            label="Kunde"
            :value="offer?.customer"
          >
            <div class="text-left w-1/2">
              <CustomerLink :customer="offer.customer" />
            </div>
          </k-list-entry>

          <k-list-entry
            label="Kontrakt"
            :value="offer?.contract"
          >
            <div class="text-left w-1/2">
              <ContractLink :contract="offer.contract" />
            </div>
          </k-list-entry>

          <k-list-entry
            label="Erstellungsdatum"
            :value="offer?.creationDate"
          >
            <div class="text-left w-1/2">
              <span>{{ dateFormatter(offer.creationDate) }} </span>
            </div>
          </k-list-entry>
        </k-list-layout>
      </k-card>
      <k-card class="col-span-2 lg:col-span-1">
        <template #title>
          <span class="card-head">
            <h2>Allgemeine Informationen</h2>
          </span>
        </template>
        <div
          v-if="loadingData"
          class="h-32 w-full flex justify-center"
        >
          <k-kurz-dot-loader class="self-center" />
        </div>
        <k-list-layout
          v-else
          class="flex-1"
        >
          <k-list-entry
            label="Geplanter Umsatz"
            :value="offer?.value"
          >
            <div class="text-left w-1/2">
              <span>{{ currencyFormatter.format(offer.value) }}</span>
            </div>
          </k-list-entry>
          <k-list-entry
            label="Laufzeit Ende"
            :value="offer?.end"
          >
            <div class="text-left w-1/2">
              <span>{{ dateFormatter(offer.end) }} </span>
            </div>
          </k-list-entry>
          <k-list-entry
            label="Laufzeit Beginn"
            :value="offer?.start"
          >
            <div class="text-left w-1/2">
              <span>{{ dateFormatter(offer.start) }} </span>
            </div>
          </k-list-entry>
        </k-list-layout>
      </k-card>
    </div>
    <OfferPositionsCard :offer-receipt="offerReceipt" />
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, watchEffect} from 'vue'
import {useRouter} from 'vue-router'
import {currencyFormatter, dateFormatter} from '@/utils/formatter'
import CustomerLink from '@/components/links/CustomerLink.vue'
import ImportTimestamp from '@/components/timestamp/ImportTimestamp.vue'
import {useOfferStore} from '@/stores/offer'
import type {OffersAggregationParams} from '@/api/modules/offers'
import {AggregationFunction} from '@/api/params/aggregation.type'
import OfferPositionsCard from '@/components/cards/OfferPositionsCard.vue'

export default defineComponent({
  name: 'OffersOverview',
  components: {ImportTimestamp, CustomerLink, OfferPositionsCard},
  props: {
    offerReceipt: {
      type: Number,
      required: true,
    },
  },

  setup(props) {
    const store = useOfferStore()
    const router = useRouter()

    const aggregateConfig = computed(() => {
      const config: OffersAggregationParams = {
        aggregationField: 'revenue',
        aggregation: AggregationFunction.SUM,
        groupBy: 'customer,receipt,end,start,creation_date,contract',
        receipt: props.offerReceipt,
      }
      return config
    })

    watchEffect(() => {
      store.loadOfferAggregate(aggregateConfig.value).then(() => {
        if (store.aggregates.data.length === 0) router.back()
      })
    })

    const loadingData = computed(() => store.aggregates.pending)

    const offer = computed(() => {
      if (store.aggregates.total === 1)
        return store.aggregates.data[0]
      else
        return null
    })

    return {
      offer,
      loadingData,

      dateFormatter,
      currencyFormatter,
    }
  },
})
</script>

<style scoped>
.offer-details-layout {
  @apply flex flex-col;
  @apply xl:grid xl:grid-cols-2 xl:gap-4;
  @apply xl:overflow-y-hidden;
  @apply xl:h-full;
}
</style>
