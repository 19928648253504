import type Hierarchy from '@/types/api/hierarchy.type'

const numberFormatter = new Intl.NumberFormat('de-DE', {useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 3})
const currencyFormatter = new Intl.NumberFormat('de-DE', {
  style: 'currency',
  currency: 'EUR',
  maximumFractionDigits: 2,
})
const quantityFormatter = new Intl.NumberFormat('de-DE', {
  useGrouping: true, minimumFractionDigits: 3, maximumFractionDigits: 3,
})

const unitFormatter = (value?: string) => {
  let result = value
  if (value === undefined)
    result = ''

  else if (value === 'M2')
    result = '\u33A1'

  return result
}

const tableCurrencyFormatter = (value?: number, undefinedAsZero = true): string | null => {
  let formatted = null
  if (value !== undefined)
    formatted = currencyFormatter.format(value)

  if (undefinedAsZero && formatted === null)
    formatted = '-'

  return formatted
}
const tableUnitFormatter = (value?: number, undefinedAsZero = true): string | null => {
  let formatted = null
  if (value !== undefined)
    formatted = numberFormatter.format(value)

  if (undefinedAsZero && formatted === null)
    formatted = '-'

  return formatted
}

const dateFormatter = (date?: Date, options: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
}): string => {
  let formatted = '-'
  if (date !== undefined)
    formatted = date.toLocaleDateString('de-DE', options)
  else if (date)
    formatted = new Date(date).toLocaleDateString('de-DE', options)

  return formatted
}
const dateParamFormatter = (value: Date): string => {
  if (value)
    return value.toLocaleDateString('en-CA')

  else return ''
}

/**
 * @deprecated use fullNumber attribute instead
 *
 * @param hierarchy
 */
const getFullHierarchyNumber = (hierarchy: Hierarchy): string | undefined => {
  let number
  if (hierarchy) {
    number = `${hierarchy.number}`
    let superiorHierarchy = hierarchy.superior
    while (superiorHierarchy) {
      number = superiorHierarchy.number + number
      superiorHierarchy = superiorHierarchy.superior
    }
  }
  return number
}

const getHierarchyLabel = (hierarchy: Hierarchy): string => {
  return `${hierarchy.name ? hierarchy.name : '-'} (${hierarchy.fullNumber})`
}

export {
  numberFormatter,
  tableCurrencyFormatter,
  getFullHierarchyNumber,
  getHierarchyLabel,
  currencyFormatter,
  quantityFormatter,
  unitFormatter,
  tableUnitFormatter,
  dateFormatter,
  dateParamFormatter,
}
