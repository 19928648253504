import type {AxiosRequestConfig, AxiosResponse} from 'axios'
import Qs from 'qs'
import axiosInstance from '@/api/axios'
import type LowPerformer from '@/types/api/dashboard/low-performer.type'
import type Page from '@/types/api/page.type'
import type HierarchyStat from '@/types/api/dashboard/hierarchy-stats.type'
import type PaginationListParameters from '@/api/modules/pagination-list-parameters'
import type BenchmarkChart from '@/types/api/dashboard/benchmark-chart.type'
import BaseModule from '@/api/modules/base-module'
import type QueryParameters from '@/api/modules/query-parameters'
import type VKOverview from '@/types/api/dashboard/vk-overview.type'

export interface HierarchyParams {
  hierarchies1?: number[]
  hierarchies2?: number[]
  hierarchies3?: number[]
}

export interface SalesOrganizationParams {
  salesOrganization?: number
}

export interface CountryCodeParams {
  country?: string
}

export interface DashboardParams extends PaginationListParameters, HierarchyParams, SalesOrganizationParams, CountryCodeParams {
}

export interface LowPerformerParams extends DashboardParams, QueryParameters {
  threshold: number
  timespan: string
  filtering: string
  date?: Date
}

export interface BenchmarkParams extends DashboardParams {
  timespan?: string
  unit: string
  benchmark?: string
  cumulate: boolean
  date?: Date
}

export interface HierarchyStatParams extends DashboardParams, QueryParameters {
  acuteness: number
  monthly: boolean
}

export interface VKOverviewParams extends HierarchyParams, SalesOrganizationParams, CountryCodeParams {
  timespan: 'month' | 'week' | 'year'
  date: Date | string
}

class DashboardModule extends BaseModule {
  getLowPerformers(params: LowPerformerParams, controller?: AbortController): Promise<AxiosResponse<Page<LowPerformer>>> {
    const signal = controller ? controller.signal : undefined

    const data: AxiosRequestConfig = {
      params,
      signal,
      paramsSerializer: params => Qs.stringify(params, {arrayFormat: 'repeat', format: 'RFC1738'}),
    }
    return axiosInstance.get('/dashboards/performance', data)
  }

  getBenchmarks(params: BenchmarkParams, controller?: AbortController): Promise<AxiosResponse<BenchmarkChart>> {
    const signal = controller ? controller.signal : undefined

    const data: AxiosRequestConfig = {
      params,
      signal,
      paramsSerializer: params => Qs.stringify(params, {arrayFormat: 'repeat'}),
    }
    return axiosInstance.get('/dashboards/benchmark', data)
  }

  getHierarchiesStats(params: HierarchyStatParams, controller?: AbortController): Promise<AxiosResponse<Page<HierarchyStat>>> {
    const signal = controller ? controller.signal : undefined

    const data: AxiosRequestConfig = {
      params,
      signal,
      paramsSerializer: params => Qs.stringify(params, {arrayFormat: 'repeat'}),
    }
    return axiosInstance.get('/dashboards/hierarchy-stats', data)
  }

  getOverview(params: VKOverviewParams, controller?: AbortController): Promise<AxiosResponse<VKOverview>> {
    const signal = controller ? controller.signal : undefined
    const data = {
      params,
      signal,
      paramsSerializer: (params: VKOverviewParams) => Qs.stringify(params, {arrayFormat: 'repeat'}),
    }
    return axiosInstance.get('dashboards/overview', data)
  }
}

const module = new DashboardModule()
export default module
