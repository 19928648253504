import type {AxiosResponse} from 'axios'
import type PaginationListParameters from '@/api/modules/pagination-list-parameters'
import axiosInstance from '@/api/axios'
import type Page from '@/types/api/page.type'
import type {DataTimestampModel} from '@/types/api/data-timestamp.type'
import type DataTimestamp from '@/types/api/data-timestamp.type'
import BaseModule from '@/api/modules/base-module'

export interface DataTimestampParams extends PaginationListParameters {
  latest?: boolean
  model?: DataTimestampModel // TODO change to DataTimestampModel
  after?: Date
  before?: Date
}

class TimestampModule extends BaseModule {
  getTimestamps(params: DataTimestampParams, controller?: AbortController): Promise<AxiosResponse<Page<DataTimestamp>>> {
    const signal = controller ? controller.signal : undefined
    const after = params.after
    delete params.after

    const before = params.before
    delete params.before

    const parameter = {
      fetched__gte: after,
      fetched__lte: before,
      ...params,
      ordering: '-fetched',
    }
    const data = {
      params: parameter,
      signal,
    }
    return axiosInstance.get('/data-imports/', data).then((response: AxiosResponse) => {
      return response
    })
  }

  getLatestTimestamps(): Promise<AxiosResponse<Page<DataTimestamp>>> {
    const data = {
      params: {
        latest: true,
      },
    }
    return axiosInstance.get('/data-imports/', data).then((response: AxiosResponse) => {
      return response
    })
  }
}

const module = new TimestampModule()
export default module
