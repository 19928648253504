import {FeedbackChannel, FeedbackGender, FeedbackResult} from '@/types/api/feedback.type'

export function feedbackResultString(result: FeedbackResult) {
  let string = null
  switch (result) {
    case FeedbackResult.HIGH_PRICE:
      string = 'Zu hoher Preis'
      break
    case FeedbackResult.SERVICE:
      string = 'Schlechter Service'
      break
    case FeedbackResult.REACTIONTIME:
      string = 'Langsame Reaktionszeit'
      break
    case FeedbackResult.REACHABILITY:
      string = 'Schlechte Erreichbarkeit'
      break
    case FeedbackResult.ADHERENCE:
      string = 'Fehlende Termintreue'
      break
    case FeedbackResult.FLEXIBILITY:
      string = 'Mangelnde Flexibilität'
      break
    case FeedbackResult.CHANGING_CONTACT:
      string = 'Wechsel des Ansprechpartners'
      break
    case FeedbackResult.COMPLAINS_PROCESSING:
      string = 'Schlechte Reklamationsbearbeitung'
      break
    case FeedbackResult.CHEAPER_COMPETITION:
      string = 'Wettbewerb ist günstiger'
      break
    case FeedbackResult.COMPETITION_ENTICEMENT:
      string = 'Aktives Abwerben durch Wettbewerber'
      break
    case FeedbackResult.EXCHANGE_OFFER:
      string = 'Attraktives Wechselangebot'
      break
    case FeedbackResult.TECHNOLOGY:
      string = 'Einsatz anderer Technologie'
      break
    case FeedbackResult.MISCELLANEOUS:
      string = 'Sonstiges'
      break
  }
  return string
}

export function feedbackGenderString(result: FeedbackGender | number) {
  let string
  switch (result) {
    case FeedbackGender.MALE:
      string = 'Herr'
      break
    case FeedbackGender.FEMALE:
      string = 'Frau'
      break
    case FeedbackGender.DIVERS:
      string = 'Divers'
      break
    case FeedbackGender.UNSPECIFIED:
      string = 'Nicht angegeben'
      break
  }
  return string
}

export function feedbackChannelString(result: FeedbackChannel) {
  let string = null
  switch (result) {
    case FeedbackChannel.PHONE:
      string = 'Telefon'
      break
    case FeedbackChannel.EMAIL:
      string = 'E-Mail'
      break
    case FeedbackChannel.IN_PERSON:
      string = 'In Person'
      break
  }
  return string
}
