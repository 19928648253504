import type {RadioOption} from '@singularit/kurz-components'
import {computed} from 'vue'
import {FeedbackChannel, FeedbackGender, FeedbackResult} from '@/types/api/feedback.type'
import {feedbackChannelString, feedbackGenderString, feedbackResultString} from '@/views/feedback/feedback-mapping'
import useAllAccounts from '@/utils/behaviours/use-all-accounts'

export default function useFeedbackOptions() {
  const genderOptions: Array<RadioOption> = []
  for (const gender in FeedbackGender) {
    if (!isNaN(Number(gender)))
      genderOptions.push({label: feedbackGenderString(Number(gender)), value: Number(gender)})
  }

  const channelOptions: Array<RadioOption> = []
  for (const channel in FeedbackChannel) {
    if (!isNaN(Number(channel)))
      channelOptions.push({label: feedbackChannelString(Number(channel)), value: Number(channel)})
  }

  const resultOptions: Array<RadioOption> = []
  for (const result in FeedbackResult) {
    if (!isNaN(Number(result)))
      resultOptions.push({label: feedbackResultString(Number(result)), value: Number(result)})
  }

  const users = useAllAccounts()
  const userOptions = computed(() => {
    const list: RadioOption[] = []
    if (users.value) {
      for (const user of users.value) {
        list.push({
          label: `${user.username}`,
          value: user.id,
        })
      }
    }
    return list
  })
  return {
    genderOptions,
    resultOptions,
    channelOptions,
    userOptions,
  }
}
