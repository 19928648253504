import type Customer from '@/types/api/customer.type'
import type Churn from '@/types/api/churn.type'
import CreateUpdateModel from '@/types/api/create-update-model.type'
import type Account from '@/types/api/account.type'

export enum FeedbackChannel {
  EMAIL = 1,
  PHONE,
  IN_PERSON,
}

export enum FeedbackGender {
  MALE = 1,
  FEMALE,
  DIVERS,
  UNSPECIFIED,
}

export enum FeedbackResult {
  HIGH_PRICE = 1,
  SERVICE,
  REACTIONTIME,
  REACHABILITY,
  ADHERENCE,
  FLEXIBILITY,
  CHANGING_CONTACT,
  COMPLAINS_PROCESSING,
  CHEAPER_COMPETITION,
  COMPETITION_ENTICEMENT,
  EXCHANGE_OFFER,
  TECHNOLOGY,
  MISCELLANEOUS,
}

export default class Feedback extends CreateUpdateModel {
  id?: number
  customer?: Partial<Customer>
  creator?: Account
  author?: Account
  churn?: Churn
  channel: FeedbackChannel = FeedbackChannel.EMAIL
  gender: FeedbackGender = FeedbackGender.UNSPECIFIED
  firstName?: string
  lastName?: string
  result?: FeedbackResult
  comment?: string
}
