import {defineStore} from 'pinia'
import type {AxiosResponse} from 'axios'
import api from '@/api'
import type {LoginParams, RegisterParams} from '@/api/modules/auth'
import {useRightStore} from '@/stores/right'
import type {AuthInfo} from '@/types/api/auth/auth-info.type'
import type Account from '@/types/api/account.type'

interface State {
  token?: string | null
  account?: Account
}

/**
 * Store for authentication. Also sets authorization(rights) store values
 */
export const useAuthStore = defineStore('auth', {
  // see https://pinia.vuejs.org/core-concepts/state.html
  state: (): State => ({
    // define initial values
    token: undefined,
    account: undefined,
  }),
  // see https://pinia.vuejs.org/core-concepts/getters.html
  getters: {
    // define getters to access compound or calculated data
    isAuthenticated: (state) => {
      return state.token !== undefined && state.token !== null
    },
    getAccountId(state) {
      return state?.account?.id
    },

  },
  // see https://pinia.vuejs.org/core-concepts/actions.html
  actions: {

    /**
         * login
         * @param config - the config for the call
         */
    async login(config: LoginParams) {
      return api.auth.login(config)
        .then((data) => {
          const info = data.data
          this.updateAuth(info)
          return data
        }).catch((error) => {
          console.error(error)
          throw error
        })
    },

    /**
         * login
         * @param {RegisterParams} config - the config for the call
         */
    async register(config: RegisterParams): Promise<AxiosResponse> {
      return api.auth.register(config)
        .then((response) => {
          // const info = res.data
          // this.updateAuth(info)
          return response
        }).catch((error) => {
          console.error(error)
          throw error
        })
    },

    /**
         * updates the token, user and userRights of the right store
         *
         * @param authInfo
         */
    updateAuth(authInfo: AuthInfo) {
      this.fillLocalStore(authInfo)
      this.$patch({
        token: authInfo.token,
        account: authInfo.account,
      })

      const rightStore = useRightStore()
      rightStore.fillLocalStore(authInfo)
      rightStore.userRights = authInfo.rights
    },

    /**
         * logs the current user out
         */
    async logout() {
      return api.auth.logout()
        .then(() => {
          const rightStore = useRightStore()
          rightStore.clearAuthorization()
          this.clearAuthentication()
        }).catch((error) => {
          console.error(error)
          return error
        })
    },

    /**
         * updates the store
         * @param authInfo -the data to update the state
         */
    fillLocalStore(authInfo: AuthInfo) {
      if (authInfo.token !== undefined)
        localStorage.setItem('token', authInfo.token)

      if (authInfo.account !== undefined)
        localStorage.setItem('account', String(JSON.stringify(authInfo.account)))
    },

    /**
         * clears the local store
         */
    clearLocalStore() {
      localStorage.clear()
    },

    /**
         *
         */
    loadAuthFromLocalStore() {
      // load token
      const localToken = localStorage.getItem('token')

      // load id
      let account
      const accountJson = localStorage.getItem('account')
      if (accountJson !== null) {
        try {
          account = JSON.parse(accountJson) as Account
        }
        catch (e) {
          console.error(e)
        }
        this.$patch({
          token: localToken,
          account,
        })

        // load rights
        const rightsString = localStorage.getItem('rights')
        if (rightsString !== null) {
          try {
            let rights = JSON.parse(rightsString)
            if (!rights)
              rights = []

            const rightStore = useRightStore()
            rightStore.userRights = rights
          }
          catch (e) {
            console.error(e)
          }
        }
      }
    },

    /**
         * resets the store to the initial state.
         */
    clearAuthentication() {
      this.$reset()
      this.clearLocalStore()
    },
  },
})
