<template>
  <div class="flex justify-center pt-32">
    <k-card
      :header="false"
      class="lg:w-1/4 lg:h-1/3 px-4"
    >
      <div class="flex-auto px-4 lg:px-10 mt-10">
        <div class="relative w-full mb-3">
          <label class="block uppercase text-gray-700 text-xs font-bold mb-2">Vorname</label><input
            v-model="registerDetails.firstName"
            class="
              border-0
              px-3
              py-3
              placeholder-gray-400
              text-gray-700
              bg-white
              rounded
              text-sm
              shadow
              focus:outline-none focus:ring
              w-full
            "
            placeholder="Vorname"
            style="transition: all 0.15s ease 0s"
          >
        </div>
        <div class="relative w-full mb-3">
          <label class="block uppercase text-gray-700 text-xs font-bold mb-2">Nachname</label><input
            v-model="registerDetails.lastName"
            class="
              border-0
              px-3
              py-3
              placeholder-gray-400
              text-gray-700
              bg-white
              rounded
              text-sm
              shadow
              focus:outline-none focus:ring
              w-full
            "
            placeholder="Nachname"
            style="transition: all 0.15s ease 0s"
          >
        </div>
        <div class="relative w-full mb-3">
          <label class="block uppercase text-gray-700 text-xs font-bold mb-2">Email</label><input
            v-model="registerDetails.email"
            type="email"
            class="
              border-0
              px-3
              py-3
              placeholder-gray-400
              text-gray-700
              bg-white
              rounded
              text-sm
              shadow
              focus:outline-none focus:ring
              w-full
            "
            placeholder="Email"
            style="transition: all 0.15s ease 0s"
          >
        </div>
        <div class="relative w-full mb-3">
          <label class="block uppercase text-gray-700 text-xs font-bold mb-2">Username *</label><input
            v-model="registerDetails.username"
            :class="{'ring ring-red-400': highlightUsername}"
            class="
              border-0
              px-3
              py-3
              placeholder-gray-400
              text-gray-700
              bg-white
              rounded
              text-sm
              shadow
              focus:outline-none focus:ring
              w-full
            "
            placeholder="Username"
            style="transition: all 0.15s ease 0s"
            @input="highlightUsername = false"
          >
        </div>
        <div class="relative w-full mb-3">
          <label class="block uppercase text-gray-700 text-xs font-bold mb-2">Password *</label><input
            v-model="registerDetails.password"
            type="password"
            :class="{'ring ring-red-400': highlightPassword}"
            class="
              border-0
              px-3
              py-3
              placeholder-gray-400
              text-gray-700
              bg-white
              rounded
              text-sm
              shadow
              focus:outline-none focus:ring
              w-full
            "
            placeholder="Password"
            style="transition: all 0.15s ease 0s"
            @input="highlightPassword = false"
          >
        </div>
        <!--          <div>-->
        <!--            <label class="inline-flex items-center cursor-pointer"-->
        <!--            ><input-->
        <!--                id="customCheckLogin"-->
        <!--                type="checkbox"-->
        <!--                class="form-checkbox border-0 rounded text-gray-800 ml-1 w-5 h-5"-->
        <!--                style="transition: all 0.15s ease 0s;"-->
        <!--            /><span class="ml-2 text-sm font-semibold text-gray-700"-->
        <!--            >Remember me</span-->
        <!--            ></label-->
        <!--            >-->
        <!--          </div>-->
        <div class="mt-2 text-gray-700 text-xs">
          <span>* Pflichtfeld</span>
        </div>
        <div class="text-center mt-6">
          <k-kurz-button
            text="Register"
            @clicked="submit(registerDetails)"
          />
        </div>
      </div>

      <div class="text-center mt-4">
        <router-link
          to="/"
          class="text-gray-300"
          @click="() => $emit('redirect')"
        >
          <small>Login</small>
        </router-link>
      </div>
    </k-card>
  </div>
</template>

<script lang="ts">
import {defineComponent, ref} from 'vue'
import {useToast} from 'vue-toastification'
import type {AxiosError, AxiosResponse} from 'axios'
import {useAuthStore} from '@/stores/auth'
import type {RegisterParams} from '@/api/modules/auth'
import highlightError from '@/utils/highlight-auth-errors'

export default defineComponent({
  emits: ['redirect'],
  setup(props, content) {
    const store = useAuthStore()
    const registerDetails = ref({
      firstName: 'supervisor',
      lastName: 'dev',
      email: 'dev@singular-it.de',
      password: 'secret',
      username: 'dev',
    })

    const authenticated = store.isAuthenticated

    const highlightUsername = ref(false)
    const highlightPassword = ref(false)

    async function submit(registerDetails: RegisterParams) {
      store.register(registerDetails)
        .then((response: AxiosResponse) => {
          content.emit('redirect')
          const toast = useToast()
          toast.success('Nutzer wurde erfolgreich registriert')
        })
        .catch((error: AxiosError) => {
          highlightError(error, highlightUsername, highlightPassword)
        })
    }

    return {registerDetails, authenticated, submit, highlightUsername, highlightPassword}
  },
})
</script>

<style scoped>

</style>
