<template>
  <k-card>
    <template #title>
      <span class="card-head">
        <h2>
          Kontrakte
        </h2>
      </span>
    </template>
    <k-table
      v-model:page="activePage"
      class="flex-1"
      :head="customerContractTableHeads"
      :data="contracts"
      :total-rows="totalContracts"
      :data-loading="loadingContracts"
      async
      @change-page="activePage = $event"
      @reload="reload"
    >
      <template #customer-slot="{ content }">
        <span
          v-if="content && content.customer"
          class="whitespace-nowrap"
        >
          {{ content.customer.name }}
        </span>
        <div v-else>
          -
        </div>
      </template>
      <template #material-slot="{ content }">
        <span
          v-if="content && content.material"
          class="whitespace-nowrap"
        >
          {{ content.material.materialDescription }}
        </span>
        <div v-else>
          -
        </div>
      </template>
      <template #employee-slot="{ content }">
        <span
          v-if="content && content.responsibleEmployee"
          class="whitespace-nowrap"
        >
          {{ content.responsibleEmployee.firstName }}
          {{ content.responsibleEmployee.lastName }}
        </span>
        <div v-else>
          -
        </div>
      </template>
      <template #ratio-slot="{ content }">
        <div class="flex flex-row gap-3 justify-center">
          <k-traffic-light
            :value="calcIconColor(content.ratio)"
            slotted
          >
            <template #icon>
              <font-awesome-icon :icon="calcArrowDir(content.ratio)" />
            </template>
          </k-traffic-light>
          <span v-if="content.ratio">{{ content.ratio }}</span>
        </div>
      </template>
    </k-table>
  </k-card>
</template>

<script setup lang="ts">
import {toRef} from 'vue'
import type Customer from '@/types/api/customer.type'
import useContractList from '@/views/contract/use-contract-list'
import {contractTableHeads} from '@/views/contract/contract-table-heads'
import {calcIconColor} from '@/utils/use-iconColor'
import {calcArrowDir} from '@/utils/use-arrowDir'
import useHierarchyFilter from '@/views/customer/customer-detail/use-hierarchy-filter'

interface Props {
  customer: Customer
}

const props = defineProps<Props>()
const customer = toRef(props, 'customer')
const customerContractTableHeads = contractTableHeads.filter(a => (a.title !== 'Kunde')) // TODO change

const {hierarchyFilter1, hierarchyFilter2, hierarchyFilter3} = useHierarchyFilter()
const {contracts, totalContracts, activePage, loadingContracts, reload} = useContractList(customer)

</script>
