import {defineStore} from 'pinia'
import type {AxiosResponse} from 'axios'
import type {InvoiceData, OfferData, VKOverview} from '@/types/api/dashboard/vk-overview.type'
import api from '@/api'
import type {VKOverviewParams} from '@/api/modules/dashboards'

interface State {
  invoiceData: InvoiceData
  offerData: OfferData
  pending: boolean
}

export const useVkOverviewStore = defineStore('vk-overview', {
  // see https://pinia.vuejs.org/core-concepts/state.html
  state: (): State => ({
    // define initial values
    invoiceData: {sum: 0, target: 0} as InvoiceData,
    offerData: {end: []} as OfferData,
    pending: false,
  }),
  // see https://pinia.vuejs.org/core-concepts/getters.html
  getters: {
    // define getters to access compound or calculated data
  },
  // see https://pinia.vuejs.org/core-concepts/actions.html
  actions: {

    /**
         * load vkOverviews
         * @param {VKOverviewParams} config - the config for the call
         */
    async loadVkOverviews(config: VKOverviewParams) {
      this.pending = true
      return api.dashboard.getOverview(config)
        .then((data: AxiosResponse<VKOverview>) => {
          this.invoiceData = data.data.invoices as InvoiceData
          this.offerData = data.data.offers as OfferData
          this.pending = false
        }).catch((error) => {
          console.error(error)
          this.pending = false
          throw error
        })
    },

    /**
         * resets the store to the initial state.
         */
    clearVkOverviews() {
      this.$reset()
    },
  },
})
