import {RouterView, createRouter, createWebHashHistory} from 'vue-router'
import type {NavDisplay, NavigationEntry} from '@singularit/kurz-components'
import ContractView from '@/views/contract/ContractsView.vue'
import ContractDetailView from '@/views/contract/ContractDetailView.vue'
import OffersView from '@/views/offer/OffersView.vue'
import OffersDetailView from '@/views/offer/OfferDetailView.vue'
import ChurnList from '@/views/churn/ChurnList.vue'
import FeedbackDetailView from '@/views/feedback/FeedbackDetailView.vue'
import ChurnMonthList from '@/views/churn/ChurnMonthList.vue'
import CustomersView from '@/views/customer/CustomersView.vue'
import CustomerTrendView from '@/views/trend/CustomerTrendView.vue'
import CustomersDetailView from '@/views/customer/customer-detail/CustomersDetailView.vue'
import VKLDashboard from '@/views/dashboard/vkl-dashboard/VKLDashboard.vue'
import VKDashboard from '@/views/dashboard/vk-dashboard/VKDashboard.vue'
import {hasRolePermission} from '@/utils/role-permission'
import DataTimestampView from '@/views/data-timestamp/DataTimestampView.vue'
import UserManagementView from '@/views/user-managemant/UserManagementView.vue'
import type Right from '@/types/api/right.type'
import {RightName} from '@/types/api/right.type'
import UserDetailView from '@/views/user-managemant/user-managemant-detail/UserDetailView.vue'
import OffersOverview from '@/views/offer/OfferOverview.vue'
import TrendsList from '@/views/trend/TrendsList.vue'

declare module 'vue-router' {
  interface RouteMeta {
    authorize?: RightName
    display?: NavDisplay
  }
}
const routes = [
  {
    path: '/vkl-dashboard',
    alias: '/',
    name: 'vkl-dashboard',
    component: VKLDashboard,
    meta: {
      authorize: RightName.VKL,
      display: {
        icon: 'layer-group',
        title: 'VKL-Dashboard',
      },
    },
  },
  {
    path: '/vk-dashboard',
    name: 'vk-dashboard',
    component: VKDashboard,
    meta: {
      authorize: RightName.VK,
      display: {
        icon: 'layer-group',
        title: 'VK-Dashboard',
      },
      // breadCrumb: {text: "materialien"} as BreadCrumb,
    },
  },

  {
    path: '/customers',
    name: 'customers',
    component: RouterView,
    children: [
      {
        name: 'customers-list',
        path: '',
        component: CustomersView,
        props: true,
      },
      {
        name: 'customer-detail',
        path: ':customerId/',
        component: CustomersDetailView,
        props: true,
      },

      {
        name: 'customer-trend-detail',
        path: ':customerId/trend',
        props: true,
        component: CustomerTrendView,
        meta: {
          breadCrumb: {text: 'Kunden Trend-Analyse'},
        },
      },
      {
        path: 'feedbacks/',
        name: 'customer-feedback',
        props: true,
        component: FeedbackDetailView,
        meta: {
          breadCrumb: {text: 'Feedback'},
        },
      },
    ],
    meta: {
      display: {
        icon: 'users',
        title: 'Kunden',
      },
      breadCrumb: {text: 'Kunden'},
    },
  },
  {
    path: '/contracts',
    name: 'contracts',
    component: RouterView,
    children: [
      {
        path: '',
        name: 'contract-list',
        component: ContractView,
        meta: {
          breadCrumb: {text: 'Kontrakt'},
          display: {
            icon: 'pencil-alt',
            title: 'Kontrakte',
          },
        },
      },

      {
        path: ':contractId',
        name: 'contract-detail',
        props: true,
        component: ContractDetailView,
        meta: {
          breadCrumb: {text: 'Kontrakt Details'},
        },
      },
    ],
    meta: {
      display: {
        icon: 'pencil-alt',
        title: 'Kontrakte',
      },
    },
  },
  {
    path: '/offers',
    name: 'offers',
    component: RouterView,
    children: [
      {
        path: '',
        name: 'offers-list',
        component: OffersView,
        meta: {
          breadCrumb: {text: 'Angebote'},
        },
      },
      {
        path: ':offerReceipt',
        name: 'offer-overview',
        props: true,
        component: OffersOverview,
        meta: {
          breadCrumb: {text: 'Angebot Übersicht'},
        },
      },
      {
        path: ':offerReceipt/:offerPosition',
        name: 'offer-detail',
        props: true,
        component: OffersDetailView,
        meta: {
          breadCrumb: {text: 'Angebot Details'},
        },
      },
    ],
    meta: {
      display: {
        icon: 'map-signs',
        title: 'Angebote',
      },
    },
  },
  {
    path: '/churns',
    name: 'churns',
    component: RouterView,
    children: [
      {
        path: ':month/',
        name: 'churns-monthly',
        props: true,
        component: ChurnMonthList,
        meta: {
          breadCrumb: {text: 'Abgänge'},
        },
      },
      {
        path: '',
        name: 'churns',
        component: ChurnList,
        meta: {
          breadCrumb: {text: 'Abgänge'},
        },
      },
    ],
    meta: {},
  },
  {
    path: '/trends',
    name: 'trends',
    component: TrendsList,
    meta: {
      display: {
        icon: 'chart-bar',
        title: 'Trends',
      },
    },
  },
  {
    path: '/data-imports',
    name: 'dataImports',
    props: true,
    component: DataTimestampView,
    meta: {

      authorize: RightName.IMPORT,
      breadCrumb: {text: 'DataImports'},
      display: {
        icon: 'file-import',
        title: 'Data Imports',
      },
    },
  },
  {
    path: '/user-managemant',
    name: 'user-managemant',
    component: RouterView,
    children: [
      {
        path: '',
        name: 'user-list',
        component: UserManagementView,
        meta: {
          breadCrumb: {text: 'User Liste'},
        },
      },
      {
        path: ':accountId',
        name: 'user-detail',
        props: true,
        component: UserDetailView,
        meta: {
          breadCrumb: {text: 'User Details'},
        },
      },
    ],
    meta: {
      authorize: RightName.ADMIN,
      display: {
        icon: 'user-lock',
        title: 'Benutzerverwaltung',
      },
    },
  },
]

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(import.meta.env.BASE_URL),
  routes,
})

const navEntries = (rights: Right[]): NavigationEntry[] => {
  const res: NavigationEntry[] = []
  routes.forEach((route) => {
    if (route.meta && route.meta.display) {
      if (!route.meta.authorize || (route.meta.authorize && hasRolePermission(rights, route.meta.authorize))) {
        res.push({
          ...route.meta.display,
          path: route.path,
        })
      }
    }
  })
  return res
}
router.beforeEach((to, from, next) => {
  // const authorize = to.meta.authorize
  // const rightStore = useRightStore()
  // const rights = rightStore.userRights
  // if (authorize && rights) {
  //     if (!hasRolePermission(rights, authorize)) {
  //         return next({name: 'vk-dashboard'})
  //     }
  // }
  return next()
})

export {navEntries}
export default router
