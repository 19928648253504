import type {AxiosError} from 'axios'
import {useToast} from 'vue-toastification'
import type {Ref} from 'vue'
import type {AuthErrorData} from '@/types/api/auth/auth-info.type'

export default function highlightError(response: AxiosError, highlightUsername: Ref<boolean>, highlightPassword: Ref<boolean>) {
  const errorData: AuthErrorData = response.response?.data as AuthErrorData
  // eslint-disable-next-line no-console
  console.log(errorData)
  const toast = useToast()
  if (errorData && errorData.username) {
    if (errorData.username.includes('Dieser Benutzername ist bereits vergeben.')) {
      highlightUsername.value = true
      toast.error('Der eingegebene Nutzername ist bereits vergeben.')
    }
    if (errorData.username.includes('Dieses Feld darf nicht leer sein.')) {
      highlightUsername.value = true
      toast.error('Geben Sie einen Nutzernamen ein.')
    }
    if (errorData.username.includes('Bitte einen gültigen Benutzernamen eingeben, bestehend aus Buchstaben, Ziffern und @/./+/-/_.')) {
      highlightUsername.value = true
      toast.error('Ungültiger Nutzername. Nutzernamen dürfen nur aus Buchstaben, Ziffern und den Sonderzeichen @/./+/-/_ bestehen.')
    }
  }
  if (errorData && errorData.password && errorData.password.includes('Dieses Feld darf nicht leer sein.')) {
    highlightPassword.value = true
    toast.error('Geben Sie ein Passwort ein.')
  }
  if (errorData && errorData.detail)
    toast.error('Sie haben einen falschen Nutzernamen oder ein falsches Passwort eingegeben.')
}
