import type {Ref} from 'vue'
import {ref} from 'vue'
import {useImportTimestamps} from '@/utils/behaviours/use-data-import-timestamps'
import type {DataTimestampModel} from '@/types/api/data-timestamp.type'

export default function useDataTimestampList(
  after?: Ref<Date>,
  before?: Ref<Date>,
  model?: Ref<DataTimestampModel>,
) {
  const activePage = ref(1)

  const {timestamps, totalTimestamps, loadingTimestamps} = useImportTimestamps(activePage, ref(false), after, before, model)

  return {timestamps, totalTimestamps, activePage, loadingTimestamps}
}
