import AuthModule from '@/api/modules/auth'
import ContractModule from '@/api/modules/contracts'
import DashboardModule from '@/api/modules/dashboards'
import CustomerModule from '@/api/modules/customers'
import OrderModule from '@/api/modules/orders'
import UIFilterModule from '@/api/modules/ui-filter'
import HierarchyModule from '@/api/modules/hierarchies'
import InvoiceModule from '@/api/modules/invoices'
import EmployeeModule from '@/api/modules/employees'
import FeedbackModule from '@/api/modules/feedbacks'
import TrendModule from '@/api/modules/trends'
import ChurnModule from '@/api/modules/churns'
import TimestampModule from '@/api/modules/data-timestamps'
import OfferModule from '@/api/modules/offers'
import AccountModule from '@/api/modules/accounts'
import StatusModule from '@/api/modules/status'
import BranchModule from '@/api/modules/branches'

const api = {
  accounts: AccountModule,
  auth: AuthModule,
  churn: ChurnModule,
  contract: ContractModule,
  customer: CustomerModule,
  dashboard: DashboardModule,
  order: OrderModule,
  uiFilter: UIFilterModule,
  hierarchies: HierarchyModule,
  invoices: InvoiceModule,
  employees: EmployeeModule,
  feedbacks: FeedbackModule,
  trends: TrendModule,
  timestamp: TimestampModule,
  offers: OfferModule,
  status: StatusModule,
  branches: BranchModule,
}

export default api
