<template>
  <div class="lg:h-full flex flex-col overflow-hidden">
    <div class="flex">
      <h1 class="page-head">
        Kontrakte
      </h1>
      <ImportTimestamp
        class="items-center mr-4 ml-auto"
        dependant-on="contracts"
      />
    </div>
    <k-card class="md:h-screen lg:h-1/2">
      <template #title>
        <span class="card-head">
          <h2>Aktive Kontrakte</h2>
        </span>
      </template>
      <k-table
        v-model:page="activePage"
        class="flex-1"
        :head="computedContractTableHeads"
        :data="contracts"
        :total-rows="totalContracts"
        :data-loading="loadingContracts"
        async
        searchable
        @reload="activeReload"
        @change-page="activePage = $event"
        @row-select="openDetailView"
      >
        <template #customer-slot="{ content }">
          <span
            v-if="content && content.customer"
            class="whitespace-nowrap"
          >
            {{ content.customer.name }}
          </span>
          <div v-else>
            -
          </div>
        </template>
        <template #material-slot="{ content }">
          <span
            v-if="content && content.material"
            class="whitespace-nowrap"
          >
            {{ content.material.materialDescription }}
          </span>
          <div v-else>
            -
          </div>
        </template>
        <template #employee-slot="{ content }">
          <span
            v-if="content && content.responsibleEmployee"
            class="whitespace-nowrap"
          >
            {{ content.responsibleEmployee.firstName }}
            {{ content.responsibleEmployee.lastName }}
          </span>
          <div v-else>
            -
          </div>
        </template>
        <template #ratio-slot="{ content }">
          <div class="flex flex-row">
            <k-traffic-light
              :value="calcIconColor(content.ratio)"
              slotted
            >
              <template #icon>
                <font-awesome-icon :icon="calcArrowDir(content.ratio)" />
              </template>
            </k-traffic-light>
            <span v-if="content.ratio">{{ content.ratio }}</span>
          </div>
        </template>
      </k-table>
    </k-card>
    <k-card class="md:h-screen lg:h-1/2">
      <template #title>
        <span class="card-head">
          <h2>Inaktive Kontrakte</h2>
        </span>
      </template>
      <k-table
        v-model:page="activePageInactiveContracts"
        class="flex-1"
        :head="computedContractTableHeads"
        :data="inactiveContracts"
        :total-rows="totalInactiveContracts"
        :data-loading="loadingInactiveContracts"
        async
        searchable
        @reload="inactiveReload"
        @change-page="activePageInactiveContracts = $event"
        @row-select="openDetailView"
      >
        <template #customer-slot="{ content }">
          <span
            v-if="content && content.customer"
            class="whitespace-nowrap"
          >
            {{ content.customer.name }}
          </span>
          <div v-else>
            -
          </div>
        </template>
        <template #material-slot="{ content }">
          <span
            v-if="content && content.material"
            class="whitespace-nowrap"
          >
            {{ content.material.materialDescription }}
          </span>
          <div v-else>
            -
          </div>
        </template>
        <template #employee-slot="{ content }">
          <span
            v-if="content && content.responsibleEmployee"
            class="whitespace-nowrap"
          >
            {{ content.responsibleEmployee.firstName }}
            {{ content.responsibleEmployee.lastName }}
          </span>
          <div v-else>
            -
          </div>
        </template>
        <template #ratio-slot="{ content }">
          <div class="flex flex-row">
            <k-traffic-light
              :value="calcIconColor(content.ratio)"
              slotted
            >
              <template #icon>
                <font-awesome-icon :icon="calcArrowDir(content.ratio)" />
              </template>
            </k-traffic-light>
            <span v-if="content.ratio">{{ content.ratio }}</span>
          </div>
        </template>
      </k-table>
    </k-card>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent} from 'vue'
import {useRouter} from 'vue-router'
import {contractTableHeads} from '@/views/contract/contract-table-heads'
import useContractList from '@/views/contract/use-contract-list'
import useScreenSize from '@/utils/use-screen-size'
import {calcArrowDir} from '@/utils/use-arrowDir'
import {calcIconColor} from '@/utils/use-iconColor'
import ImportTimestamp from '@/components/timestamp/ImportTimestamp.vue'
import type Contract from '@/types/api/contract.type'

export default defineComponent({
  name: 'ContractsView',
  components: {ImportTimestamp},
  setup() {
    const router = useRouter()

    function openDetailView(data: Contract) {
      router.push({
        name: 'contract-detail',
        params: {
          contractId: data?.id,
        },
      })
    }

    const {isMediumSize} = useScreenSize()
    const computedContractTableHeads = computed(() => {
      if (isMediumSize.value)
        return contractTableHeads.slice(0, 6)

      else return contractTableHeads
    })

    const {
      contracts,
      totalContracts,
      activePage,
      loadingContracts,
      reload: activeReload,
    } = useContractList(undefined, false)

    const {
      contracts: inactiveContracts,
      totalContracts: totalInactiveContracts,
      activePage: activePageInactiveContracts,
      loadingContracts: loadingInactiveContracts,
      reload: inactiveReload,
    } = useContractList(undefined, true)

    return {
      contractTableHeads,
      computedContractTableHeads,
      contracts,
      totalContracts,
      activePage,
      activeReload,
      inactiveReload,
      loadingContracts,

      loadingInactiveContracts,
      inactiveContracts,
      totalInactiveContracts,
      activePageInactiveContracts,
      openDetailView,
      calcArrowDir,
      calcIconColor,
    }
  },
})
</script>
