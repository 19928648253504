import type {Ref} from 'vue'
import {computed, ref, unref, watchEffect} from 'vue'
import type Customer from '@/types/api/customer.type'
import type {InvoicesListParams} from '@/api/modules/invoices'
import {useInvoiceStore} from '@/stores/invoice'
import useTableQuery from '@/utils/use-table-query'

export default function useInvoiceList(
  customerFilter?: Ref<Customer>,
  startDate?: Ref<Date | string>,
  endDate?: Ref<Date | string>,
  exactDate?: Ref<Date | string>,
) {
  const store = useInvoiceStore()

  const activePage = ref(1)

  const {query, reload} = useTableQuery()
  const config = computed(() => {
    const config: InvoicesListParams = {
      page: unref(activePage),
      customer: unref(customerFilter),
      invoice_date__gte: startDate?.value,
      invoice_date__lte: endDate?.value,
      invoice_date: exactDate?.value,
      query: unref(query),
    }
    return config
  })

  watchEffect(() => {
    store.loadInvoices(config.value)
  })

  const loadingInvoices = computed(() => store.invoices.pending)

  const invoices = computed(() => {
    return store.invoices.data
  })

  const totalInvoices = computed(() => {
    return store.invoices.total
  })

  return {
    invoices,
    totalInvoices,
    activePage,
    loadingInvoices,
    reload,
  }
}
