import type {WritableComputedRef} from 'vue'
import {computed} from 'vue'
import {useUrlSearchParams} from '@vueuse/core'
import {Mutex} from 'async-mutex'

class URLManager {
  private params: Record<string, string | string[]> = {}
  private readonly mode: 'history' | 'hash' | 'hash-params'
  public mutex: Mutex
  private currentURL

  constructor(mode: 'history' | 'hash' | 'hash-params') {
    this.mode = mode
    this.params = useUrlSearchParams(mode)

    this.currentURL = window.location.href
    this.mutex = new Mutex()
  }

  getParam(name: string) {
    return this.params[name]
  }

  /**
   * checks if the current url is still active as the vue use functions does not automatically updates
   */
  checkURL() {
    if (this.currentURL !== window.location.href) {
      this.params = useUrlSearchParams(this.mode)
      this.currentURL = window.location.href
    }
  }

  /**
   * should only be called when the calling functions owns the mutex.
   *
   * @param value
   * @param name
   */
  addValue(value: string | string[] | undefined, name: string) {
    if (value === undefined)
      delete this.params[name]
    else
      this.params[name] = value
  }
}

const manager = new URLManager('hash')

type Maybe<T> = T | undefined

export default function useUrlParam<T, I extends Maybe<T>>(name: string, toString: (value: T) => string | string[] | undefined, fromString: (string: string | string[]) => Maybe<T>, initial?: I): WritableComputedRef<I extends undefined ? Maybe<T> : T> {
  manager.checkURL()
  const setURL = (value: T | undefined) => {
    let string
    if (value !== initial && value !== undefined)
      string = toString(value)

    manager.addValue(string, name)
  }
  return computed({
    get() {
      const paramValue = manager.getParam(name)
      if (paramValue)
        return fromString(paramValue) as I extends undefined ? Maybe<T> : T
      return initial as I extends undefined ? Maybe<T> : T
    },
    set(value) {
      manager.mutex.acquire().then((release) => {
        try {
          setURL(value)
        }
        finally {
          release()
        }
      })
    },
  })
}
