import {ObjectTableHead, SlotTableHead} from '@singularit/kurz-components'
import {dateFormatter, numberFormatter, tableCurrencyFormatter} from '@/utils/formatter'

const contractTableHeads = [
  new ObjectTableHead({
    title: 'Beleg',
    attribute: 'receipt',
    sortable: true,
    id: 0,
  }),
  new ObjectTableHead({
    title: 'Position',
    attribute: 'position',
    id: 1,
  }),
  new SlotTableHead({
    title: 'Kunde',
    slotName: 'customer-slot',
    sortable: true,
    sortKey: 'customer__name',
    id: 2,
  }),

  new SlotTableHead({
    title: 'Material',
    slotName: 'material-slot',
    sortable: true,
    sortKey: 'material__material_description',
    id: 3,
  }),
  new ObjectTableHead({
    title: 'Gepl. Umsatz',
    attribute: 'openRevenue',
    sortable: true,
    sortKey: 'open_revenue',
    id: 4,
    alignment: 'right',
    formatter: tableCurrencyFormatter,

  }),
  new ObjectTableHead({
    title: 'Real. Umsatz',
    attribute: 'revenue',
    sortable: true,
    id: 5,
    formatter: tableCurrencyFormatter,
  }),

  new SlotTableHead({
    title: 'Zust. MA',
    slotName: 'employee-slot',
    sortable: true,
    sortKey: 'responsible_employee__first_name',
    id: 6,
  }),

  new ObjectTableHead({
    title: 'Beginn',
    attribute: 'durationStart',
    sortable: true,
    sortKey: 'duration_start',
    id: 7,
    formatter: dateFormatter,
    alignment: 'right',
  }),

  new ObjectTableHead({
    title: 'Ende',
    attribute: 'durationEnd',
    sortable: true,
    sortKey: 'duration_end',
    id: 8,
    formatter: dateFormatter,
    alignment: 'right',
  }),

  new SlotTableHead({
    title: 'Abw.',
    slotName: 'ratio-slot',
    sortKey: 'ratio',
    id: 9,
    alignment: 'center',
  }),
]

const contractOrderTableHeads = [
  new ObjectTableHead({
    title: 'Auftrag',
    attribute: 'receipt',
    sortable: true,
    id: 0,
  }),
  new ObjectTableHead({
    title: 'Pos.',
    attribute: 'position',
    id: 1,
  }),
  new ObjectTableHead({
    title: 'Umsatz',
    attribute: 'revenue',
    sortable: true,
    id: 2,
    formatter: (revenue: number) => {
      const formatted = numberFormatter.format(revenue)
      return isNaN(revenue) ? '-' : `${formatted} €`
    },
  }),
  new SlotTableHead({
    title: 'Menge',
    id: 3,
    slotName: 'quantity-slot',
  }),
  new ObjectTableHead({
    title: 'Warenauslieferungsdatum',
    attribute: 'deliveryDate',
    sortable: true,
    sortKey: 'delivery_date',
    id: 4,
    formatter: dateFormatter,
    alignment: 'right',
  }),
  new ObjectTableHead({
    title: 'Ab-Termin',
    attribute: 'orderConfirmationDate',
    sortable: true,
    sortKey: 'order_confirmation_date',
    id: 4,
    formatter: dateFormatter,
    alignment: 'right',
  }),
  new ObjectTableHead({
    title: 'Erstellungstermin',
    attribute: 'creationDate',
    sortable: true,
    sortKey: 'creation_date',
    id: 4,
    formatter: dateFormatter,
    alignment: 'right',
  }),
]

export {contractTableHeads, contractOrderTableHeads}
