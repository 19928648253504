<template>
  <div class="h-full flex flex-col overflow-hidden">
    <div class="flex">
      <h1 class="page-head">
        Benutzerverwaltung
      </h1>
      <ImportTimestamp
        class="items-center mr-4 ml-auto"
        :dependant-on="accounts"
      />
    </div>
    <k-card>
      <template #title>
        <span class="card-head">
          <h2>Alle Benutzer</h2>
        </span>
      </template>
      <k-table
        v-model:page="activePage"
        class="flex-1"
        :head="accountTableHeads"
        :data="accounts"
        :total-rows="totalAccounts"
        :searchable="true"
        :data-loading="loadingAccounts"
        async
        @row-select="openDetailView"
        @reload="reload"
        @change-page="activePage = $event"
      />
    </k-card>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
import {useRouter} from 'vue-router'
import useAccountList from '@/views/user-managemant/use-account-list'
import {accountTableHeads} from '@/views/user-managemant/account-table-heads'
import type Account from '@/types/api/account.type'

export default defineComponent({
  components: {},
  setup() {
    const accountListBehaviour = useAccountList()
    const router = useRouter()
    const openDetailView = (data: Account) => {
      router.push({
        name: 'user-detail',
        params: {
          accountId: data.id,
        },
      })
    }
    return {...accountListBehaviour, accountTableHeads, openDetailView}
  },
})
</script>
