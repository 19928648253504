import {defineStore} from 'pinia'
import type DataTimestamp from '@/types/api/data-timestamp.type'
import {PaginatedData} from '@/stores/index'
import api from '@/api'
import type {DataTimestampParams} from '@/api/modules/data-timestamps'

interface State {
  timestamps: PaginatedData<DataTimestamp>
  latestTimestamps: PaginatedData<DataTimestamp>
}

export const useTimestampStore = defineStore('timestamp', {
  // see https://pinia.vuejs.org/core-concepts/state.html
  state: (): State => ({
    // define initial values
    timestamps: new PaginatedData<DataTimestamp>(),
    latestTimestamps: new PaginatedData<DataTimestamp>(),
  }),
  // see https://pinia.vuejs.org/core-concepts/getters.html
  getters: {
    // define getters to access compound or calculated data
  },
  // see https://pinia.vuejs.org/core-concepts/actions.html
  actions: {

    /**
         * load timestamps
         * @param {DataTimestampParams} config - the config for the call
         * @param cancel
         * @param requestID
         */
    async loadTimestamps(config: DataTimestampParams, cancel = true, requestID?: string) {
      const id = requestID || api.timestamp.getTimestamps.name
      if (cancel)
        api.timestamp.cancel(id)
      this.timestamps.pending = true
      return api.timestamp.cancelableCall(api.timestamp.getTimestamps, config, id)
        .then((data) => {
          const page = data.data
          this.timestamps.total = page.count
          this.timestamps.data = page.results
          if (!api.timestamp.hasPending(id))
            this.timestamps.pending = false
        }).catch((error) => {
          if (!api.timestamp.hasPending(id))
            this.timestamps.pending = false
          throw error
        })
    },

    async loadLatestTimestamps() {
      this.latestTimestamps.pending = true
      return api.timestamp.getLatestTimestamps()
        .then((data) => {
          const page = data.data
          this.latestTimestamps.total = page.count
          this.latestTimestamps.data = page.results
          this.latestTimestamps.pending = false
        }).catch((error) => {
          console.error(error)
          this.latestTimestamps.pending = false
          return error
        })
    },

    /**
         * resets the store to the initial state.
         */
    clearTimestamps() {
      this.$reset()
    },
  },
})
