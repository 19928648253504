import type {AxiosResponse} from 'axios'
import axiosInstance from '@/api/axios'
import BaseModule from '@/api/modules/base-module'
import type {UIFilter, ViewName} from '@/types/api/ui-filter.type'
import type Page from '@/types/page.type'
import type PaginationListParameters from '@/api/modules/pagination-list-parameters'

export interface UIFilterPostParams {
  filter: {
    url: string
  }
  viewName: ViewName
  isEditable: boolean
  user: number
}

export interface UIFilterUpdateParams extends UIFilterPostParams {
  filterId: number
}

export interface UiFilterParams extends PaginationListParameters {
  user?: number
}

class UIFilterModule extends BaseModule {
  loadUiFilter(params: UiFilterParams, controller?: AbortController): Promise<AxiosResponse<Page<UIFilter>>> {
    const signal = controller ? controller.signal : undefined
    const data = {
      params,
      signal,
    }
    return axiosInstance.get('/ui-filters/', data)
  }

  postFilter(params: UIFilterPostParams): Promise<AxiosResponse> {
    const form = new FormData()
    const filter = {url: params.filter}

    form.append('filter', JSON.stringify(filter))
    form.append('view_name', params.viewName)
    form.append('user', params.user.toString())
    form.append('is_editable', params.isEditable.toString())

    return axiosInstance.post('/ui-filters/', form)
  }

  updateFilter(params: UIFilterUpdateParams): Promise<AxiosResponse> {
    const form = new FormData()
    const filter = params.filter

    form.append('filter', JSON.stringify(filter))
    form.append('view_name', params.viewName)
    form.append('user', params.user.toString())
    form.append('is_editable', params.isEditable.toString())

    const filterId = params.filterId

    return axiosInstance.put(`/ui-filters/${filterId}/`, form)
  }
}

const module = new UIFilterModule()
export default module
