import {ObjectTableHead, SlotTableHead} from '@singularit/kurz-components'
import {dateFormatter} from '@/utils/formatter'

const churnMonthlyTableHeads = [
  new SlotTableHead({
    title: 'Kunde',
    slotName: 'customer-slot',
    sortKey: 'customer',
    sortable: true,
    id: 0,
  }),
  new ObjectTableHead({
    title: 'Hierarchie',
    attribute: 'hierarchie',
    sortable: true,
    id: 1,
  }),
  new ObjectTableHead({
    title: '⌀ Einkaufsfrequenz',
    attribute: 'frequency',
    sortable: true,
    id: 2,
  }),
  new ObjectTableHead({
    title: 'Letzter Einkauf vor',
    attribute: 'date',
    sortable: true,
    id: 3,
  }),
  new ObjectTableHead({
    title: 'Abw.',
    attribute: 'likelihood',
    sortable: true,
    id: 4,
  }),
  new ObjectTableHead({
    title: 'Zugewiesen',
    attribute: 'assignee',
    sortable: true,
    id: 5,
  }),
]
const churnTableHeads = [
  new SlotTableHead({
    title: 'Kunde',
    slotName: 'customer-slot',
    sortKey: 'customer',
    sortable: false,
    id: 0,
  }),
  new ObjectTableHead({
    title: 'Datum der Markierung',
    attribute: 'date',
    sortable: true,
    id: 1,
    formatter: dateFormatter,
    alignment: 'right',
  }),
  new ObjectTableHead({
    title: 'Durchschnittliche Einkaufsfrequenz',
    attribute: 'frequency',
    sortable: true,
    id: 2,
  }),
  new ObjectTableHead({
    title: 'Letzter Einkauf vor',
    attribute: 'updated',
    sortable: true,
    id: 3,
    formatter: dateFormatter,
    alignment: 'right',
  }),
  new ObjectTableHead({
    title: 'Abw.',
    attribute: 'likelihood',
    sortable: true,
    formatter: (a: number) => `${a * 100} %`,
    id: 4,
  }),
]

const customerTableHead = [
  new ObjectTableHead({
    title: '',
    attribute: 'customer',
    sortable: true,
    id: 0,
  }),
]

export {churnMonthlyTableHeads, churnTableHeads, customerTableHead}
