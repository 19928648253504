import type {NavigationEntry} from '@singularit/kurz-components'

export const calcChurnEntry = (): NavigationEntry => {
  const date = new Date()
  const res: NavigationEntry = {
    title: 'Absprünge',
    icon: 'calendar',
    path: `/churns/${date.toLocaleDateString('en-CA').substring(0, 7)}`,
    subEntries: {
      open: false,
      entries: [],
    },
  }
  for (let i = 0; i < 4; i++) {
    date.setMonth(date.getMonth() - 1)
    res.subEntries?.entries.push({
      title: date.toLocaleDateString('de-DE', {
        month: 'long',
        year: 'numeric',
      }),
      icon: 'calendar',
      path: `/churns/${date.toLocaleDateString('en-CA').substring(0, 7)}`,
    })
  }
  res.subEntries?.entries.push({
    title: 'Ältere',
    icon: 'calendar',
    path: '/churns',
  })

  return res
}
