<template>
  <span>
    <router-link
      v-if="computedObject && id"
      class="link"
      :to="{ name: 'customer-detail', params: { customerId: id } }"
    >
      {{ computedObject.name }}
      <font-awesome-icon
        class="icon"
        icon="external-link-alt"
      />
    </router-link>
    <span v-else>
      {{ emptyValue }}
    </span>
  </span>
</template>

<script lang="ts" setup>
import {computed} from 'vue'
import Customer from '@/types/api/customer.type'

interface Props {
  customer?: Customer['id'] | Partial<Customer> // TODO remove id type
  emptyValue: string
}

const props = withDefaults(defineProps<Props>(), {
  emptyValue: '-',
})

const computedObject = computed(() => {
  let object = new Customer()
  if (props.customer instanceof Object)
    object = {...object, ...props.customer}

  return object
})

const id = computed(() => {
  return computedObject.value.id
})
</script>

<style scoped rel="stylesheet/scss">
.link:hover {
  @apply text-blue-kurz-500;
}
</style>
<style></style>
