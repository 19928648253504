<template>
  <div class="h-full flex flex-col overflow-hidden">
    <!--headline  -->
    <div
      class="w-[97%] z-30 bg-lightwhite flex flex-col lg:flex-row mb-3 justify-between lg:basis-1/3 fixed mb-10 pb-10 lg:pb-0"
    >
      <div class="flex flex-row">
        <h1 class="page-head">
          Trends
        </h1>
      </div>
      <div class="order-last lg:order-none flex justify-center">
        <div class="flex flex-row gap-2 self-center mt-4 items-center">
          <font-awesome-icon
            class="hover:cursor-pointer select-none"
            icon="angle-left"
            @click="prevMonth"
          />
          <k-month-picker
            v-model="watchedMonth"
            :label-format="monthPickerFormat"
            :preview-format="monthPickerFormat"
            locale="de-DE"
            class="w-44"
          />
          <font-awesome-icon
            class="hover:cursor-pointer select-none"
            icon="angle-right"
            @click="nextMonth"
          />
        </div>
      </div>
      <div>
        <ImportTimestamp
          dependant-on="customers"
          class="ml-4 lg:ml-0 mr-4"
        />
      </div>
    </div>

    <!-- content -->
    <div
      class="grid grid-cols-1 mt-48 lg:mt-16 max-h-full overflow-hidden"
    >
      <k-card>
        <template #title>
          <div class="card-head flex">
            <span class="flex items-center">
              <h2>Alle Trends</h2>
            </span>
            <div class="w-10 mt-1 flex items-center justify-center cursor-pointer">
              <font-awesome-icon
                :icon="[isFilterActive ? 'fas' : 'far', 'filter']"
                class="text-xl self-center"
                @click="showFilter = true"
              />
            </div>
          </div>
        </template>
        <k-table
          v-model:page="activePage"
          class="flex-1"
          searchable
          :head="trendsTableHeads"
          :data="trendData"
          :total-rows="totalTrends"
          :data-loading="dataLoading"
          async
          @reload="trendReload"
          @change-page="activePage = $event"
          @row-select="openCustomerDetailView"
        >
          <template #branch-slot="{content}">
            <span
              v-if="content && content.branch"
              class="whitespace-nowrap"
            >
              {{ content.branch.name }}
            </span>
            <div v-else>
              -
            </div>
          </template>
          <template #3-month-trend="{content}">
            <span
              v-if="content && content.threeMonthTrend"
              class="whitespace-nowrap"
            >
              {{ content.threeMonthTrend }}
            </span>
            <div v-else>
              -
            </div>
          </template>
          <template #12-month-trend="{content}">
            <span
              v-if="content && content.twelveMonthTrend"
              class="whitespace-nowrap"
            >
              {{ content.twelveMonthTrend }}
            </span>
            <div v-else>
              -
            </div>
          </template>
          <template #partner-slot="{ content }">
            <span
              v-if="content.partners && content.partners.length > 0"
              class="whitespace-nowrap"
            >
              {{ partnerString(content) }}
            </span>
            <span v-else>-</span>
          </template>
          <template #current-year-revenue="{content}">
            <span class="whitespace-nowrap">
              {{ tableCurrencyFormatter(content.currentYearRevenue) }}
            </span>
          </template>
          <template #previous-year-revenue="{content}">
            <span class="whitespace-nowrap">
              {{ tableCurrencyFormatter(content.previousYearRevenue) }}
            </span>
          </template>
          <template #my-customers-filter="{ filter }">
            <div>
              {{ filter.displayText }}
              <input
                id="customer-filter"
                v-model="myCustomersFilterActive"
                type="checkbox"
                class="ml-5"
              >
            </div>
          </template>
        </k-table>
      </k-card>

      <!--    filter -->
      <k-modal
        :modal-active="showFilter"
        @close="showFilter=false"
      >
        <template #head>
          <h1 class="page-head">
            Filter
          </h1>
        </template>
        <hr>
        <div
          v-if="filtersLoading"
          class="flex justify-center py-10"
        >
          <k-kurz-dot-loader />
        </div>
        <div class="flex flex-col pb-5">
          <div class="filter-list flex flex-col gap-5 py-5">
            <div class="flex gap-5">
              <k-kurz-multi-select
                v-model="filteredIds"
                :options="idOptions"
                placeholder="IDs"
                clearable
              />
              <info-toggle
                v-model="includeIds"
                class="self-center"
                :options="includeToggleOptions"
                :prefix-label="includeToggleOptions.falseLabel"
                :suffix-label="includeToggleOptions.trueLabel"
                off-color="bg-blue-kurz-600"
              />
            </div>
            <div class="flex gap-5">
              <k-kurz-multi-select
                v-model="filteredBranches"
                :options="branchOptions"
                placeholder="Branches"
                clearable
              />
              <info-toggle
                v-model="includeBranches"
                class="self-center"
                :options="includeToggleOptions"
                :prefix-label="includeToggleOptions.falseLabel"
                :suffix-label="includeToggleOptions.trueLabel"
                off-color="bg-blue-kurz-600"
              />
            </div>
            <div class="flex gap-5">
              <k-kurz-multi-select
                v-model="filteredCountries"
                :options="countryOptions"
                placeholder="Länder"
                clearable
              />
              <info-toggle
                v-model="includeCountries"
                class="self-center"
                :options="includeToggleOptions"
                :prefix-label="includeToggleOptions.falseLabel"
                :suffix-label="includeToggleOptions.trueLabel"
                off-color="bg-blue-kurz-600"
              />
            </div>
            <hr>
            <label class="ml-2">
              <input
                id="my-customer-filter"
                v-model="myCustomersFilterActive"
                type="checkbox"
              >
              <span>
                Nur meine Kunden anzeigen
              </span>
            </label>
          </div>
          <hr>
          <div class="flex justify-between pt-5">
            <k-kurz-button
              text="Filter laden"
              @click="setUserFilter"
            />
            <k-kurz-button
              text="Filter speichern"
              @click="saveFilter"
            />
          </div>
        </div>
      </k-modal>
    </div>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, ref} from 'vue'
import {useRouter} from 'vue-router'
import ImportTimestamp from '@/components/timestamp/ImportTimestamp.vue'
import useMonth from '@/views/trend/use-month'
import useUiFilter from '@/utils/use-ui-filter'
import InfoToggle from '@/components/toggle/InfoToggle.vue'
import useMonthlyTrends from '@/views/trend/use-monthly-trends'
import {tableCurrencyFormatter} from '@/utils/formatter'
import type {MonthlyTrend} from '@/types/api/trend.type'

export default defineComponent({
  name: 'TrendsList',
  components: {ImportTimestamp, InfoToggle},
  setup() {
    const router = useRouter()
    const {watchedMonth, monthPickerFormat, nextMonth, prevMonth} = useMonth()

    const showFilter = ref(false)

    const {setUserFilter, saveFilter, loadFilter, hasFilterPermission} = useUiFilter('trends')

    const {
      activePage, trendsTableHeads, trendReload, trendData, totalTrends, dataLoading,
      myCustomersFilterActive, filteredIds, includeIds, filteredBranches, includeBranches, filteredCountries,
      includeCountries, idOptions, branchOptions, countryOptions, includeToggleOptions, filtersLoading,
    } = useMonthlyTrends(watchedMonth)

    const isFilterActive = computed(() => {
      return filteredIds.value.length > 0 || filteredBranches.value.length > 0 || filteredCountries.value.length > 0
    })

    function openCustomerDetailView(data: MonthlyTrend) {
      router.push({
        name: 'customer-detail',
        params: {
          customerId: data.id,
        },
      })
    }

    function partnerString(trend: MonthlyTrend) {
      if (trend.partners && trend.partners.length > 1 && trend.partners[0])
        return `${trend.partners[0].firstName} ${trend.partners[0].lastName} und ${trend.partners.length - 1} weitere`

      else if (trend.partners && trend.partners.length === 1)
        return `${trend.partners[0].firstName} ${trend.partners[0].lastName}`
    }

    return {
      watchedMonth,
      monthPickerFormat,
      nextMonth,
      prevMonth,

      filteredIds,
      includeIds,
      filteredBranches,
      includeBranches,
      filteredCountries,
      includeCountries,
      idOptions,
      branchOptions,
      countryOptions,
      includeToggleOptions,
      showFilter,
      isFilterActive,
      myCustomersFilterActive,

      setUserFilter,
      saveFilter,
      loadFilter,
      hasFilterPermission,

      activePage,
      trendsTableHeads,
      trendReload,
      trendData,
      totalTrends,
      dataLoading,
      filtersLoading,
      tableCurrencyFormatter,
      openCustomerDetailView,
      partnerString,
    }
  },
})
</script>

<style scoped>

</style>
