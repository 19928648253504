import {defineStore} from 'pinia'
import type Account from '@/types/api/account.type'
import api from '@/api'
import type {
  AccountDetailParams,
  AccountGroupPostParams,
  AccountListParams,
  AccountRightsPostParams,
  AccountUpdateParams,
} from '@/api/modules/accounts'
import {PaginatedData, RequestData} from '@/stores/index'
import type {UiFilterParams} from '@/api/modules/ui-filter'

interface State {
  account: RequestData<Account>
  accounts: PaginatedData<Account>
}

export const useAccountStore = defineStore('account', {
  // see https://pinia.vuejs.org/core-concepts/state.html
  state: (): State => ({
    // define initial values
    account: new RequestData<Account>(),
    accounts: new PaginatedData<Account>(),
  }),
  // see https://pinia.vuejs.org/core-concepts/getters.html
  getters: {
    getAccountRights: (state) => {
      if (state.account) return state.account.data?.rights
      else return undefined
    },
    getAccountUIFilter: (state) => {
      if (state.account) return state.account.data?.uiFilter
      else return undefined
    },
    // define getters to access compound or calculated data
  },
  // see https://pinia.vuejs.org/core-concepts/actions.html
  actions: {

    /**
         * load accounts
         * @param {AccountListParams} config - the config for the call
         * @param cancel
         * @param requestID
         */
    async loadAccounts(config: AccountListParams, cancel = true, requestID?: string) {
      const id = requestID || api.accounts.getAccounts.name
      if (cancel)
        api.accounts.cancel(id)
      this.accounts.pending = true
      return api.accounts.cancelableCall(api.accounts.getAccounts, config, id)
        .then((data) => {
          const page = data.data
          this.accounts.total = page.count
          this.accounts.data = page.results
          if (!api.accounts.hasPending(id))
            this.accounts.pending = false
        }).catch((error) => {
          if (!api.accounts.hasPending(id))
            this.accounts.pending = false
          throw error
        })
    },

    /**
         * load account
         * @param {AccountDetailParams} config - the config for the call
         * @param cancel
         * @param requestID
         */
    async loadAccount(config: AccountDetailParams, cancel = true, requestID?: string) {
      const id = requestID || api.accounts.getAccountDetails.name
      if (cancel)
        api.accounts.cancel(id)
      this.account.pending = true
      return api.accounts.getAccountDetails(config)
        .then((data) => {
          this.account.data = data.data
          if (!api.accounts.hasPending(id))
            this.account.pending = false
        }).catch((error) => {
          if (!api.accounts.hasPending(id))
            this.account.pending = false
          throw error
        })
    },
    async updateAccountDetails(config: AccountUpdateParams, cancel = true, requestID?: string) {
      const id = requestID || api.accounts.updateAccountDetails.name
      if (cancel)
        api.accounts.cancel(id)
      this.account.pending = true

      return api.accounts.updateAccountDetails(config)
        .then((data) => {
          if (!api.accounts.hasPending(id))
            this.account.pending = false
          return data
        }).catch((error) => {
          if (!api.accounts.hasPending(id))
            this.account.pending = false
          throw error
        })
    },
    async loadAccountRights(config: AccountDetailParams) {
      this.account.pending = true
      return api.accounts.getAccountRights(config)
        .then((data) => {
          if (this.account && this.account.data)
            this.account.data.rights = data.data
          this.account.pending = false
          return data.data
        }).catch((error) => {
          console.error(error)
          this.account.pending = false
          throw error
        })
    },
    async loadAccountGroups(config: AccountDetailParams) {
      this.account.pending = true
      return api.accounts.getAccountGroups(config)
        .then((data) => {
          if (this.account && this.account.data)
            this.account.data.groups = data.data

          this.account.pending = false
          return data.data
        }).catch((error) => {
          console.error(error)
          this.account.pending = false
          throw error
        })
    },
    async loadAccountUIFilter(config: UiFilterParams) {
      this.account.pending = true
      return api.uiFilter.loadUiFilter(config)
        .then((data) => {
          if (this.account && this.account.data)
            this.account.data.uiFilter = data.data.results
          this.account.pending = false
        }).catch((error) => {
          console.error(error)
          this.account.pending = false
          throw error
        })
    },
    async postAccountRights(config: AccountRightsPostParams, cancel = true, requestID?: string) {
      const id = requestID || api.accounts.postAccountRights.name
      if (cancel)
        api.accounts.cancel(id)
      this.account.pending = true

      return api.accounts.postAccountRights(config)
        .then((data) => {
          if (!api.accounts.hasPending(id))
            this.account.pending = false
          return data
        }).catch((error) => {
          if (!api.accounts.hasPending(id))
            this.account.pending = false
          throw error
        })
    },
    async postAccountGroup(config: AccountGroupPostParams, cancel = true, requestID?: string) {
      const id = requestID || api.accounts.postAccountGroup.name
      if (cancel)
        api.accounts.cancel(id)
      this.account.pending = true

      return api.accounts.postAccountGroup(config)
        .then((data) => {
          if (!api.accounts.hasPending(id))
            this.account.pending = false
          return data
        }).catch((error) => {
          if (!api.accounts.hasPending(id))
            this.account.pending = false
          throw error
        })
    },
    /**
         * resets the store to the initial state.
         */
    clearAccounts() {
      this.$reset()
    },
  },
})
