import type {Ref} from 'vue'
import {computed, ref, unref, watchEffect} from 'vue'
import {ObjectTableHead, SlotTableHead, TrafficLightColor} from '@singularit/kurz-components'
import {subYears} from 'date-fns'
import type {DashboardParams, HierarchyStatParams} from '@/api/modules/dashboards'
import useAcuteness from '@/utils/behaviours/use-acuteness'
import {hierarchyStatsTableHeads} from '@/views/dashboard/vkl-dashboard/dashboard-table-heads'
import type HierarchyStat from '@/types/api/dashboard/hierarchy-stats.type'
import {tableCurrencyFormatter} from '@/utils/formatter'
import useUrlParam from '@/utils/behaviours/use-url-param'
import {useHierarchyStatStore} from '@/stores/hierarchy-stat'
import {toggleValuesReverseMapping} from '@/utils/url/url-from-string'
import hasBeenCalledWithConfig from '@/utils/check-request-config'

export default function useHierarchyStatsList(
  baseConfig?: Ref<DashboardParams>,
) {
  const store = useHierarchyStatStore()

  const loadingHS = computed(() => store.hierarchyStats.pending)

  const activePageHS = ref(1)

  const {acutenessOptions, selectedAcuteness, acutenessDefault} = useAcuteness(
    undefined,
    () => (activePageHS.value = 1),
  )

  const timespanOptions = {trueLabel: 'Monat', trueValue: true, falseValue: false, falseLabel: 'Jahr'}

  const timespanDefault = timespanOptions.falseValue as boolean
  const selectedTimespan = useUrlParam(
    'hst',
    (bool: boolean) => bool.toString(),
    toggleValuesReverseMapping(timespanOptions) as (string: string | string[]) => boolean,
    timespanDefault)

  const now = new Date()
  const month = now.toLocaleString('de-DE', {month: 'long'})
  const year = now.toLocaleString('de-DE', {year: 'numeric'})
  const prevYear = subYears(now, 1).toLocaleString('de-DE', {
    year: 'numeric',
  })

  const computedHSTableHead = computed(() => {
    let list: any[] = []
    list = list.concat(hierarchyStatsTableHeads)
    let title = ''
    let title2 = ''
    if (selectedTimespan.value === timespanOptions.falseValue) {
      title += `YTD ${year}`
      title2 += `YTD ${prevYear}`
    }
    else {
      title += `MTD ${month} - ${year}`
      title2 += `MTD ${month} - ${prevYear}`
    }
    list.push(
      new ObjectTableHead<HierarchyStat>({
        id: 1,
        title: `Umsatz ${title}`,
        attribute: 'sum',
        sortable: false,
        formatter: tableCurrencyFormatter,
      }),
    )
    list.push(
      new ObjectTableHead<HierarchyStat>({
        id: 2,
        title: `Umsatz ${title2}`,
        attribute: 'prevYtd',
        sortable: false,
        formatter: tableCurrencyFormatter,
      }),

      new SlotTableHead({
        id: 3,
        title: '',
        slotName: 'ytd-light',
        sortable: false,
      }),
    )

    list.push(
      new ObjectTableHead<HierarchyStat>({
        id: 4,
        title: `Zielumsatz ${title}`,
        attribute: 'targetScaled',
        sortable: false,
        formatter: tableCurrencyFormatter,
      }),
      new SlotTableHead({
        id: 5,
        title: '',
        slotName: 'target-scaled-light',
        sortable: false,
      }),
    )

    let prevTitle = ''
    title = ''
    if (selectedTimespan.value === timespanOptions.falseValue) {
      prevTitle += `${prevYear}`
      title += `${year}`
    }
    else {
      prevTitle += `${month} - ${prevYear}`
      title += `${month} - ${year}`
    }

    list.push(
      new ObjectTableHead<HierarchyStat>({
        id: 6,
        title: `Umsatz ${title} hochgerechnet`,
        attribute: 'extrapolation',
        sortable: false,
        formatter: tableCurrencyFormatter,
      }),
    )
    // list.push(
    //   new SlotTableHead({
    //     id: 7,
    //     title: '',
    //     slotName: 'extrapolation-light',
    //     sortable: false,
    //   }),
    // )

    list.push(
      new ObjectTableHead<HierarchyStat>({
        id: 8,
        title: `Umsatz ${prevTitle} gesamt`,
        attribute: 'prevSum',
        sortable: false,
        formatter: tableCurrencyFormatter,
      }),
      new SlotTableHead({
        id: 9,
        title: '',
        slotName: 'previous-year-light',
        sortable: false,
      }),
    )

    list.push(
      new ObjectTableHead<HierarchyStat>({
        id: 10,
        title: `Zielumsatz ${title} gesamt`,
        attribute: 'target',
        sortable: false,
        formatter: tableCurrencyFormatter,
      }),

      new SlotTableHead({
        id: 11,
        title: '',
        slotName: 'revenue-target-light',
        sortable: false,
      }),
    )

    return list
  })

  const config = computed(() => {
    const acuteness = unref(selectedAcuteness) ? unref(selectedAcuteness) : acutenessDefault
    const monthly = unref(selectedTimespan) ? unref(selectedTimespan) as boolean : timespanDefault
    const config: HierarchyStatParams = {
      acuteness,
      monthly,
      page: unref(activePageHS),
    }
    return {...config, ...baseConfig?.value}
  })

  const currentConfig = ref()

  watchEffect(() => {
    if (!hasBeenCalledWithConfig(currentConfig, config)) {
      store.loadHierarchyStats(config.value)
      currentConfig.value = config.value
    }
  })

  const hierarchyStats = computed(() => {
    return store.hierarchyStats.data as HierarchyStat[]
  })
  const totalHS = computed(() => {
    return store.hierarchyStats.total
  })

  const calcTrafficLightValues = (
    value: number,
    compare: number,
  ): TrafficLightColor => {
    let color = TrafficLightColor.GREEN
    if (compare > 0) {
      if (value <= 0.95 * compare)
        color = TrafficLightColor.RED

      else if (value > 0.95 * compare && value < compare)
        color = TrafficLightColor.YELLOW
    }
    return color
  }

  const trafficLightExplanation
    = 'Bezüglich eines Vergleichwertes heißen die Ampelfarben: \n'
    + 'grün: höher, \n'
    + 'gelb: mehr als 95%, \n'
    + 'rot: weniger als 95%'

  const trafficLightInfo = (value: number, compare: number) => {
    const difference = compare - value
    let infoText = ''
    infoText += `Wert: ${tableCurrencyFormatter(value)}\n`
    infoText += `Vergleichswert: ${tableCurrencyFormatter(compare)}\n`
    if (!compare) {
      infoText += 'Kein Vergleichswert vorhanden.'
    }
    else {
      infoText += `Differenz: ${tableCurrencyFormatter(difference)}\n`
      infoText += 'Anteil: '
      infoText += `${((100 / compare) * value).toFixed(2)} %`
    }
    return infoText
  }

  return {
    loadingHS,
    timespanOptions,
    selectedTimespan,
    acutenessOptions,
    selectedAcuteness,
    computedHSTableHead,
    hierarchyStats,
    totalHS,
    activePageHS,
    calcTrafficLightValues,
    trafficLightExplanation,
    trafficLightInfo,
  }
}
