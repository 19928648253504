<template>
  <div class="flex flex-col h-full">
    <div class="flex">
      <h1 class="page-head uppercase">
        Daten-Imports
      </h1>
      <!--      <ImportTimestamp-->
      <!--        class="items-center mr-4 ml-auto"-->
      <!--        dependant-on="data-imports"-->
      <!--      />-->
    </div>
    <div class="grid grid-cols-2 h-full overflow-hidden auto-rows-fr">
      <k-card class="h-full">
        <template #title>
          <span class="card-head">
            <h2>Liste</h2>
          </span>
        </template>
        <div
          v-if="loadingTimestamps"
          class="h-full w-full flex justify-center"
        >
          <k-kurz-dot-loader class="self-center" />
        </div>
        <div
          v-else
          class="import-list w-full overflow-y-auto"
        >
          <div
            v-for="[key, value] in timestampsMap"
            :key="key"
            class="flex flex-col gap-4 mt-3"
          >
            <div class="flex flex-col mt-2 mb-4 ml-3">
              <div class="text-lg flex flex-row">
                <h1
                  class="w-1/2"
                  :class="{'font-bold': isActive(value.date)}"
                >
                  {{ formatDateToImportString(value.date) }}
                </h1>
                <font-awesome-icon
                  :icon="isOpen(value.date)? 'minus': 'plus'"
                  class="ml-5 mt-1 text text-primary font-bold text-xl cursor-pointer select-none"
                  @click="toggleTimestampDetails(value.date)"
                />
                <font-awesome-icon
                  icon="table"
                  class="ml-5 mt-1 text text-primary font-bold text-xl cursor-pointer select-none"
                  @click="setActive(value.date)"
                />
              </div>
              <div v-if="isOpen(value.date)">
                <div>
                  <k-list-entry
                    label="Neue Umsatzsumme"
                    :value="value.revenueSum"
                  />
                  <k-list-entry
                    label="Kritische Imports"
                    :value="value.criticalImports"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </k-card>
      <k-card class="h-full">
        <template #title>
          <span class="card-head">
            <h2>Details</h2>
          </span>
        </template>
        <div class="flex flex-col w-full gap-5">
          <div class="flex flex-row gap-2 pt-5 items-center justify-center">
            <font-awesome-icon
              class="hover:cursor-pointer self-center select-none"
              icon="angle-left"
              @click="prevDay"
            />
            <k-date-picker
              v-model="currentDate"
              :label-format="datePickerFormat"
              :preview-format="datePickerFormat"
              class="w-40"
            />
            <font-awesome-icon
              class="hover:cursor-pointer self-center select-none"
              icon="angle-right"
              @click="nextDay"
            />
          </div>
          <k-table
            class="w-full"
            :head="dataTimestampDetailsTableHeades"
            :data="timestampsByDate"
            async
          />
        </div>
      </k-card>
    </div>
  </div>
</template>

<script lang="ts">
import type {Ref} from 'vue'
import {computed, defineComponent, ref, watch, watchEffect} from 'vue'
import {addDays, format, isSameDay, subDays, subMonths} from 'date-fns'
import {dateFormatter, tableCurrencyFormatter} from '@/utils/formatter'
import {dataTimestampDetailsTableHeades} from '@/views/data-timestamp/data-timestamp-table-heads'
import useDataTimestampList from '@/views/data-timestamp/use-data-timestamp-list'
import type DataTimestamp from '@/types/api/data-timestamp.type'
import type {InvoiceAggregationParams} from '@/api/modules/invoices'
import {AggregationFunction} from '@/api/params/aggregation.type'
import {useInvoiceStore} from '@/stores/invoice'
import type {InvoiceAggregation} from '@/types/api/aggregation.type'

export default defineComponent({
  name: 'DataTimestampView',

  setup() {
    const rangeStart = ref()
    const rangeEnd = ref()
    const currentDate = ref()

    currentDate.value = new Date()
    rangeEnd.value = format(new Date(), 'yyyy-MM-dd')
    rangeStart.value = format(subMonths(new Date(), 3), 'yyyy-MM-dd')

    const {
      timestamps,
      totalTimestamps,
      activePage,
      loadingTimestamps,
    } = useDataTimestampList(rangeStart, rangeEnd, undefined)

    const invoiceStore = useInvoiceStore()
    const config: Ref<InvoiceAggregationParams> = ref({
      page: 1,
      invoice_date__gte: rangeStart.value,
      invoice_date__lte: rangeEnd.value,
      aggregationField: 'revenue',
      groupBy: 'invoice_date',
      aggregation: AggregationFunction.SUM,
    })
    watchEffect(() => {
      invoiceStore.loadInvoiceAggregate(config.value)
    })

    const invoices = computed(() => {
      return invoiceStore.aggregates.data as InvoiceAggregation[]
    })

    const prevDay = () => {
      currentDate.value = subDays(currentDate.value, 1)
    }
    const nextDay = () => {
      currentDate.value = addDays(currentDate.value, 1)
    }

    const importIsCritical = (timestamp: DataTimestamp): boolean => {
      // TODO condition for critical Import
      return true
    }

    const getRevenueSum = (date: Date): number | undefined => {
      if (invoices.value) {
        return invoices.value.find((el) => {
          return (el.invoiceDate!.toDateString() === date.toDateString())
        })?.value
      }
    }

    function formatDateToImportString(date: Date) {
      return `${date.toLocaleDateString('de-DE', {
        weekday: 'long',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
      })} Uhr`
    }

    const timestampsMap = computed(() => {
      const map = new Map()
      const list = timestamps.value
      list.forEach((timestamp) => {
        if (timestamp.fetched) {
          const fetched = timestamp.fetched.toDateString()
          let entry = map.get(fetched)
          if (!entry) {
            entry = {
              items: [],
              date: timestamp.fetched,
              revenueSum: tableCurrencyFormatter(getRevenueSum(timestamp.fetched)),
              criticalImports: 0,
            }
          }
          entry.items.push(timestamp)
          if (importIsCritical(timestamp))
            entry.criticalImports++
          map.set(fetched, entry)
        }
      })
      return map
    })

    watch(timestampsMap, () => {
      if (timestamps.value && timestamps.value.length > 0)
        currentDate.value = timestamps.value[0].fetched
    })

    const timestampsByDate = computed((): DataTimestamp[] => {
      let items: DataTimestamp[] = []
      const key = currentDate.value.toDateString()
      const entry = timestampsMap.value.get(key)
      if (entry)
        items = entry.items
      return items
    })

    const openDetails = ref<string[]>([])
    const toggleTimestampDetails = (date: string) => {
      const index = openDetails.value.indexOf(date)
      if (index >= 0)
        openDetails.value.splice(index, 1)

      else
        openDetails.value.push(date)
    }
    const isOpen = (date: string) => openDetails.value.includes(date)
    const setActive = (date: string) => {
      // const data = timestampMap.value.get(date.toDateString())
      currentDate.value = date
    }
    const datePickerFormat = (date: Date) => {
      return `${dateFormatter(date)}`
    }

    const isActive = (date: string) => {
      return isSameDay(currentDate.value, new Date(date))
    }

    return {
      toggleTimestampDetails,
      isOpen,
      prevDay,
      nextDay,

      tableCurrencyFormatter,
      dateFormatter,
      formatDateToImportString,

      currentDate,
      datePickerFormat,
      dataTimestampDetailsTableHeades,
      timestampsByDate,

      setActive,
      isActive,

      timestampsMap,
      openDetails,
      timestamps,
      totalTimestamps,
      activePage,
      loadingTimestamps,
    }
  },
})
</script>
