import {computed} from 'vue'
import {useEmployeeStore} from '@/stores/employee'

export default function useAllEmployees() {
  const store = useEmployeeStore()

  store.loadEmployees({pagesize: 1000})

  const employeesLoading = computed(() => {
    return store.employees.pending
  })

  const employees = computed(() => {
    return store.employees.data
  })

  return {
    employees, employeesLoading,
  }
}
