import {defineStore} from 'pinia'
import type Churn from '@/types/api/churn.type'
import api from '@/api'
import type {ChurnListParams} from '@/api/modules/churns'
import {PaginatedData} from '@/stores/index'

interface State {
  churns: PaginatedData<Churn>
  monthlyChurns: PaginatedData<Churn>
}

export const useChurnStore = defineStore('churn', {
  // see https://pinia.vuejs.org/core-concepts/state.html
  state: (): State => ({
    // define initial values
    churns: new PaginatedData<Churn>(),
    monthlyChurns: new PaginatedData<Churn>(),
  }),
  // see https://pinia.vuejs.org/core-concepts/getters.html
  getters: {
    // define getters to access compound or calculated data
  },
  // see https://pinia.vuejs.org/core-concepts/actions.html
  actions: {

    /**
         * load churns
         * @param {ChurnListParams} config - the config for the call
         * @param cancel
         * @param requestID
         */
    async loadChurns(config: ChurnListParams, cancel = true, requestID?: string) {
      const id = requestID || api.churn.getChurns.name
      if (cancel)
        api.churn.cancel(id)
      this.churns.pending = true
      return api.churn.cancelableCall(api.churn.getChurns, config, id)
        .then((data) => {
          const page = data.data
          this.churns.data = page.results
          this.churns.total = page.count
          if (!api.churn.hasPending(id))
            this.churns.pending = false
        }).catch((error) => {
          if (!api.churn.hasPending(id))
            this.churns.pending = false
          throw error
        })
    },

    /**
         * load churns
         * @param {ChurnListParams} config - the config for the call
         * @param cancel
         * @param requestID
         */
    async loadMonthlyChurns(config: ChurnListParams, cancel = true, requestID = 'monthlyChurns') {
      const id = requestID
      if (cancel)
        api.churn.cancel(id)
      this.monthlyChurns.pending = true
      return api.churn.cancelableCall(api.churn.getChurns, config, id)
        .then((data) => {
          const page = data.data
          this.monthlyChurns.total = page.count
          this.monthlyChurns.data = page.results
          if (!api.churn.hasPending(id))
            this.monthlyChurns.pending = false
        }).catch((error) => {
          if (!api.churn.hasPending(id))
            this.monthlyChurns.pending = false
          throw error
        })
    },

    /**
         * resets the store to the initial state.
         */
    clearChurns() {
      this.$reset()
    },
  },
})
