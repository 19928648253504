import type Customer from '@/types/api/customer.type'
import type Material from '@/types/api/material.type'
import type Employee from '@/types/api/employee.type'
import type Account from '@/types/api/account.type'
import type {OpenQuantity} from '@/types/api/quantity.type'

export default class Contract {
  public id?: number
  public receipt?: number
  public position?: number
  public customer?: Partial<Customer>
  public salesOrganization?: number
  public salesGroup?: number
  public material?: Partial<Material>
  public openRevenue?: number
  public revenue?: number

  public openQuantity?: number

  public quantities: Array<OpenQuantity> = []
  public baseQuantity?: number // id of the quantity

  public creationDate?: Date
  public updated?: Date

  public salesManager?: Partial<Employee>
  public responsibleEmployee?: Partial<Employee>

  public milestone?: string
  public durationStart?: Date
  public durationEnd?: Date
  public ratio?: number

  public state?: number
  public stateAppointee?: Account
}
