import {defineStore} from 'pinia'
import {PaginatedData} from '@/stores/index'
import type LowPerformer from '@/types/api/dashboard/low-performer.type'
import api from '@/api'
import type {LowPerformerParams} from '@/api/modules/dashboards'

interface State {
  lowPerformers: PaginatedData<LowPerformer>
}

export const useLowPerformerStore = defineStore('low-performer', {
  // see https://pinia.vuejs.org/core-concepts/state.html
  state: (): State => ({
    // define initial values
    lowPerformers: new PaginatedData<LowPerformer>(),
  }),
  // see https://pinia.vuejs.org/core-concepts/getters.html
  getters: {
    // define getters to access compound or calculated data
  },
  // see https://pinia.vuejs.org/core-concepts/actions.html
  actions: {

    /**
         * load lowPerformers
         * @param {LowPerformerParams} config - the config for the call
         * @param cancel
         * @param requestID
         */
    async loadLowPerformers(config: LowPerformerParams, cancel = true, requestID?: string) {
      const id = requestID || api.dashboard.getLowPerformers.name
      if (cancel)
        api.dashboard.cancel(id)
      this.lowPerformers.pending = true
      return api.dashboard.cancelableCall(api.dashboard.getLowPerformers, config, id)
        .then((data) => {
          const page = data.data
          this.lowPerformers.data = page.results
          this.lowPerformers.total = page.count
          if (!api.dashboard.hasPending(id))
            this.lowPerformers.pending = false
        }).catch((error) => {
          if (!api.dashboard.hasPending(id))
            this.lowPerformers.pending = false
          throw error
        })
    },

    /**
         * resets the store to the initial state.
         */
    clearLowPerformers() {
      this.$reset()
    },
  },
})
