import {ObjectTableHead} from '@singularit/kurz-components'

const accountTableHeads = [
  new ObjectTableHead({
    title: 'E-mail',
    attribute: 'email',
    sortable: false,
    id: 4,
  }),
  new ObjectTableHead({
    title: 'Vorname',
    attribute: 'firstName',
    sortable: false,
    id: 1,
  }),

  new ObjectTableHead({
    title: 'Nachname',
    attribute: 'lastName',
    sortable: false,
    id: 2,
  }),

  new ObjectTableHead({
    title: 'Username',
    attribute: 'username',
    sortable: false,
    id: 3,
  }),

  new ObjectTableHead({
    title: 'Rolle',
    attribute: 'role',
    sortable: false,
    id: 5,
    alignment: 'center',
  }),
]

export {accountTableHeads}
