import type {Ref} from 'vue'
import {computed, ref, unref, watchEffect} from 'vue'
import type {RadioOption} from '@singularit/kurz-components'
import {endOfMonth, startOfMonth, subMonths} from 'date-fns'
import type {ChartData, ChartEvent, LegendItem, LineOptions} from 'chart.js'

import type Customer from '@/types/api/customer.type'
import type {InvoiceGraphParams} from '@/api/modules/invoices'
import {graphCoordinateComparator} from '@/types/api/chart/graph-coordinate.type'
import {mapGraph, mapGraphLabels, mapGraphValues} from '@/utils/charts/graph-mapper'
import {currencyFormatter} from '@/utils/formatter'
import {fillYear, generateColorGradients, monthOfYearLabels} from '@/utils/charts/graph-util'
import {useInvoiceStore} from '@/stores/invoice'
import type GraphData from '@/types/api/chart/graph.type'

export default function useInvoicesChart(customer: Ref<Customer>) {
  const store = useInvoiceStore()

  const loadingChart = computed(() => store.invoiceGraph.pending)
  const now = new Date()
  const endPrevMonth = endOfMonth(subMonths(now, 1))
  const start3 = startOfMonth(subMonths(endPrevMonth, 2))
  const start6 = startOfMonth(subMonths(endPrevMonth, 5))
  const start12 = startOfMonth(subMonths(endPrevMonth, 11))
  const start24 = startOfMonth(subMonths(endPrevMonth, 23))

  // TODO put into map with enum for better understanding
  const timeSpanConfigs: Array<InvoiceGraphParams> = [
    {acuteness: 'year'},
    {
      acuteness: 'month',
      beginning: start3,
      end: endPrevMonth,
    },
    {
      acuteness: 'month',
      beginning: start6,
      end: endPrevMonth,
    },
    {
      acuteness: 'month',
      beginning: start12,
      end: endPrevMonth,
    },
    {
      acuteness: 'month',
      beginning: start24,
      end: endPrevMonth,
    },
    {acuteness: 'month'},
  ]

  const timeOptions: Array<RadioOption> = [
    {label: 'Gesamt', value: 0},
    {label: '3 Monate', value: 1},
    {label: '6 Monate', value: 2},
    {label: '1 Jahr', value: 3},
    {label: '2 Jahre', value: 4},
    {label: 'Jahresvergleich', value: 5},
  ]

  const timespan = ref(timeOptions[1].value)

  const config = computed(() => {
    let params: InvoiceGraphParams = {}
    if (timespan.value !== null)
      params = timeSpanConfigs[timespan.value]

    if (customer.value)
      params.customer = unref(customer)

    return params
  })

  watchEffect(() => {
    if (config.value.customer)
      store.loadInvoiceGraph(config.value)
      // store.dispatch(ActionTypes.GET_INVOICES_GRAPH_DATA, config.value)
  },
  )

  const graphData = computed(() => {
    const data: GraphData = store.invoiceGraph.data as GraphData
    if (data !== undefined && data.values) {
      const sortedValues = data.values.sort(graphCoordinateComparator)
      data.values = sortedValues
    }
    return data
  })

  const data = computed<ChartData<'line'>>(() => {
    const dataSets = []
    let labels = []
    if (timespan.value === 5) { // 5 is the index of the config
      labels = monthOfYearLabels()
      const graphMap = mapGraph(graphData.value)
      const colors = generateColorGradients(graphMap.size)

      for (const key of graphMap.keys()) {
        const data = fillYear(graphMap.get(key))
        const color = colors.pop()
        if (color !== undefined) {
          dataSets.push({
            label: key,
            data: mapGraphValues(data),
            borderColor: `#${color.toHex()}`,
            cubicInterpolationMode: 'monotone' as LineOptions['cubicInterpolationMode'],
            hidden: false,
          })
        }
      }
      // show only last 3 years
      const showAmount = 3
      let showCount = 0
      dataSets.reverse().forEach((item) => {
        item.hidden = !(showAmount > showCount)
        showCount++
      })
      dataSets.reverse() // undo reverse
    }
    else {
      labels = mapGraphLabels(graphData.value)
      dataSets.push({
        label: 'Umsatz', // TODO i18n
        data: mapGraphValues(graphData.value),
        borderColor: 'rgb(236, 106, 29)',
        cubicInterpolationMode: 'monotone' as LineOptions['cubicInterpolationMode'],
      })
    }
    return {
      labels,
      datasets: dataSets,
    }
  })

  const options = ref({
    scales: {
      y: {
        ticks: {
          callback(value: number) {
            return currencyFormatter.format(value)
          },
        },
      },
    },
    plugins: {
      responsive: true,
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          sort: (a: LegendItem, b: LegendItem) => a.text < b.text ? -1 : 1,
        },
        onHover: (event: ChartEvent) => {
          const target = event.native?.target
          if (target)
            (target as HTMLElement).style.cursor = 'pointer'
        },
        onLeave: (event: ChartEvent) => {
          const target = event.native?.target
          if (target)
            (target as HTMLElement).style.cursor = 'default'
        },
      },
    },
  })

  return {
    data,
    options,
    timeOptions,
    timespan,
    loadingChart,
  }
}
