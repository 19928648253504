import type {Ref} from 'vue'
import {computed, ref, unref, watchEffect} from 'vue'
import type {OrdersListParams} from '@/api/modules/orders'
import type Contract from '@/types/api/contract.type'
import type Customer from '@/types/api/customer.type'
import {useOrderStore} from '@/stores/order'
import useTableQuery from '@/utils/use-table-query'

export default function useOrderList(
  contractFilter?: Ref<Contract | undefined> | Contract,
  customerFilter?: Ref<Customer> | Customer,
) {
  const store = useOrderStore()

  const activePage = ref(1)
  const {query, reload} = useTableQuery()

  // initial loading
  const config = computed(() => {
    const contract = unref(contractFilter)
    const customer = unref(customerFilter)
    const config: OrdersListParams = {
      page: unref(activePage),
      contract,
      customer,
      query: unref(query),
    }
    return config
  })

  watchEffect(() => {
    store.loadOrders(config.value)
  })

  const loadingOrders = computed(() => store.orders.pending)
  const orders = computed(() => {
    return store.orders.data
  })
  const totalOrders = computed(() => {
    return store.orders.total
  })

  return {orders, totalOrders, activePage, loadingOrders, reload}
}
