import {ObjectTableHead, SlotTableHead} from '@singularit/kurz-components'
import type LowPerformer from '@/types/api/dashboard/low-performer.type'

const lowPerformersTableHeads = [
  new SlotTableHead({
    title: 'Kunde',
    slotName: 'customer-slot',
    sortable: true,
    sortKey: 'customer__name',
    id: 0,
    alignment: 'left',
  }),
  new ObjectTableHead<LowPerformer>({
    title: 'Änderung',
    attribute: 'revenueDelta',
    sortKey: 'revenue_delta',
    sortable: true,
    id: 2,
    formatter: (delta: number) => {
      if (delta)
        return `${delta}%`
      else
        return '-'
    },
    alignment: 'right',
  }),
]

const hierarchyStatsTableHeads = [
  new SlotTableHead({
    title: 'Hierarchie',
    sortable: false,
    id: 0,
    sortKey: 'number',
    slotName: 'hierarchy-number',
    alignment: 'left',
  }),
]

export {lowPerformersTableHeads, hierarchyStatsTableHeads}
