import {defineStore} from 'pinia'
import type BenchmarkChart from '@/types/api/dashboard/benchmark-chart.type'
import api from '@/api'
import type {BenchmarkParams} from '@/api/modules/dashboards'
import {RequestData} from '@/stores/index'

interface State {
  benchmarkChart: RequestData<BenchmarkChart>
}

export const useBenchmarkStore = defineStore('benchmark', {
  // see https://pinia.vuejs.org/core-concepts/state.html
  state: (): State => ({
    // define initial values
    benchmarkChart: new RequestData(),
  }),
  // see https://pinia.vuejs.org/core-concepts/getters.html
  getters: {
    // define getters to access compound or calculated data
  },
  // see https://pinia.vuejs.org/core-concepts/actions.html
  actions: {

    /**
         * load benchmarks
         * @param {BenchmarkParams} config - the config for the call
         * @param cancel
         * @param requestID
         */
    async loadBenchmarks(config: BenchmarkParams, cancel = true, requestID?: string) {
      const id = requestID || api.dashboard.getBenchmarks.name
      if (cancel)
        api.dashboard.cancel(id)
      this.benchmarkChart.pending = true
      return api.dashboard.cancelableCall(api.dashboard.getBenchmarks, config, id)
        .then((data) => {
          this.benchmarkChart.data = data.data
          if (!api.dashboard.hasPending(id))
            this.benchmarkChart.pending = false
        }).catch((error) => {
          if (!api.dashboard.hasPending(id))
            this.benchmarkChart.pending = false
          throw error
        })
    },

    /**
         * resets the store to the initial state.
         */
    clearBenchmarks() {
      this.$reset()
    },
  },
})
