import {computed} from 'vue'
import type {Option} from '@singularit/kurz-components'
import useAllEmployees from '@/utils/behaviours/use-all-employees'
import type Employee from '@/types/api/employee.type'
import useUrlParam from '@/utils/behaviours/use-url-param'
import {singleValueReverseMapping} from '@/utils/url/url-from-string'

export default function usePartnersFilter() {
  const {employees, employeesLoading} = useAllEmployees()

  const employeeOptions = computed(() => {
    const options: Array<Option> = []
    for (const employee of employees.value) {
      options.push({
        label: `${employee.lastName}, ${employee.firstName}`,
        value: employee,
      })
    }
    return options
  })

  const responsibleEmployee = useUrlParam(
    'empl',
    (employee: Employee) => `${employee ? employee.id : ''}`,
    singleValueReverseMapping(employeeOptions, true),
  )

  return {
    employees,
    employeeOptions,
    responsibleEmployee,
    employeesLoading,
  }
}
