<template>
  <div class="flex flex-col">
    <div class="flex">
      <h1
        v-if="offer"
        class="page-head cursor-pointer"
        @click="openOfferOverview"
      >
        Angebot {{ offer?.receipt }} / {{ offer?.position }}
      </h1>
      <ImportTimestamp
        class="items-center mr-4 ml-auto"
        dependant-on="offers"
      />
    </div>
    <div class="offer-details-layout grid grid-cols-2">
      <k-card class="col-span-2 lg:col-span-1">
        <template #title>
          <span class="card-head">
            <h2>Allgemeine Informationen</h2>
          </span>
        </template>
        <div
          v-if="loadingData"
          class="h-32 w-full flex justify-center"
        >
          <k-kurz-dot-loader class="self-center" />
        </div>
        <k-list-layout
          v-else
          class="flex-1"
        >
          <k-list-entry
            label="Kunde"
            :value="offer?.customer"
          >
            <div class="text-left w-1/2">
              <CustomerLink :customer="offer.customer" />
            </div>
          </k-list-entry>

          <k-list-entry
            label="Kontrakt"
            :value="offer?.contract"
          >
            <div class="text-left w-1/2">
              <ContractLink :contract="offer.contract" />
            </div>
          </k-list-entry>
          <k-list-entry
            label="Beleg"
            :value="offer.receipt"
            @click="openOfferOverview"
          >
            <div class="hover:text-blue-kurz-500 cursor-pointer">
              <span>{{ offer.receipt }}
                <font-awesome-icon
                  class="icon self-center"
                  icon="external-link-alt"
                />
              </span>
            </div>
          </k-list-entry>
          <k-list-entry
            label="Belegposition"
            :value="offer.position"
          />

          <k-list-entry
            label="Material"
            :value="offer?.material"
          >
            <span class="text-left w-1/2">
              {{ offer.material.materialDescription }} ({{ offer.material.materialNumber }})
            </span>
          </k-list-entry>

          <k-list-entry
            label="Erstellungsdatum"
            :value="creationDate"
          />
          <k-list-entry
            label="letzte Datenaktualisierung"
            :value="updatedDate"
          >
            <div class="text-left w-1/2">
              <span>{{ updatedDate }}</span>
            </div>
          </k-list-entry>
          <k-list-entry
            label="Status"
            :value="status"
          >
            <template #icon>
              <font-awesome-icon
                class="icon hover:cursor-pointer"
                icon="edit"
                @click="openChangeState"
              />
            </template>
            <div class="text-left w-1/2">
              {{ statusDescription }}
            </div>
          </k-list-entry>
          <k-modal
            :modal-active="showStateModal"
            @close="showStateModal=false"
          >
            <StateChanger
              :offer="offer"
              @save="applyNewState($event); showStateModal=false;"
            />
          </k-modal>
        </k-list-layout>
      </k-card>
      <k-card class="col-span-2 lg:col-span-1">
        <template #title>
          <span class="card-head">
            <h2>Allgemeine Informationen</h2>
          </span>
        </template>
        <div
          v-if="loadingData"
          class="h-32 w-full flex justify-center"
        >
          <k-kurz-dot-loader class="self-center" />
        </div>
        <k-list-layout
          v-else
          class="flex-1"
        >
          <k-list-entry
            label="Geplanter Umsatz"
            :value="revenue"
          >
            <div class="text-left w-1/2">
              <span>{{ revenue }}</span>
            </div>
          </k-list-entry>
          <k-list-entry
            label="Geplante Menge"
            :value="offer.baseQuantity"
          >
            <div class="text-left w-1/2">
              <span>
                {{
                  quantityFormatter.format(getBaseQuantity(offer).quantity)
                }} {{ unitFormatter(getBaseQuantity(offer).unit) }}
              </span>
            </div>
          </k-list-entry>
          <k-list-entry
            label="Laufzeit Beginn"
            :value="durationStart"
          >
            <div class="text-left w-1/2">
              <span>{{ durationStart }} </span>
            </div>
          </k-list-entry>
          <k-list-entry
            label="Laufzeit Ende"
            :value="durationEnd"
          >
            <div class="text-left w-1/2">
              <span>{{ durationEnd }} </span>
            </div>
          </k-list-entry>
        </k-list-layout>
      </k-card>
    </div>
    <OfferPositionsCard
      v-if="offer && offer.receipt"
      :offer-receipt="offer.receipt"
      :current-position="offer.position"
    />
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, ref, watchEffect} from 'vue'
import {useRouter} from 'vue-router'
import {currencyFormatter, quantityFormatter, unitFormatter} from '@/utils/formatter'
import CustomerLink from '@/components/links/CustomerLink.vue'
import ContractLink from '@/components/links/ContractLink.vue'
import ImportTimestamp from '@/components/timestamp/ImportTimestamp.vue'
import {useComputedDate} from '@/utils/behaviours/use-formatter'
import StateChanger from '@/components/state-changer/StateChanger.vue'
import type Status from '@/types/api/status.type'
import {useOfferStore} from '@/stores/offer'
import type Offer from '@/types/api/offer.type'
import {getBaseQuantity} from '@/utils/use-quantities'
import OfferPositionsCard from '@/components/cards/OfferPositionsCard.vue'

export default defineComponent({
  name: 'OffersDetailView',
  components: {OfferPositionsCard, ImportTimestamp, ContractLink, CustomerLink, StateChanger},
  props: {
    offerReceipt: {
      type: Number,
      required: true,
    },
    offerPosition: {
      type: Number,
      required: true,
    },
  },

  setup(props) {
    const store = useOfferStore()
    const router = useRouter()

    // initial loading
    watchEffect(() => {
      store.loadOffer({receipt: props.offerReceipt, position: props.offerPosition}).catch(() => {
        router.back()
      })
    })

    const offer = computed(() => {
      return store.offer.data as Offer
    })

    const loadingData = computed(() => store.offer.pending)

    const durationStart = useComputedDate(offer, 'start')
    const durationEnd = useComputedDate(offer, 'end')

    const creationDate = useComputedDate(offer, 'created')
    const updatedDate = useComputedDate(offer, 'updated')
    const revenue = computed(() => {
      let result = null
      if (offer.value && offer.value.revenue)
        result = currencyFormatter.format(offer.value.revenue)

      return result
    })

    const status = computed(() => {
      if (offer.value && offer.value.state) {
        switch (offer.value.state) {
          case 1:
            return 'Offen'
          case 2:
            return 'In Absprache'
          case 3:
            return 'Beendet'
          default:
            return null
        }
      }
      else {
        return null
      }
    })

    const statusDescription = computed(() => {
      if (status.value && offer.value.stateAppointee) {
        const appointee = offer.value.stateAppointee
        return `${status.value}, Beauftrager: ${appointee.firstName} ${appointee.lastName}`
      }
      else {
        return status.value
      }
    })

    const showStateModal = ref(false)

    function applyNewState(newState: Status) {
      store.changeState(newState)
      // store.commit(MutationTypes.EDIT_OFFER_STATE, newState)
    }

    function openChangeState() {
      showStateModal.value = true
    }

    function openOfferOverview() {
      router.push({
        name: 'offer-overview',
        params: {
          offerReceipt: offer.value.receipt,
        },
      })
    }

    return {
      loadingData,
      offer,
      creationDate,
      updatedDate,
      durationStart,
      durationEnd,
      revenue,
      status,
      statusDescription,

      showStateModal,
      openChangeState,
      applyNewState,
      getBaseQuantity,
      quantityFormatter,
      unitFormatter,
      openOfferOverview,
    }
  },
})
</script>

<style scoped>
.offer-details-layout {
  @apply flex flex-col;
  @apply xl:grid xl:grid-cols-2 xl:gap-4;
  @apply xl:overflow-y-hidden;
  @apply xl:h-full;
}
</style>
