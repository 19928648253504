import type {AxiosResponse} from 'axios'
import axiosInstance from '@/api/axios'
import type Page from '@/types/api/page.type'
import type PaginationListParameters from '@/api/modules/pagination-list-parameters'
import type Feedback from '@/types/api/feedback.type'
import BaseModule from '@/api/modules/base-module'
import type Customer from '@/types/api/customer.type'
import type QueryParameters from '@/api/modules/query-parameters'

export interface FeedbackListParams extends PaginationListParameters, QueryParameters {
  customer?: Customer
}

class FeedbackModule extends BaseModule {
  getFeedbacks(params: FeedbackListParams, controller?: AbortController): Promise<AxiosResponse<Page<Feedback>>> {
    const signal = controller ? controller.signal : undefined

    const data = {
      params,
      signal,
    }
    return axiosInstance.get('/feedbacks/', data).then((response: AxiosResponse) => {
      return response
    })
  }

  saveFeedback(feedback: Feedback) {
    let url = '/feedbacks/'
    if (feedback.id) url += feedback.id
    const params = {
      ...feedback,
      customer: feedback.customer?.id,
      author: feedback.author?.id,
      creator: feedback.creator?.id,
    }
    return axiosInstance.post(url, params)
  }
}

const module = new FeedbackModule()
export default module
