import type {ComputedRef} from 'vue'
import {computed} from 'vue'
import type {Option, ToggleValues} from '@singularit/kurz-components'
// import useUrlParam from '@/utils/behaviours/use-url-param'
import useUrlParam from '@/utils/behaviours/use-url-param'
import useCountryCodeFilter from '@/views/dashboard/vkl-dashboard/use-country-code-filter'
import {multipleValuesReverseMapping} from '@/utils/url/url-from-string'
import {useCustomerStore} from '@/stores/customer'
import {useBranchStore} from '@/stores/branch'
import type IncludeExcludeParameters from '@/api/modules/include-exclude-parameters'
// import {useCustomerStore} from '@/stores/employee'

// TODO when filters are available
export default function useIncludeExcludeFilters() {
  const customerStore = useCustomerStore()
  const branchStore = useBranchStore()
  const includeToggleOptions: ToggleValues = {trueLabel: 'inkludieren', trueValue: true, falseValue: false, falseLabel: 'exkludieren'}

  customerStore.loadAllCustomerIds()

  const ids = computed(() => {
    return customerStore.ids.data ? customerStore.ids.data : []
  })

  branchStore.loadBranches({pagesize: 1000})

  const branches = computed(() => {
    return branchStore.branches.data ? branchStore.branches.data : []
  })
  // const countries = ref<Array<string>>([])

  const idOptions: ComputedRef<Array<Option>> = computed(() => {
    const options = []
    for (const id of ids.value) {
      options.push({
        label: id.toString(),
        value: id,
      })
    }
    return options
  })
  const branchOptions: ComputedRef<Array<Option>> = computed(() => {
    const options = []
    for (const branch of branches.value) {
      options.push({
        label: branch.name,
        value: branch.id,
      })
    }
    return options
  })

  const {countryCodes: countryOptions} = useCountryCodeFilter()
  // const countryOptions: ComputedRef<Array<Option>> = computed(() => {
  //   const options = []
  //   for (const country of countries.value) {
  //     options.push({
  //       label: country.name,
  //       value: country.code,
  //     })
  //   }
  //   return options
  // })

  const filteredIds = useUrlParam(
    'ids',
    (ids: number[]) => ids.map(id => id ? id.toString() : ''),
    multipleValuesReverseMapping(idOptions),
    [],
  )
  const includeIds = useUrlParam(
    'incids',
    (value: boolean) => value.toString(),
    string => string.includes('true'),
    includeToggleOptions.trueValue as boolean,
  )

  const filteredBranches = useUrlParam(
    'br',
    (branches: number[]) => branches.map(branch => branch ? branch.toString() : ''),
    multipleValuesReverseMapping(branchOptions),
    [],
  )
  const includeBranches = useUrlParam(
    'incbr',
    (value: boolean) => value.toString(),
    string => string.includes('true'),
    includeToggleOptions.trueValue as boolean,
  )

  const filteredCountries = useUrlParam(
    'ctry',
    (countryCodes: string[]) => countryCodes.map(countryCode => countryCode || ''),
    multipleValuesReverseMapping(countryOptions),
    [],
  )
  const includeCountries = useUrlParam(
    'incctry',
    (value: boolean) => value.toString(),
    string => string.includes('true'),
    includeToggleOptions.trueValue as boolean,
  )

  const includeExcludeConfig: ComputedRef<IncludeExcludeParameters> = computed(() => {
    const config: IncludeExcludeParameters = {}
    if (filteredIds.value.length > 0) {
      if (includeIds.value)
        config.incIds = filteredIds.value
      else
        config.excIds = filteredIds.value
    }
    if (filteredBranches.value.length > 0) {
      if (includeBranches.value)
        config.incBranches = filteredBranches.value
      else
        config.excBranches = filteredBranches.value
    }
    if (filteredCountries.value.length > 0) {
      if (includeCountries.value)
        config.incCountries = filteredCountries.value
      else
        config.excCountries = filteredCountries.value
    }
    return config
  })

  const filtersLoading = computed(() => {
    return customerStore.ids.pending || branchStore.branches.pending // || countryStore.countries.pending
  })

  return {
    includeToggleOptions,

    filteredIds,
    includeIds,
    filteredBranches,
    includeBranches,
    filteredCountries,
    includeCountries,

    idOptions,
    branchOptions,
    countryOptions,

    includeExcludeConfig,
    filtersLoading,
  }
}
