<template>
  <div class="dashboard">
    <div class="w-full z-10 bg-lightwhite">
      <div class="flex">
        <h1 class="page-head">
          Dashboard VK-Leitung
        </h1>
        <div class="h-10 w-10 mt-1 flex items-center justify-center cursor-pointer">
          <font-awesome-icon
            :icon="[isFilterActive ? 'fas' : 'far', 'filter']"
            class="text-2xl"
            @click="showFilter = true"
          />
        </div>
        <ImportTimestamp
          dependant-on="invoices"
          class="mr-4 ml-8 xl:ml-auto"
        />
      </div>
    </div>
    <div class="h-full overflow-auto xl:overflow-hidden flex-1">
      <div class="dashboard-content">
        <k-card class="dashboard-card xl:col-span-4 xl:row-span-2 mb-0">
          <template #title>
            <span class="card-head">
              <h2>Absatzentwicklung</h2>
            </span>
          </template>
          <div
            v-if="loadingBenchmark"
            class="h-full w-full flex justify-center"
          >
            <k-kurz-dot-loader class="self-center" />
          </div>
          <div
            v-else
            class="h-full flex flex-row justify-between p-2"
          >
            <div class="flex flex-col flex-1">
              <LineChart
                :chart-data="data"
                :options="options"
                class="mr-5 mt-2 h-full w-full pr-5"
                :height="450"
              />
            </div>
            <div class="flex flex-col gap-3 mt-2 p-2 overflow-auto">
              <div>
                <info-toggle
                  v-model="unit"
                  label="Größe"
                  :options="unitOptions"
                  :tooltip-text="squareMeterInfoText"
                  :prefix-label="unitOptions.falseLabel"
                  :suffix-label="unitOptions.trueLabel"
                  off-color="bg-blue-kurz-600"
                />
              </div>
              <info-toggle
                v-model="timespanChart"
                label="Zeit"
                :options="timeOptionsChart"
                :prefix-label="timeOptionsChart.falseLabel "
                :suffix-label="timeOptionsChart.trueLabel"
                off-color="bg-blue-kurz-600"
              />
              <info-toggle
                v-model="benchmark"
                label="Benchmark"
                :options="benchmarkOptions"
                :prefix-label="benchmarkOptions.falseLabel"
                :suffix-label="benchmarkOptions.trueLabel"
                off-color="bg-blue-kurz-600"
                disabled
              />
              <info-toggle
                v-model="cumulate"
                label="Kumulieren"
                :options="cumulateOption"
                :prefix-label="cumulateOption.falseLabel"
                :suffix-label="cumulateOption.trueLabel"
                off-color="bg-blue-kurz-600"
              />
            </div>
          </div>
        </k-card>
        <k-card
          class="dashboard-card xl:col-span-3 xl:row-span-2"
          :info-text="lowPerformerDefinition"
        >
          <template #title>
            <span class="card-head">
              <h2>{{ lowPerformerLabel }}</h2>
            </span>
          </template>
          <div class="flex flex-col flex-1 gap-3 p-2 w-full overflow-hidden">
            <div class="flex gap-3 overflow-auto">
              <info-toggle
                v-model="filtering"
                label="Filter"
                :options="globalOptions"
                :prefix-label="globalOptions.falseLabel"
                :suffix-label="globalOptions.trueLabel"
                off-color="bg-blue-kurz-600"
              />
              <info-toggle
                v-model="selectedTimespanLP"
                label="Zeit"
                :options="timespanOptionLP"
                :prefix-label="timespanOptionLP.falseLabel"
                :suffix-label="timespanOptionLP.trueLabel"
                off-color="bg-blue-kurz-600"
              />
            </div>
            <k-table
              v-model:page="activePageLP"
              class="flex-1"
              :head="computedLPTableHead"
              :data="lowPerformers"
              :total-rows="totalLP"
              :default-sort-id="2"
              :data-loading="loadingLP"
              async
              @reload="reload"
              @change-page="activePageLP = $event"
              @row-select="openCustomerDetailView"
            >
              <template #customer-slot="{content}">
                <div
                  v-if="content.customer"
                  class="customer-name-wrapper whitespace-nowrap"
                >
                  {{ content.customer.name }}
                  <span class="customer-id invisible">
                    ID: {{ content.customer.id }}
                  </span>
                </div>
                <div v-else>
                  -
                </div>
              </template>
            </k-table>
          </div>
        </k-card>
        <k-card
          class="dashboard-card xl:col-span-7 xl:row-span-2"
          :info-text="trafficLightExplanation"
        >
          <template #title>
            <span class="card-head">
              <h2>Hierarchien</h2>
            </span>
          </template>
          <div class="flex flex-1 flex-col overflow-hidden">
            <div class="flex flex-1">
              <k-base-radio-group
                v-model="selectedAcuteness"
                horizontal
                label="Hierarchie"
                :options="acutenessOptions"
              />
              <info-toggle
                v-model="selectedTimespanHS"
                label="Zeit"
                :options="timespanOptionsHS"
                :prefix-label="timespanOptionsHS.falseLabel"
                :suffix-label="timespanOptionsHS.trueLabel"
                off-color="bg-blue-kurz-600"
              />
            </div>

            <k-table
              v-model:page="activePageHS"
              class="gap-3"
              :head="computedHSTableHead"
              :data="hierarchyStats"
              :total-rows="totalHS"
              :selectable="false"
              :data-loading="loadingHS"
              async
              @change-page="activePageHS = $event"
            >
              <template #hierarchy-number="{ content }">
                <div
                  v-if="content.hierarchy"
                  class="whitespace-nowrap"
                >
                  {{ getHierarchyLabel(content.hierarchy) }}
                </div>
                <div v-else>
                  -
                </div>
              </template>
              <template #ytd-light="{content}">
                <k-traffic-light
                  :value="calcTrafficLightValues(content.ytd, content.prevYtd)"
                  :info="trafficLightInfo(content.ytd, content.prevYtd)"
                />
              </template>
              <template #target-scaled-light="{content}">
                <k-traffic-light
                  :value="calcTrafficLightValues(content.sum, content.targetScaled)"
                  :info="trafficLightInfo(content.sum, content.targetScaled)"
                />
              </template>
              <!--            <template #extrapolation-light="{content}">-->
              <!--              <k-traffic-light :value="calcTrafficLightValues(content.extrapolation, content.prevSum)" />-->
              <!--            </template>-->

              <template #previous-year-light="{content}">
                <k-traffic-light
                  :value="calcTrafficLightValues(content.extrapolation, content.prevSum)"
                  :info="trafficLightInfo(content.extrapolation, content.prevSum)"
                />
              </template>
              <template #revenue-target-light="{content}">
                <k-traffic-light
                  :value="calcTrafficLightValues(content.extrapolation, content.target)"
                  :info="trafficLightInfo(content.extrapolation, content.target)"
                />
              </template>
            </k-table>
          </div>
        </k-card>
      </div>
    </div>
    <k-modal
      :modal-active="showFilter"
      @close="showFilter=false"
    >
      <template #head>
        <h1 class="page-head">
          Filter
        </h1>
      </template>
      <hr>
      <div
        v-if="filtersLoading"
        class="flex justify-center py-10"
      >
        <k-kurz-dot-loader />
      </div>
      <div
        v-else
        class="flex flex-col pb-5"
      >
        <div class="filter-list flex flex-col gap-5 py-5">
          <k-select-input
            v-model="selectedSalesOrganization"
            class="max-w-xs m-0"
            placeholder="Verkaufsorganisation"
            clear-able
            :options="salesOrganizations"
          />
          <k-select-input
            v-model="selectedCountryCode"
            class="max-w-xs m-0"
            placeholder="Land"
            clear-able
            :options="countryCodes"
          />
          <div class="filter-list-group">
            <h1>Produkthierarchien:</h1>
            <k-kurz-multi-select
              v-model="ph1selected"
              placeholder="PH1"
              clearable
              :options="level1"
              :label-function="hierarchyLabelFunction"
            />
            <k-kurz-multi-select
              v-model="ph2selected"
              class="max-w-xs m-0"
              placeholder="PH2"
              :disabled="level2Disabled"
              clearable
              :options="level2"
              :label-function="hierarchyLabelFunction"
            />
            <k-kurz-multi-select
              v-model="ph3selected"
              class="max-w-xs m-0"
              placeholder="PH3"
              :disabled="level3Disabled"
              clearable
              :options="level3"
              :label-function="hierarchyLabelFunction"
            />
          </div>
        </div>
        <hr>
        <div class="flex justify-between pt-5">
          <k-kurz-button
            text="Filter laden"
            @click="setUserFilter"
          />
          <k-kurz-button
            text="Filter speichern"
            @click="saveFilter"
          />
        </div>
      </div>
    </k-modal>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, ref, unref} from 'vue'
import {LineChart} from 'vue-chart-3'
import {useRoute, useRouter} from 'vue-router'
import useBenchmarkChart from '@/views/dashboard/vkl-dashboard/use-benchmark-chart'
import useLowPerformer from '@/views/dashboard/vkl-dashboard/use-low-performer'
import useHierarchyStatsList from '@/views/dashboard/vkl-dashboard/use-hierarchy-stats-list'
import useHierarchyFilters from '@/views/dashboard/vkl-dashboard/use-hierarchy-filters'
import type {DashboardParams} from '@/api/modules/dashboards'
import {getHierarchyLabel} from '@/utils/formatter'
import useSaleOrganisationFilter from '@/views/dashboard/vkl-dashboard/use-sales-organisation-filter'
import type LowPerformer from '@/types/api/dashboard/low-performer.type'
import useCountryCodeFilter from '@/views/dashboard/vkl-dashboard/use-country-code-filter'
import ImportTimestamp from '@/components/timestamp/ImportTimestamp.vue'
import InfoToggle from '@/components/toggle/InfoToggle.vue'
import useUiFilter from '@/utils/use-ui-filter'
import useUrlParam from '@/utils/behaviours/use-url-param'
import {singleValueReverseMapping} from '@/utils/url/url-from-string'

export default defineComponent({
  name: 'VKLDashboard',
  components: {ImportTimestamp, LineChart, InfoToggle},
  setup() {
    const router = useRouter()
    const route = useRoute()

    const {
      level1,
      level2,
      level3,
      level2Disabled,
      level3Disabled,
      ph1selected,
      ph2selected,
      ph3selected,
      hierarchyLabelFunction,
      hierarchiesLoading,
    } = useHierarchyFilters()

    const squareMeterInfoText = 'm² Filter beinhaltet nur Rechungen auf m² Basis'

    const {salesOrganizations} = useSaleOrganisationFilter()

    const selectedSalesOrganization = useUrlParam('orga', (value: number) => value ? value.toString() : '', singleValueReverseMapping(salesOrganizations), undefined)

    const {countryCodes} = useCountryCodeFilter()

    const selectedCountryCode = useUrlParam('country', (value: string) => value, singleValueReverseMapping(countryCodes))

    const filtersLoading = computed(() => {
      return hierarchiesLoading.value // || countriesLoading.value || orgasLoading.value
    })

    const baseConfig = computed(() => {
      const config: DashboardParams = {}
      if (unref(ph1selected)) {
        const hierarchies1 = []
        for (const ph1s of unref(ph1selected)) {
          if (ph1s.id !== undefined)
            hierarchies1.push(ph1s.id)
        }
        config.hierarchies1 = hierarchies1
      }
      if (unref(ph2selected)) {
        const hierarchies2 = []
        for (const ph2s of unref(ph2selected)) {
          if (ph2s.id !== undefined)
            hierarchies2.push(ph2s.id)
        }
        config.hierarchies2 = hierarchies2
      }
      if (unref(ph3selected)) {
        const hierarchies3 = []
        for (const ph3s of unref(ph3selected)) {
          if (ph3s.id !== undefined)
            hierarchies3.push(ph3s.id)
        }
        config.hierarchies3 = hierarchies3
      }
      if (unref(selectedSalesOrganization) && selectedSalesOrganization.value !== 0)
        config.salesOrganization = unref(selectedSalesOrganization)

      if (unref(selectedCountryCode) && selectedCountryCode.value !== '')
        config.country = unref(selectedCountryCode)

      return config
    })

    const now = new Date()
    const month = now.toLocaleString('de-DE', {month: 'long'})
    const year = now.toLocaleString('de-DE', {year: 'numeric'})

    function openCustomerDetailView(data: LowPerformer) {
      router.push({
        name: 'customer-detail',
        params: {
          customerId: data?.customer?.id,
        },
      })
    }

    const {
      selectedTimespan: selectedTimespanLP,
      timespanOptions: timespanOptionLP,
      globalOptions,
      filtering,
      lowPerformers,
      computedLPTableHead,
      totalLP,
      activePageLP,
      lowPerformerDefinition,
      loadingLP,
      reload,
    } = useLowPerformer(
      5000,
      baseConfig,
    )

    const lowPerformerLabel = computed(() => {
      let label = 'Low Performer '
      if (selectedTimespanLP.value === timespanOptionLP.trueValue)
        label += `(${year})`

      else
        label += `(${month} - ${year})`

      return label
    })

    const {
      data,
      options,
      unitOptions,
      unit,
      timeOptions: timeOptionsChart,
      timespan: timespanChart,
      benchmarkOptions,
      benchmark,
      cumulateOption,
      cumulate,
      loadingBenchmark,
    } = useBenchmarkChart(baseConfig)

    const {
      timespanOptions: timespanOptionsHS,
      selectedTimespan: selectedTimespanHS,
      acutenessOptions,
      selectedAcuteness,
      computedHSTableHead,
      hierarchyStats,
      totalHS,
      activePageHS,
      calcTrafficLightValues,
      trafficLightExplanation,
      trafficLightInfo,
      loadingHS,
    } = useHierarchyStatsList(
      baseConfig,
    )

    const {setUserFilter, saveFilter, loadFilter, hasFilterPermission} = useUiFilter('vklDashboard')
    const showFilter = ref(false)
    const isFilterActive = computed(() => {
      return ph1selected.value.length > 0
          || ph2selected.value.length > 0
          || ph3selected.value.length > 0
          || selectedCountryCode.value !== undefined
          || selectedSalesOrganization.value !== undefined
    })

    return {
      level1,
      level2,
      level3,
      ph1selected,
      ph2selected,
      ph3selected,
      level2Disabled,
      level3Disabled,
      hierarchyLabelFunction,

      squareMeterInfoText,

      selectedSalesOrganization,
      salesOrganizations,

      selectedCountryCode,
      countryCodes,

      loadingLP,
      lowPerformerDefinition,
      lowPerformerLabel,
      lowPerformers,
      totalLP,
      computedLPTableHead,
      globalOptions,
      timespanOptionLP,
      selectedTimespanLP,
      filtering,
      openCustomerDetailView,
      reload,

      activePageLP,
      data,
      options,
      unitOptions,
      unit,
      timespanChart,
      benchmark,
      timeOptionsChart,
      benchmarkOptions,
      cumulateOption,
      cumulate,
      loadingBenchmark,

      trafficLightExplanation,
      trafficLightInfo,
      hierarchyStats,
      totalHS,
      computedHSTableHead,
      getHierarchyLabel,
      acutenessOptions,
      selectedAcuteness,
      activePageHS,
      loadingHS,

      calcTrafficLightValues,

      timespanOptionsHS,
      selectedTimespanHS,
      route,

      filtersLoading,
      isFilterActive,
      setUserFilter,
      loadFilter,
      saveFilter,
      hasFilterPermission,
      showFilter,
    }
  },
})
</script>

<style scoped>
.dashboard {
  @apply flex flex-col;
  @apply overflow-hidden;
  @apply h-full;
}

.dashboard-content {
  @apply flex flex-col;
  @apply xl:grid xl:grid-cols-7 xl:grid-rows-4 xl:gap-4;
  @apply xl:overflow-y-hidden;
  @apply xl:h-full;
}

.dashboard-card {
  max-height: calc(100vh - 10rem);
}

.customer-name-wrapper:hover .customer-id {
  @apply visible;
}

.filter-list-group {
  @apply flex flex-col gap-2;
}

</style>
