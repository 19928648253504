<template>
  <k-card
    class="h-full"
    show-overflow
    header
  >
    <template #title>
      Benutzergruppe verwalten
    </template>
    <div class="mx-auto mt-10 w-3/5 flex flex-col gap-6">
      <div class="flex flex-row">
        <span class="w-1/3 mt-1 font-bold">Benutzergruppe ändern </span>
        <k-select-input
          v-model="currentGroup"
          placeholder="Benutzergruppen"
          clearable
          :options="groupOptions"
        />
      </div>
      <k-kurz-button
        class="w-1/6 mt-7 mx-auto"
        @click="updateGroup"
      >
        Ändern
      </k-kurz-button>
    </div>
  </k-card>
</template>

<script setup lang="ts">

import type {Ref} from 'vue'
import {computed, onBeforeMount, ref, toRef} from 'vue'
import {useToast} from 'vue-toastification'
import {useAccountStore} from '@/stores/account'
import type {AccountGroupPostParams} from '@/api/modules/accounts'
import type Group from '@/types/api/group.type'

interface Props {
  accountId: number
}

const props = defineProps<Props>()
const user = toRef(props, 'accountId')

const currentGroup = ref()
const store = useAccountStore()

const config: Ref<AccountGroupPostParams> = computed(() => {
  return {
    id: props.accountId,
    group: currentGroup.value,
  }
})

const toast = useToast()
const updateGroup = () => {
  store.postAccountGroup(config.value).then(() =>
    toast.success('Benutzergruppe wurde erfolgreich geändert'))
    .catch(() => {
      toast.error('Benutzergruppe konnte nicht geändert werden.')
    })
}

const accountGroup = computed(() => {
  return store.account.data?.groups
})

onBeforeMount(() => {
  store.loadAccountGroups({id: props.accountId}).then((data: Group[]) => currentGroup.value = data[0].id,
  )
})

const groupOptions = [
  {label: 'Gruppe 0', value: 5},
  {label: 'Gruppe 1', value: 1},
  {label: 'Gruppe 2', value: 2},
  {label: 'Gruppe 3', value: 3},
  {label: 'Gruppe 4', value: 4},
]

</script>

<style scoped>

</style>
