import type {AxiosResponse} from 'axios'
import axiosInstance from '@/api/axios'
import type Page from '@/types/api/page.type'
import type PaginationListParameters from '@/api/modules/pagination-list-parameters'
import BaseModule from '@/api/modules/base-module'
import type QueryParameters from '@/api/modules/query-parameters'
import type Employee from '@/types/api/employee.type'

export interface EmployeeListParams extends PaginationListParameters, QueryParameters {
  account?: number
}

export interface EmployeeParams {
  id?: number

}

class EmployeeModule extends BaseModule {
  getEmployees(params: EmployeeListParams, controller?: AbortController): Promise<AxiosResponse<Page<Employee>>> {
    const signal = controller ? controller.signal : undefined

    const data = {
      params,
      signal,
    }
    return axiosInstance.get('/employees/', data).then((response: AxiosResponse) => {
      return response
    })
  }

  getEmployee(params: EmployeeParams, controller?: AbortController): Promise<AxiosResponse<Employee>> {
    const signal = controller ? controller.signal : undefined
    const id = params.id
    delete params.id
    const data = {
      params,
      signal,
    }
    return axiosInstance.get(`/employees/${id}/`, data)
  }
}

const module = new EmployeeModule()
export default module
