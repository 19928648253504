export interface State<T> {
  data: T
  pending: boolean
}

export interface RootState {
  pendingRequests: number
}

export const defaultState = (): RootState => {
  return {
    pendingRequests: 0,
  }
}

export class RequestData<T> {
  data?: T
  pending = false

  constructor(data?: T) {
    this.data = data
  }
}

export class PaginatedData<T> {
  total = 0
  data: Array<T> = []
  pending = false
}

export interface DefaultPagination<D> {
  current_page: number
  first_page_url: string
  last_page_url: string
  next_page_url: string
  prev_page_url: string
  from: number
  to: number
  last_page: number
  per_page: number
  total: number
  path: string
  data: Array<D>
}
