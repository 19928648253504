import CreateUpdateModel from '@/types/api/create-update-model.type'
import type Offer from '@/types/api/offer.type'
import type Contract from '@/types/api/contract.type'
import type Account from '@/types/api/account.type'

export default class Status extends CreateUpdateModel {
  offer?: Offer
  contract?: Contract
  state?: number
  stateAppointee?: Account
}
