import {computed} from 'vue'
import {useAccountStore} from '@/stores/account'
import type Account from '@/types/api/account.type'

export default function useAllAccounts() {
  const store = useAccountStore()

  store.loadAccounts({page: 1})
  // TODO fix
  // store.dispatch(ActionTypes.GET_ALL_ACCOUNTS, { page:1 })

  const accounts = computed(() => {
    return store.accounts.data as Account[]
  })
  return accounts
}
