import type {OrderEntry} from '@singularit/kurz-components'
import {OrderDirection} from '@singularit/kurz-components'

const isoDateFormat
  = /(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))/
const simpleDateFormat = /(\d{4}-[01]\d-[0-3]\d)/

function isIsoDateString(value: any): boolean {
  return value && typeof value === 'string' && isoDateFormat.test(value)
}

function isSimpleDate(value: any): boolean {
  return value && typeof value === 'string' && simpleDateFormat.test(value)
}

export function handleDates(body: any) {
  if (body === null || body === undefined || !(body instanceof Object)) return body

  for (const key of Object.keys(body)) {
    const value = body[key]
    if (isIsoDateString(value) || isSimpleDate(value))
      body[key] = new Date(value)
    else if (value instanceof Object)
      handleDates(value)
  }
}

export function parseSorting(entry: OrderEntry) {
  return entry.direction === OrderDirection.ASCENDING ? String(entry.value) : `-${String(entry.value)}`
}
