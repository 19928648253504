import type {Ref} from 'vue'
import {computed, ref, watchEffect} from 'vue'
import {addWeeks, format, isSameISOWeek, isSameISOWeekYear} from 'date-fns'
import {useRouter} from 'vue-router'
import type Offer from '@/types/api/offer.type'
import useTableQuery from '@/utils/use-table-query'
import {useOfferStore} from '@/stores/offer'
import type {DashboardParams} from '@/api/modules/dashboards'
import type {OffersAggregationParams} from '@/api/modules/offers'
import {AggregationFunction} from '@/api/params/aggregation.type'
import {parseSorting} from '@/utils/parser'

export default function useWeeklyOffers(watchedWeek: Ref<Date[]>, baseConfig: Ref<DashboardParams>) {
  const router = useRouter()

  const {query: offerQuery, reload: offerReload} = useTableQuery()

  const offerDisplay = ref('overview')

  function changeOfferDisplay(display: string) {
    offerDisplay.value = display
  }

  const store = useOfferStore()
  const offerActivePage = ref(1)

  const startOfWeek = computed(() => format(watchedWeek.value[0], 'yyyy-MM-dd'))

  const config = computed(() => {
    let orderString
    if (offerQuery.value && offerQuery.value.orderEntry) {
      const entry = offerQuery.value.orderEntry
      orderString = parseSorting(entry)
    }
    const config: OffersAggregationParams = {
      page: offerActivePage.value,
      end__gte: startOfWeek.value,
      query: offerQuery.value,
      aggregationField: 'revenue',
      aggregation: AggregationFunction.SUM,
      groupBy: 'customer,end,receipt',
      orderBy: orderString,
    }
    return {...config, ...baseConfig?.value}
  })

  watchEffect(() => {
    if (offerDisplay.value !== 'overview')
      store.loadOfferAggregate(config.value)
  })

  const loadingOffers = computed(() => store.aggregates.pending)
  const offers = computed(() => store.aggregates.data as Offer[])
  const totalOffers = computed(() => store.aggregates.total)

  const criticalOffers = computed(() => {
    const criticalOffers = []
    const semiCriticalOffers = []
    const criticalWeek = addWeeks(watchedWeek.value[0], 1)
    const semiCriticalWeek = addWeeks(watchedWeek.value[0], 2)
    for (const offer of offers.value) {
      if (offer.end && (isSameISOWeek(criticalWeek, offer.end) || isSameISOWeek(watchedWeek.value[0], offer.end))
                && (isSameISOWeekYear(criticalWeek, offer.end) || isSameISOWeekYear(watchedWeek.value[0], offer.end)))
        criticalOffers.push(offer)

      else if (offer.end && isSameISOWeek(offer.end, semiCriticalWeek) && isSameISOWeekYear(semiCriticalWeek, offer.end))
        semiCriticalOffers.push(offer)
    }
    return {
      criticalOffers,
      semiCriticalOffers,
    }
  })

  function isCritical(offer: Offer) {
    return criticalOffers.value.criticalOffers.includes(offer)
  }

  function isSemiCritical(offer: Offer) {
    return criticalOffers.value.semiCriticalOffers.includes(offer)
  }

  function openOfferOverview(offer: Offer) {
    router.push({
      name: 'offer-overview',
      params: {
        offerReceipt: offer?.receipt,
      },
    })
  }

  return {
    loadingOffers,
    offerDisplay,
    changeOfferDisplay,
    offers,
    totalOffers,
    offerActivePage,
    openOfferOverview,
    criticalOffers,
    isCritical,
    isSemiCritical,
    offerReload,
  }
}
