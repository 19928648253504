import {defineStore} from 'pinia'
import api from '@/api'
import {PaginatedData} from '@/stores/index'
import type Branch from '@/types/api/branch.type'
import type {BranchListParams} from '@/api/modules/branches'

interface State {
  branches: PaginatedData<Branch>
}

export const useBranchStore = defineStore('branch', {
  // see https://pinia.vuejs.org/core-concepts/state.html
  state: (): State => ({
    // define initial values
    branches: new PaginatedData<Branch>(),
  }),
  // see https://pinia.vuejs.org/core-concepts/getters.html
  getters: {
    // define getters to access compound or calculated data
  },
  // see https://pinia.vuejs.org/core-concepts/actions.html
  actions: {

    /**
         * load branches
         * @cancel
         * @requestID
         */
    async loadBranches(config: BranchListParams, cancel = true, requestID?: string) {
      const id = requestID || api.branches.getBranches.name
      if (cancel)
        api.customer.cancel(id)
      this.branches.pending = true
      return api.customer.cancelableCall(api.branches.getBranches, config, id)
        .then((data) => {
          const page = data.data
          this.branches.data = page.results
          this.branches.total = page.count
          if (!api.customer.hasPending(id))
            this.branches.pending = false
        }).catch((error) => {
          if (!api.customer.hasPending(id))
            this.branches.pending = false
          return error
        })
    },

    /**
         * resets the store to the initial state.
         */
    clearBranches() {
      this.$reset()
    },
  },
})
