import {add, addDays, getMonth, startOfISOWeek, startOfYear} from 'date-fns'
import tinygradient from 'tinygradient'
import tinycolor from 'tinycolor2'
import GraphCoordinate from '@/types/api/chart/graph-coordinate.type'
import type GraphData from '@/types/api/chart/graph.type'

export function fillYear(data: GraphData) {
  const graphData = data
  let date = startOfYear(new Date())
  const fill: GraphCoordinate[] = []
  for (let step = 0; step < 12; step++) {
    const coord = new GraphCoordinate()
    coord.x = date
    coord.y = 0
    fill.push(coord)
    date = add(date, {months: 1})
  }
  graphData.values.forEach((data) => {
    if (data.x instanceof Date) {
      const index = fill.findIndex((item) => {
        if (!(item.x instanceof String)) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          return getMonth(item.x) === getMonth(data.x as Date)
        }
        else {
          return false
        }
      })
      if (index >= 0)
        fill[index] = data
    }
  })
  graphData.values = fill
  return graphData
}

export function generateColorGradients(steps: number, start = '#F8BE93', end = '#96ff00') {
  if (steps > 2) {
    const gradient = tinygradient(start, end)
    return gradient.hsv(steps, true)
  }
  else {
    return [tinycolor(start), tinycolor(end)]
  }
}

export function monthOfYearLabels(): string[] {
  const list = []
  let date = startOfYear(new Date())
  for (let step = 0; step < 12; step++) {
    list.push(date.toLocaleDateString('DE-de', {month: 'long'}))
    date = add(date, {months: 1})
  }
  return list
}

export function daysOfWeekLabels(dateOfWeek: Date) {
  const list = []
  let date = startOfISOWeek(dateOfWeek)
  for (let i = 1; i <= 7; i++) {
    list.push(date.toLocaleDateString('DE-de', {day: '2-digit', month: '2-digit', year: 'numeric'}))
    date = addDays(date, 1)
  }
  return list
}
