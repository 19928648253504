import type {ComputedRef, Ref} from 'vue'
import {computed, ref, watchEffect} from 'vue'
import {format} from 'date-fns'
import {SlotTableHead} from '@singularit/kurz-components'
import {monthlyTrendsTableHeads} from '@/views/trend/monthly-trends-table-heads'
import type {Month} from '@/types/api/trend.type'
import {useTrendStore} from '@/stores/trend'
import type {MonthlyTrendListParams} from '@/api/modules/trends'
import useTableQuery from '@/utils/use-table-query'
import {useAuthStore} from '@/stores/auth'
import useAllEmployees from '@/utils/behaviours/use-all-employees'
import useUrlParam from '@/utils/behaviours/use-url-param'
import useIncludeExcludeFilters from '@/utils/use-include-exclude-filters'
// import {useEmployeeStore} from '@/stores/employee'

export default function useMonthlyTrends(watchedMonth: Ref<Month>) {
  const store = useTrendStore()
  const authStore = useAuthStore()
  // const employeeStore = useEmployeeStore()

  const activePage = ref(1)

  const {query: trendQuery, reload: trendReload} = useTableQuery()

  const {employees} = useAllEmployees()

  const {
    filteredIds, includeIds, filteredBranches, includeBranches, filteredCountries, includeCountries,
    idOptions, branchOptions, countryOptions, includeToggleOptions, includeExcludeConfig, filtersLoading,
  } = useIncludeExcludeFilters()

  const myCustomersFilterActive = useUrlParam(
    'mycu',
    (value: boolean) => value.toString(),
    (string: string | string[]) => {
      return string.includes('true')
    },
    false,
  )

  const currentUserEmployeeId = computed(() => {
    const accountId = authStore.getAccountId
    const currentUserEmployee = employees.value.filter((employee) => {
      return employee.account === accountId
    })
    return currentUserEmployee.length === 1 ? currentUserEmployee[0].id : undefined
  })

  const watchedMonthDate = computed(() => {
    return format(new Date(watchedMonth.value.year, watchedMonth.value.month), 'yyyy-MM-dd')
  })

  const monthlyTrendConfig: ComputedRef<MonthlyTrendListParams> = computed(() => {
    const config = {
      date: watchedMonthDate.value,
      page: activePage.value,
      query: trendQuery.value,
      partners: myCustomersFilterActive.value ? currentUserEmployeeId.value : undefined,
    }
    return {...config, ...includeExcludeConfig.value}
  })

  watchEffect(() => {
    store.loadMonthlyTrends(monthlyTrendConfig.value)
  })

  const dataLoading = computed(() => {
    return store.monthlyTrends.pending
  })

  const trendData = computed(() => {
    return store.monthlyTrends.data
  })

  const totalTrends = computed(() => {
    return store.monthlyTrends.total
  })

  const trendsTableHeads = computed(() => {
    const tableHeads = [
      new SlotTableHead({
        id: 6,
        title: `Umsatz ${watchedMonth.value.year}`,
        sortKey: 'current_year_revenue',
        sortable: true,
        slotName: 'current-year-revenue',
        alignment: 'right',
      }),
      new SlotTableHead({
        id: 7,
        title: `Umsatz ${watchedMonth.value.year - 1}`,
        sortKey: 'previous_year_revenue',
        sortable: true,
        alignment: 'right',
        slotName: 'previous-year-revenue',
      }),
    ]
    return Array.prototype.concat(monthlyTrendsTableHeads, tableHeads)
  })

  return {
    filteredIds,
    includeIds,
    filteredBranches,
    includeBranches,
    filteredCountries,
    includeCountries,
    idOptions,
    branchOptions,
    countryOptions,
    includeToggleOptions,
    dataLoading,
    trendData,
    totalTrends,
    activePage,
    trendsTableHeads,
    trendReload,
    myCustomersFilterActive,
    filtersLoading,
  }
}
