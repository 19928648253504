import {defineStore} from 'pinia'
import hash from 'object-hash'
import type {CustomerTrend, MonthlyTrend} from '@/types/api/trend.type'
import {PaginatedData, RequestData} from '@/stores/index'
import api from '@/api'
import type {
  CustomerPurchaseTrendInfoParams,
  CustomerTrendInfoParams,
  CustomerTrendListParams,
  GeneralTrendListParams, MonthlyTrendListParams,
} from '@/api/modules/trends'
import type CustomerPurchaseTrendInfo from '@/types/api/customer-purchase-trend-info.type'
import type CustomerTrendInfo from '@/types/api/customer-trend-info.type'
import type Tuple from '@/utils/tuple.type'

interface State {
  purchaseTrendInfos: RequestData<Record<number, CustomerPurchaseTrendInfo>>
  trendInfos: RequestData<Record<string, CustomerTrendInfo>>
  trends: PaginatedData<CustomerTrend>
  generalTrends: PaginatedData<CustomerTrend>
  monthlyTrends: PaginatedData<MonthlyTrend>
}

export const useTrendStore = defineStore('trend', {
  // see https://pinia.vuejs.org/core-concepts/state.html
  state: (): State => ({
    // define initial values
    purchaseTrendInfos: new RequestData<Record<number, CustomerPurchaseTrendInfo>>({}),
    trendInfos: new RequestData<Record<string, CustomerTrendInfo>>({}),
    trends: new PaginatedData<CustomerTrend>(),
    generalTrends: new PaginatedData<CustomerTrend>(),
    monthlyTrends: new PaginatedData<MonthlyTrend>(),
  }),
  // see https://pinia.vuejs.org/core-concepts/getters.html
  getters: {
    // define getters to access compound or calculated data
    getTrendInfo(key) {
      const hashKey = hash(key)
      if (this.trendInfos.data)
        return this.trendInfos.data[hashKey]
      return undefined
    },
  },
  // see https://pinia.vuejs.org/core-concepts/actions.html
  actions: {

    /**
         * load trends
         * @param {CustomerTrendListParams} config - the config for the call
         * @param cancel
         * @param requestID
         */
    async loadTrends(config: CustomerTrendListParams, cancel = true, requestID?: string) {
      const id = requestID || api.trends.getCustomerTrends.name
      if (cancel)
        api.trends.cancel(id)
      this.trends.pending = true
      return api.trends.cancelableCall(api.trends.getCustomerTrends, config, id)
        .then((data) => {
          const page = data.data
          this.trends.total = page.count
          this.trends.data = page.results
          if (!api.trends.hasPending(id))
            this.trends.pending = false
        }).catch((error) => {
          if (!api.trends.hasPending(id))
            this.trends.pending = false
          throw error
        })
    },

    /**
         * load trends
         * @param {CustomerPurchaseTrendInfoParams} config - the config for the call
         * @param cancel
         * @param requestID
         */
    async loadCustomerPurchaseTrend(config: CustomerPurchaseTrendInfoParams, cancel = true, requestID?: string) {
      const id = requestID || api.trends.getCustomerPurchaseTrendInfo.name
      if (cancel)
        api.trends.cancel(id)
      this.purchaseTrendInfos.pending = true
      return api.trends.cancelableCall(api.trends.getCustomerPurchaseTrendInfo, config, id)
        .then((data) => {
          const key = config.customer.id
          if (!api.trends.hasPending(id))
            this.purchaseTrendInfos.pending = false
          if (key !== undefined && this.purchaseTrendInfos.data)
            this.purchaseTrendInfos.data[key] = data.data
          else
            throw new Error('invalid customer id')
        }).catch((error) => {
          if (!api.trends.hasPending(id))
            this.purchaseTrendInfos.pending = false
          return error
        })
    },

    /**
         * load trends
         * @param {CustomerTrendInfoParams} config - the config for the call
         * @param cancel
         * @param requestID
         */
    async loadTrendInfo(config: CustomerTrendInfoParams, cancel = true, requestID?: string) {
      const id = requestID || api.trends.getCustomerTrendInfo.name
      if (cancel)
        api.trends.cancel(id)
      this.trendInfos.pending = true
      return api.trends.cancelableCall(api.trends.getCustomerTrendInfo, config, id)
        .then((data) => {
          const key: Tuple<number, number> = {first: config.customer?.id, second: config.months}
          const hashKey = hash(key)
          if (this.trendInfos.data)
            this.trendInfos.data[hashKey] = data.data
          if (!api.trends.hasPending(id))
            this.trendInfos.pending = false
        }).catch((error) => {
          if (!api.trends.hasPending(id))
            this.trendInfos.pending = false
          return error
        })
    },

    /**
         * load general trends
         * @param {GeneralTrendListParams} config - the config for the call
         * @param cancel
         * @param requestID
         */
    async loadGeneralTrends(config: GeneralTrendListParams, cancel = true, requestID?: string) {
      const id = requestID || api.trends.getGeneralTrends.name
      if (cancel)
        api.trends.cancel(id)
      this.generalTrends.pending = true
      return api.trends.cancelableCall(api.trends.getGeneralTrends, config, id)
        .then((data) => {
          const page = data.data
          this.generalTrends.total = page.count
          this.generalTrends.data = page.results
          if (!api.trends.hasPending(id))
            this.generalTrends.pending = false
        }).catch((error) => {
          if (!api.trends.hasPending(id))
            this.generalTrends.pending = false
          return error
        })
    },

    async loadMonthlyTrends(config: MonthlyTrendListParams, cancel = true, requestID?: string) {
      const id = requestID || api.trends.getMonthlyTrends.name
      if (cancel)
        api.trends.cancel(id)
      this.monthlyTrends.pending = true
      return api.trends.cancelableCall(api.trends.getMonthlyTrends, config, id)
        .then((data) => {
          const page = data.data
          this.monthlyTrends.total = page.count
          this.monthlyTrends.data = page.results
          if (!api.trends.hasPending(id))
            this.monthlyTrends.pending = false
        }).catch((error) => {
          if (!api.trends.hasPending(id))
            this.monthlyTrends.pending = false
          return error
        })
    },

    /**
         * resets the store to the initial state.
         */
    clearTrends() {
      this.$reset()
    },
  },
})
