import {computed, ref, unref, watchEffect} from 'vue'
import type {ChurnListParams} from '@/api/modules/churns'
import {useChurnStore} from '@/stores/churn'
import useTableQuery from '@/utils/use-table-query'

export default function useMonthlyChurnList(
  month?: string,
) {
  const store = useChurnStore()
  const {query, reload} = useTableQuery()

  const activePage = ref(1)
  const config = computed(() => {
    const config: ChurnListParams = {
      date: month ? `${month}-01` : '',
      page: unref(activePage),
      query: unref(query),
    }
    return config
  })

  watchEffect(() => {
    store.loadMonthlyChurns(config.value)
    // store.dispatch(ActionTypes.GET_MONTHLY_CHURNS, config.value)
  })

  const loadingMonthlyChurns = computed(() => store.monthlyChurns.pending)

  const churns = computed(() => {
    return store.monthlyChurns.data
  })
  const totalChurns = computed(() => {
    return store.monthlyChurns.total
  })

  return {
    totalChurns,
    activePage,
    churns,
    loadingMonthlyChurns,
    reload,
  }
}
