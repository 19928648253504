import type {AxiosResponse} from 'axios'
import Qs from 'qs'
import type PaginationListParameters from '@/api/modules/pagination-list-parameters'
import type Page from '@/types/api/page.type'
import axiosInstance from '@/api/axios'
import BaseModule from '@/api/modules/base-module'
import type {CustomerTrend, HierarchyTrend, MonthlyTrend} from '@/types/api/trend.type'
import type Customer from '@/types/api/customer.type'
import type Hierarchy from '@/types/api/hierarchy.type'
import type QueryParameters from '@/api/modules/query-parameters'
import type CustomerTrendInfo from '@/types/api/customer-trend-info.type'
import type CustomerPurchaseTrendInfo from '@/types/api/customer-purchase-trend-info.type'
import type IncludeExcludeParameters from '@/api/modules/include-exclude-parameters'

export interface MonthlyTrendListParams extends PaginationListParameters, QueryParameters, IncludeExcludeParameters {
  date: string | Date
  partners?: number
}

export interface GeneralTrendListParams extends PaginationListParameters, QueryParameters {}

export interface CustomerTrendListParams extends PaginationListParameters, QueryParameters {
  customer?: Customer
}

export interface HierarchyTrendListParams extends PaginationListParameters, QueryParameters {
  customer?: Customer
  hierarchy?: Hierarchy
}

export interface CustomerTrendInfoParams {
  customer?: Customer
  months?: number
}

export interface CustomerPurchaseTrendInfoParams {
  customer: Customer
  timespan?: string
}

class TrendModule extends BaseModule {
  getGeneralTrends(params: GeneralTrendListParams, controller?: AbortController): Promise<AxiosResponse<Page<CustomerTrend>>> {
    const signal = controller ? controller.signal : undefined
    const data = {
      params,
      signal,
    }
    return axiosInstance.get('/trends/', data).then((response: AxiosResponse) => {
      return response
    })
  }

  getCustomerTrends(params: CustomerTrendListParams, controller?: AbortController): Promise<AxiosResponse<Page<HierarchyTrend>>> {
    const signal = controller ? controller.signal : undefined
    const data = {
      params,
      signal,
    }
    return axiosInstance.get('/trends/hierarchies/', data)
  }

  getHierarchyTrends(params: HierarchyTrendListParams, controller?: AbortController): Promise<AxiosResponse<Page<HierarchyTrend>>> {
    const signal = controller ? controller.signal : undefined
    const data = {
      params,
      signal,
    }
    return axiosInstance.get('/trends/hierarchies/', data)
  }

  getCustomerTrendInfo(params: CustomerTrendInfoParams, controller?: AbortController): Promise<AxiosResponse<CustomerTrendInfo>> {
    const signal = controller ? controller.signal : undefined
    const data = {
      params,
      signal,
    }
    return axiosInstance.get('/trends/chart', data)
  }

  getCustomerPurchaseTrendInfo(params: CustomerPurchaseTrendInfoParams, controller?: AbortController): Promise<AxiosResponse<CustomerPurchaseTrendInfo>> {
    const signal = controller ? controller.signal : undefined
    const data = {
      params,
      signal,
    }
    return axiosInstance.get('/trends/purchase-chart', data)
  }

  getMonthlyTrends(params: MonthlyTrendListParams, controller?: AbortController): Promise<AxiosResponse<MonthlyTrend>> {
    const signal = controller ? controller.signal : undefined
    const data = {
      params,
      signal,
      paramsSerializer: (params: MonthlyTrendListParams) => Qs.stringify(params, {arrayFormat: 'comma'}),
    }
    return axiosInstance.get('/trends/', data)
  }
}

const module = new TrendModule()
export default module
