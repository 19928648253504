import {computed, ref} from 'vue'

export default function useScreenSize() {
  const windowWidth = ref()
  window.addEventListener('resize', () => {
    windowWidth.value = window.innerWidth
  })
  const isMediumSize = computed(() => {
    return windowWidth.value <= 1000
  })
  return {isMediumSize}
}
