import type {AxiosResponse} from 'axios'
import BaseModule from '@/api/modules/base-module'
import type Status from '@/types/api/status.type'
import axiosInstance from '@/api/axios'

class StatusModule extends BaseModule {
  saveState(state: Status): Promise<AxiosResponse> {
    let url = ''
    if (state.contract || state.offer) {
      if (state.offer)
        url = `/offers/${state.offer.id}`

      else if (state.contract)
        url = `/contracts/${state.contract.id}`

      url += '/state/'
    }
    return axiosInstance.post(url, state)
  }
}

const module = new StatusModule()
export default module
