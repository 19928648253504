<template>
  <div class="xl:h-full flex flex-col xl:overflow-hidden">
    <div class="flex">
      <h1
        v-if="contract"
        class="page-head"
      >
        Kontrakt {{ contract.receipt }}
      </h1>
      <ImportTimestamp
        class="items-center mr-4 ml-auto"
        dependant-on="contracts"
      />
    </div>
    <div class="grid grid-cols-1 xl:grid-cols-2">
      <k-card>
        <template #title>
          <span class="card-head">
            <h2>Allgemeine Informationen</h2>
          </span>
        </template>
        <div
          v-if="loadingData"
          class="h-32 w-full flex justify-center"
        >
          <k-kurz-dot-loader class="self-center" />
        </div>
        <k-list-layout
          v-else
          class="w-full"
        >
          <k-list-entry
            label="Warenempfänger"
            :value="contract?.customer"
          >
            <div class="text-left w-1/2">
              <CustomerLink :customer="contract.customer" />
            </div>
          </k-list-entry>
          <k-list-entry
            label="Material"
            :value="contract?.material"
          >
            <span class="text-left w-1/2">
              {{ contract.material.materialDescription }} ({{ contract.material.materialNumber }})
            </span>
          </k-list-entry>
          <k-list-entry
            label="Belegposition"
            :value="contract?.position"
          />
          <k-list-entry
            label="Meilenstein"
            :value="contract?.milestone"
          />
          <k-list-entry
            label="Laufzeit Beginn"
            :value="durationStart"
          />
          <k-list-entry
            label="Laufzeit Ende"
            :value="durationEnd"
          />
          <k-list-entry
            v-if="contract"
            label="Abweichung"
            :value="contract.ratio"
          />
          <k-list-entry
            label="Status"
            :value="status"
          >
            <template #icon>
              <font-awesome-icon
                class="icon hover:cursor-pointer"
                icon="edit"
                @click="openChangeState"
              />
            </template>
            <div class="text-left w-1/2">
              {{ statusDescription }}
            </div>
          </k-list-entry>
          <k-modal
            v-if="contract"
            :modal-active="showStateModal"
            @close="showStateModal=false"
          >
            <StateChanger
              :contract="contract"
              @save="showStateModal=false; applyNewState($event)"
            />
          </k-modal>
        </k-list-layout>
      </k-card>
      <k-card>
        <template #title>
          <span class="card-head">
            <h2>Allgemeine Informationen</h2>
          </span>
        </template>
        <div
          v-if="loadingData"
          class="h-32 w-full flex justify-center"
        >
          <k-kurz-dot-loader class="self-center" />
        </div>
        <k-list-layout
          v-else
          class="w-full"
        >
          <k-list-entry
            label="Realisierter Umsatz"
            :value="revenue"
          />
          <k-list-entry
            label="Geplanter Umsatz"
            :value="openRevenue"
          />
          <k-list-entry
            label="Abgerufene Menge"
            :value="quantity"
          >
            <div class="text-left w-1/2">
              <span>{{ quantity }} {{ quantityUnit }}
              </span>
            </div>
          </k-list-entry>
          <k-list-entry
            label="Geplante Menge"
            :value="openQuantity"
          >
            <div class="text-left w-1/2">
              <span>{{ openQuantity }} {{ quantityUnit }}
              </span>
            </div>
          </k-list-entry>
          <k-list-entry
            v-if="contract"
            label="Verkaufsorganisation"
            :value="contract.salesOrganization"
          />
          <k-list-entry
            v-if="contract"
            label="Verkaufsgruppe"
            :value="contract.salesGroup"
          />
          <k-list-entry
            label="Erstellungsdatum"
            :value="creationDate"
          />
          <k-list-entry
            label="letzte Datenaktualisierung"
            :value="updatedDate"
          >
            <div class="text-left w-1/2">
              <span>{{ updatedDate }}</span>
            </div>
          </k-list-entry>
          <k-list-entry
            v-if="contract"
            label="Vertriebsbeauftragter"
            :value="contract.salesManager"
          >
            <div class="text-left w-1/2">
              <span>{{ contract.salesManager.firstName }} {{ contract.salesManager.lastName }}</span>
            </div>
          </k-list-entry>
          <k-list-entry
            v-if="contract"
            label="Zuständiger Mitarbeiter"
            :value="contract.responsibleEmployee"
          >
            <div class="text-left w-1/2">
              <span>{{ contract.responsibleEmployee.firstName }} {{ contract.responsibleEmployee.lastName }}</span>
            </div>
          </k-list-entry>
        </k-list-layout>
      </k-card>
    </div>
    <k-card class="col-span-2">
      <template #title>
        <h2>Zugeordnete Aufträge</h2>
      </template>
      <k-table
        v-model:page="activePageOrders"
        class="flex-1"
        :head="contractOrderTableHeads"
        :data="orders"
        :total-rows="totalOrders"
        :data-loading="loadingOrders"
        :selectable="false"
        async
        @reload="reload"
        @change-page="activePageOrders = $event"
      >
        <template #quantity-slot="{ content }">
          <k-attribute-display :value="getBaseQuantity(content)">
            <span class="whitespace-nowrap">
              {{ quantityFormatter.format(getBaseQuantity(content).quantity) }}
              <span>{{ unitFormatter(getBaseQuantity(content).unit) }}</span>
            </span>
          </k-attribute-display>
        </template>
      </k-table>
    </k-card>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, ref, watchEffect} from 'vue'
import {useRouter} from 'vue-router'
import CustomerLink from '@/components/links/CustomerLink.vue'
import {contractOrderTableHeads} from '@/views/contract/contract-table-heads'
import useOrderList from '@/views/order/use-order-list'
import {useComputedCurrency, useComputedDate, useComputedNumber} from '@/utils/behaviours/use-formatter'
import ImportTimestamp from '@/components/timestamp/ImportTimestamp.vue'
import StateChanger from '@/components/state-changer/StateChanger.vue'
import {getBaseQuantity} from '@/utils/use-quantities'
import type Status from '@/types/api/status.type'
import {useContractStore} from '@/stores/contract'
import type Contract from '@/types/api/contract.type'
import {quantityFormatter, unitFormatter} from '@/utils/formatter'

export default defineComponent({
  name: 'ContractDetailView',
  components: {ImportTimestamp, CustomerLink, StateChanger},
  props: {
    contractId: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const store = useContractStore()
    const router = useRouter()

    const contract = computed(() => {
      return store.contract.data as Contract
    })

    const loadingData = computed(() => store.contract.pending)

    // initial loading
    watchEffect(() => {
      store.loadContract({id: props.contractId}).catch(() => {
        router.push({name: 'contract-list'})
      })
    })

    const {orders, totalOrders, activePage: activePageOrders, loadingOrders, reload} = useOrderList(contract)

    const revenue = useComputedCurrency(contract, 'revenue')
    const openRevenue = useComputedCurrency(contract, 'openRevenue')

    const contractQuantity = computed(() => {
      if (contract.value) return contract.value.quantities.find(item => contract.value.baseQuantity === item.id)
      else return undefined
    })

    const quantityUnit = computed(() => {
      return unitFormatter(contractQuantity.value?.unit)
    })

    const quantity = useComputedNumber(contractQuantity, 'quantity')

    const openQuantity = useComputedNumber(contractQuantity, 'openQuantity')

    const durationStart = useComputedDate(contract, 'durationStart')

    const durationEnd = useComputedDate(contract, 'durationEnd')

    const creationDate = useComputedDate(contract, 'creationDate')

    const updatedDate = useComputedDate(contract, 'updated')

    const status = computed(() => {
      if (contract.value && contract.value.state) {
        switch (contract.value.state) {
          case 1:
            return 'Offen'
          case 2:
            return 'In Absprache'
          case 3:
            return 'Beendet'
          default:
            return null
        }
      }
      else {
        return null
      }
    })

    const statusDescription = computed(() => {
      if (status.value && contract.value.stateAppointee) {
        const appointee = contract.value.stateAppointee
        return `${status.value}, Beauftrager: ${appointee.firstName} ${appointee.lastName}`
      }
      else {
        return status.value
      }
    })

    const showStateModal = ref(false)

    function openChangeState() {
      showStateModal.value = true
    }

    function applyNewState(newState: Status) {
      store.changeState(newState)
    }

    return {
      contract,
      revenue,
      openRevenue,
      loadingData,

      quantity,
      openQuantity,
      quantityUnit,

      durationStart,
      durationEnd,
      creationDate,
      updatedDate,
      status,
      statusDescription,

      contractOrderTableHeads,
      orders,
      totalOrders,
      activePageOrders,
      loadingOrders,

      showStateModal,
      openChangeState,
      applyNewState,

      reload,
      getBaseQuantity,
      quantityFormatter,
      unitFormatter,
    }
  },
})
</script>
