import type {Ref} from 'vue'
import {computed, ref, unref, watchEffect} from 'vue'
import type Customer from '@/types/api/customer.type'
import type {CustomerHierarchyOverviewParams} from '@/api/modules/customers'
import type Hierarchy from '@/types/api/hierarchy.type'
import {useCustomerStore} from '@/stores/customer'

export default function useHierarchyOverviewList(
  customerFilter: Ref<Customer>,
  hierarchyFilter1?: Ref<Hierarchy | undefined>,
  hierarchyFilter2?: Ref<Hierarchy | undefined>) {
  const store = useCustomerStore()

  const activePage = ref(1)

  const config = computed(() => {
    const customer = unref(customerFilter)
    const config: CustomerHierarchyOverviewParams = {
      page: unref(activePage),
      id: customer ? customer.id : undefined,
      hierarchy1: hierarchyFilter1?.value,
      hierarchy2: hierarchyFilter2?.value,
    }
    return config
  })

  watchEffect(() => {
    store.loadHierarchyOverview(config.value)
  })

  const loadingHierarchyOverviews = computed(() => store.hierarchyOverviews.pending)

  const hierarchyOverviews = computed(() => store.hierarchyOverviews.data)

  const totalHierarchyOverviews = computed(() => {
    return store.hierarchyOverviews.total
  })

  return {hierarchyOverviews, totalHierarchyOverviews, activePage, loadingHierarchyOverviews}
}
