import type {AxiosResponse} from 'axios'
import axiosInstance from '@/api/axios'
import type Page from '@/types/api/page.type'
import type PaginationListParameters from '@/api/modules/pagination-list-parameters'
import type Order from '@/types/api/order.type'
import BaseModule from '@/api/modules/base-module'
import type Customer from '@/types/api/customer.type'
import type Contract from '@/types/api/contract.type'
import type QueryParameters from '@/api/modules/query-parameters'

export interface OrdersListParams extends PaginationListParameters, QueryParameters {
  customer?: Customer
  contract?: Contract
}

class OrderModule extends BaseModule {
  getOrders(params: OrdersListParams, controller?: AbortController): Promise<AxiosResponse<Page<Order>>> {
    const signal = controller ? controller.signal : undefined

    const data = {
      params,
      signal,
    }
    return axiosInstance.get('/orders/', data).then((response) => {
      return response
    })
  }
}

const module = new OrderModule()
export default module
