import type {Ref} from 'vue'
import {computed, watchEffect} from 'vue'
import type Customer from '@/types/api/customer.type'
import type {InvoiceGraphParams} from '@/api/modules/invoices'
import {graphCoordinateComparator} from '@/types/api/chart/graph-coordinate.type'
import {useInvoiceStore} from '@/stores/invoice'
import type GraphData from '@/types/api/chart/graph.type'

export default function useInvoiceTrendGraph(customer: Ref<Customer>) {
  const store = useInvoiceStore()

  const config = computed(() => {
    let config: InvoiceGraphParams | undefined
    if (customer.value) {
      config = {
        customer: customer.value,
        acuteness: 'month',
      }
    }
    return config
  })

  watchEffect(() => {
    if (config.value !== undefined)
      store.loadInvoiceGraph(config.value)
      // store.dispatch(ActionTypes.GET_INVOICES_GRAPH_DATA, config.value)
  })

  const graphData = computed(() => {
    const data: GraphData = store.invoiceGraph.data as GraphData
    if (data && data.values)
      data.values = data.values.sort(graphCoordinateComparator)

    return data
  })

  return {graphData}
}
