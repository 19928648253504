import {defineStore} from 'pinia'
import type Contract from '@/types/api/contract.type'
import api from '@/api'
import {PaginatedData, RequestData} from '@/stores/index'
import type {ContractDetailParams, ContractListParams} from '@/api/modules/contracts'
import type Status from '@/types/api/status.type'

interface State {
  contract: RequestData<Contract>
  contracts: PaginatedData<Contract>
  inactiveContracts: PaginatedData<Contract>
}

export const useContractStore = defineStore('contract', {
  // see https://pinia.vuejs.org/core-concepts/state.html
  state: (): State => ({
    // define initial values
    contract: new RequestData(),
    contracts: new PaginatedData<Contract>(),
    inactiveContracts: new PaginatedData<Contract>(),
  }),
  // see https://pinia.vuejs.org/core-concepts/getters.html
  getters: {},
  // see https://pinia.vuejs.org/core-concepts/actions.html
  actions: {

    /**
         * load contracts
         * @param {ContractListParams} config - the config for the call
         * @param [cancel=true] - whether to cancel existing requests. If a requestID is given only request with the ID will be canceled
         * @param [requestID] - id to specify the request. if not given defaults to `api.contract.getContracts.name`
         */
    async loadContracts(config: ContractListParams, cancel = true, requestID?: string) {
      const id = requestID || api.contract.getContracts.name
      if (cancel)
        api.contract.cancel(id)
      this.contracts.pending = true
      return api.contract.cancelableCall(api.contract.getContracts, config, id)
        .then((data) => {
          const page = data.data
          this.contracts.total = page.count
          this.contracts.data = page.results
          if (!api.contract.hasPending(id))
            this.contracts.pending = false
        }).catch((error) => {
          if (!api.contract.hasPending(id))
            this.contracts.pending = false
          return error
        })
    },

    /**
         * load inactive contracts
         * @param {ContractListParams} config - the config for the call
         * @param [cancel=true] - whether to cancel existing requests. If a requestID is given only request with the ID will be canceled
         * @param [requestID=getInactiveContracts] - id to specify the request
         */
    async loadInactiveContracts(config: ContractListParams, cancel = true, requestID = 'getInactiveContracts') {
      if (cancel)
        api.contract.cancel(requestID)
      this.inactiveContracts.pending = true
      return api.contract.cancelableCall(api.contract.getContracts, config, requestID)
        .then((data) => {
          const page = data.data
          this.inactiveContracts.total = page.count
          this.inactiveContracts.data = page.results
          if (!api.contract.hasPending(requestID))
            this.inactiveContracts.pending = false
        }).catch((error) => {
          if (!api.contract.hasPending(requestID))
            this.inactiveContracts.pending = false
          throw error
        })
    },

    /**
         * load details of a contract
         * @param {ContractDetailParams} config - the config for the call
         * @param [cancel=true] - whether to cancel existing requests. If a requestID is given only request with the ID will be canceled
         * @param [requestID] - id to specify the request
         */
    async loadContract(config: ContractDetailParams, cancel = true, requestID?: string) {
      const id = requestID || api.contract.getContractDetails.name
      if (cancel)
        api.contract.cancel(id)
      return api.contract.cancelableCall(api.contract.getContractDetails, config, id)
        .then((data) => {
          this.contract.data = data.data
          if (!api.contract.hasPending(id))
            this.contract.pending = false
        }).catch((error) => {
          if (!api.contract.hasPending(id))
            this.contract.pending = false
          throw error
        })
    },

    /**
         * changes the state of the current contract
         * @param newState - object containing the new state and state appointee
         */
    async changeState(newState: Status) {
      if (this.contract.data) {
        this.contract.data.state = newState.state
        this.contract.data.stateAppointee = newState.stateAppointee
      }
    },

    /**
         * resets the store to the initial state.
         */
    clearContracts() {
      this.$reset()
    },
  },
})
