import type {TableQuery} from '@singularit/kurz-components'
import {ref} from 'vue'

export default function useTableQuery() {
  const query = ref<TableQuery | undefined>(undefined)
  const reload = (tableConfiguration: TableQuery) => {
    query.value = tableConfiguration
  }

  return {query, reload}
}
