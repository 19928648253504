import useAllHierarchies from '@/utils/behaviours/use-all-hierarchies'
import {hierarchyMapping, hierarchyReverseMapping} from '@/utils/url/url-from-string'
import useUrlParam from '@/utils/behaviours/use-url-param'

export default function useHierarchyFilter() {
  const {hierarchies, hierarchiesLoading} = useAllHierarchies()
  const reverseMapping = hierarchyReverseMapping(hierarchies)

  const hierarchyFilter1 = useUrlParam('ph1', hierarchyMapping, reverseMapping)
  const hierarchyFilter2 = useUrlParam('ph2', hierarchyMapping, reverseMapping)
  const hierarchyFilter3 = useUrlParam('ph3', hierarchyMapping, reverseMapping)

  return {hierarchyFilter1, hierarchyFilter2, hierarchyFilter3, hierarchiesLoading}
}
