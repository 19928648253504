<template>
  <k-card>
    <template #title>
      <span class="card-head">
        <h2>
          Rechnungen
        </h2>
      </span>
    </template>
    <k-table
      v-model:page="activePage"
      class="flex-1"
      :head="customerInvoicesTableHeads"
      :data="invoices"
      :total-rows="totalInvoices"
      :data-loading="loadingInvoices"
      :selectable="false"
      async
      @reload="reload"
      @change-page="activePage = $event"
    >
      <template #material-slot="{ content }">
        <k-attribute-display
          class="whitespace-nowrap"
          :value="content?.material?.materialDescription"
        />
      </template>
      <template #quantity-slot="{ content }">
        <k-attribute-display :value="getBaseQuantity(content)">
          <span class="whitespace-nowrap">
            {{ quantityFormatter.format(getBaseQuantity(content).quantity) }}
            <span>{{ unitFormatter(getBaseQuantity(content).unit) }}</span>
          </span>
        </k-attribute-display>
      </template>
    </k-table>
  </k-card>
</template>

<script setup lang="ts">
import {toRef} from 'vue'
import type Customer from '@/types/api/customer.type'
import useInvoiceList from '@/views/invoice/use-invoice-list'
import {invoicesTableHeads} from '@/views/invoice/invoice-table-heads'
import {quantityFormatter, unitFormatter} from '@/utils/formatter'
import useHierarchyFilter from '@/views/customer/customer-detail/use-hierarchy-filter'
import {getBaseQuantity} from '@/utils/use-quantities'

interface Props {
  customer: Customer
}

const props = defineProps<Props>()
const customer = toRef(props, 'customer')

const {hierarchyFilter1, hierarchyFilter2, hierarchyFilter3} = useHierarchyFilter()
const customerInvoicesTableHeads = invoicesTableHeads.filter(a => (a.title !== 'Warenempfänger')) // TODO change

const {invoices, totalInvoices, activePage, loadingInvoices, reload} = useInvoiceList(customer)

</script>
