import {defineStore} from 'pinia'
import api from '@/api'
import {PaginatedData, RequestData} from '@/stores/index'
import type Offer from '@/types/api/offer.type'
import type {OfferDetailParams, OffersAggregationParams, OffersListParams} from '@/api/modules/offers'
import type Status from '@/types/api/status.type'
import type {OfferAggregation} from '@/types/api/aggregation.type'

interface State {
  offer: RequestData<Offer>
  offers: PaginatedData<Offer>
  aggregates: PaginatedData<OfferAggregation>
}

export const useOfferStore = defineStore('offer', {
  // see https://pinia.vuejs.org/core-concepts/state.html
  state: (): State => ({
    // define initial values
    offer: new RequestData<Offer>(),
    offers: new PaginatedData<Offer>(),
    aggregates: new PaginatedData<OfferAggregation>(),

  }),
  // see https://pinia.vuejs.org/core-concepts/getters.html
  getters: {
    // define getters to access compound or calculated data
  },
  // see https://pinia.vuejs.org/core-concepts/actions.html
  actions: {

    /**
         * load offers
         * @param {OffersListParams} config - the config for the call
         * @param cancel
         * @param requestID
         */
    async loadOffers(config: OffersListParams, cancel = true, requestID?: string) {
      const id = requestID || api.offers.getOffers.name
      if (cancel)
        api.offers.cancel(id)
      this.offers.pending = true
      return api.offers.cancelableCall(api.offers.getOffers, config, id)
        .then((data) => {
          const page = data.data
          this.offers.total = page.count
          this.offers.data = page.results
          if (!api.offers.hasPending(id))
            this.offers.pending = false
        }).catch((error) => {
          if (!api.offers.hasPending(id))
            this.offers.pending = false
          throw error
        })
    },

    /**
         * load offers
         * @param {OfferDetailParams} config - the config for the call
         * @param cancel
         * @param requestID
         */
    async loadOfferAggregate(config: OffersAggregationParams, cancel = true, requestID?: string) {
      const id = requestID || api.offers.getOfferAggregations.name
      if (cancel)
        api.offers.cancel(id)
      this.aggregates.pending = true
      return api.offers.cancelableCall(api.offers.getOfferAggregations, config, id)
        .then((data) => {
          const page = data.data
          this.aggregates.data = page.results
          this.aggregates.total = page.count
          if (!api.offers.hasPending(id))
            this.aggregates.pending = false
        }).catch((error) => {
          if (!api.offers.hasPending(id))
            this.aggregates.pending = false
          throw error
        })
    },

    /**
         * load offer
         * @param {OfferDetailParams} config - the config for the call
         * @param cancel
         * @param requestID
         */
    async loadOffer(config: OfferDetailParams, cancel = true, requestID?: string) {
      const id = requestID || api.offers.getOfferDetails.name
      if (cancel)
        api.offers.cancel(id)
      this.offer.pending = true
      return api.offers.cancelableCall(api.offers.getOfferDetails, config, id)
        .then((data) => {
          this.offer.data = data.data
          if (!api.offers.hasPending(id))
            this.offer.pending = false
        }).catch((error) => {
          if (!api.offers.hasPending(id))
            this.offer.pending = false
          throw error
        })
    },

    /**
         * changes the state of the current offer
         * @param newState - object containing the new state and state appointee
         */
    async changeState(newState: Status) {
      if (this.offer.data) {
        this.offer.data.state = newState.state
        this.offer.data.stateAppointee = newState.stateAppointee
      }
    },

    /**
         * resets the store to the initial state.
         */
    clearOffers() {
      this.$reset()
    },
  },
})
