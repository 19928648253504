import type {AxiosResponse} from 'axios'
import axios from 'axios'

export default class BaseModule {
  public requestMap: Map<string, AbortController> = new Map()
  public keyMap = new Map<string, string[]>()

  cancel(key: string): void {
    const uids = this.keyMap.get(key)
    if (uids) {
      for (const uid of uids) {
        const controller = this.requestMap.get(uid)
        if (controller)
          controller.abort()
      }
    }
  }

  private uid() {
    return (performance.now().toString(36) + Math.random().toString(36)).replace(/\./g, '')
  }

  private getUniqueKey(requestID: string) {
    let keys = this.keyMap.get(requestID)
    if (!keys)
      keys = []
    const uid = this.uid()
    keys.push(uid)
    this.keyMap.set(requestID, keys)
    return uid
  }

  private startRequest(key: string) {
    const controller = new AbortController()
    this.requestMap.set(key, controller)
    return controller
  }

  private finishRequest(key: string, uid: string) {
    // console.info(`${key}:${uid} - before finishRequest:
    //   ${JSON.stringify([...this.requestMap])}
    //   ${JSON.stringify([...this.keyMap])}`)
    const uids = this.keyMap.get(key)
    if (uids) {
      const index = uids.indexOf(uid)
      uids.splice(index, 1)
      this.keyMap.set(key, uids)
    }
    this.requestMap.delete(uid)

    // console.info(`${key}:${uid} - finishRequest:
    //   ${JSON.stringify([...this.requestMap])}
    //   ${JSON.stringify([...this.keyMap])}`)
  }

  /**
     *
     * @param call - the request to call
     * @param params - params of the request
     * @param [requestID] - an ID to identify the request. If undefined defaults to call.name
     */
  cancelableCall<T, S>(call: (params: T, controller?: AbortController) => Promise<AxiosResponse<S>>, params: T, requestID?: string) {
    let key = call.name
    if (requestID)
      key = requestID
    const uid = this.getUniqueKey(key)
    const controller = this.startRequest(uid)
    return call(params, controller).then((response: AxiosResponse) => {
      // console.info(`${key} - then`)
      this.finishRequest(key, uid)
      return response
    }).catch((error) => {
      // console.info(`${key} - catch`)
      this.finishRequest(key, uid)
      if (!axios.isCancel(error))
        console.error(error)

      throw error
    })
  }

  // counting the amount of pending requests to prevent falsely cancelling the loading animation after restarting a request
  hasPending(key?: string): boolean {
    // console.info(`${key} - hasPending:
    //   ${JSON.stringify([...this.requestMap])}
    //   ${JSON.stringify([...this.keyMap])}`)
    let result = false
    if (key) {
      const uids = this.keyMap.get(key)
      if (uids)
        result = uids.length > 0
    }
    else {
      for (const value of this.requestMap.values()) {
        if (value) {
          result = true
          break
        }
      }
    }
    return result
  }
}
