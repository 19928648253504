import {createApp} from 'vue'
import 'vue-toastification/dist/index.css'
import {createPinia} from 'pinia'
import {library} from '@fortawesome/fontawesome-svg-core'
import {fas} from '@fortawesome/free-solid-svg-icons'
import {far} from '@fortawesome/free-regular-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {Chart, registerables} from 'chart.js'
import './assets/styles/index.css'
import KurzComponents from '@singularit/kurz-components'
import '@singularit/kurz-components/dist/style.css'
import Toast from 'vue-toastification'
import router from './router'
import App from './App.vue'

import options from '@/assets/toastPluginOptions'
import {farFilter} from '@/assets/icons'

const pinia = createPinia()

Chart.register(...registerables)
library.add(farFilter)
library.add(far, fas)
const app = createApp(App)
app.use(pinia)
app.use(router)
app.use(Toast, options)
app.use(KurzComponents)
app.component('FontAwesomeIcon', FontAwesomeIcon)
app.mount('#app')
