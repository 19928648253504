import {defineStore} from 'pinia'
import type Hierarchy from '@/types/api/hierarchy.type'
import api from '@/api'
import type {HierarchyListParams} from '@/api/modules/hierarchies'
import {PaginatedData} from '@/stores/index'

interface State {
  hierarchies: PaginatedData<Hierarchy>
}

export const useHierarchyStore = defineStore('hierarchy', {
  // see https://pinia.vuejs.org/core-concepts/state.html
  state: (): State => ({
    // define initial values
    hierarchies: new PaginatedData<Hierarchy>(),
  }),
  // see https://pinia.vuejs.org/core-concepts/getters.html
  getters: {
    // define getters to access compound or calculated data
  },
  // see https://pinia.vuejs.org/core-concepts/actions.html
  actions: {

    /**
     * load hierarchies
     * @param {HierarchyListParams}config - the config for the call
     * @param cancel
     * @param requestID
     */
    async loadHierarchies(config: HierarchyListParams, cancel = true, requestID?: string) {
      const id = requestID || api.hierarchies.getHierarchies.name
      if (cancel)
        api.hierarchies.cancel(id)
      this.hierarchies.pending = true
      return api.hierarchies.cancelableCall(api.hierarchies.getHierarchies, config, id)
        .then((data) => {
          const page = data.data
          this.hierarchies.data = page.results
          this.hierarchies.total = page.count
          this.hierarchies.pending = false
          if (!api.hierarchies.hasPending(id))
            this.hierarchies.pending = false
        }).catch((error) => {
          if (!api.hierarchies.hasPending(id))
            this.hierarchies.pending = false
          throw error
        })
    },

    /**
         * resets the store to the initial state.
         */
    clearHierarchies() {
      this.$reset()
    },
  },
})
