import type {AxiosResponse} from 'axios'
import axiosInstance from '@/api/axios'
import type PaginationListParameters from '@/api/modules/pagination-list-parameters'
import type QueryParameters from '@/api/modules/query-parameters'
import BaseModule from '@/api/modules/base-module'
import type Page from '@/types/page.type'
import type Churn from '@/types/api/churn.type'

export interface ChurnListParams extends PaginationListParameters, QueryParameters {
  date?: string
  date__gte?: string
  date__lte?: string
  likelihood__gte?: number
}

class ChurnModule extends BaseModule {
  getMonthlyChurns(params: ChurnListParams, controller?: AbortController): Promise<AxiosResponse<Page<Churn>>> {
    const signal = controller ? controller.signal : undefined
    const data = {
      params,
      signal,
    }
    return axiosInstance.get('/churns/monthly/', data).then((response: AxiosResponse) => {
      return response
    })
  }

  getChurns(params: ChurnListParams, controller?: AbortController): Promise<AxiosResponse<Page<Churn>>> {
    const signal = controller ? controller.signal : undefined
    const data = {
      params,
      signal,
    }
    return axiosInstance.get('/churns/', data).then((response: AxiosResponse) => {
      return response
    })
  }
}

const module = new ChurnModule()
export default module
