import type {Ref} from 'vue'
import {computed, isRef, ref, unref, watchEffect} from 'vue'
import type {SlotTableHead} from '@singularit/kurz-components'
import {ObjectTableHead} from '@singularit/kurz-components'
import {useRouter} from 'vue-router'
import type {DashboardParams, LowPerformerParams} from '@/api/modules/dashboards'
import {lowPerformersTableHeads} from '@/views/dashboard/vkl-dashboard/dashboard-table-heads'
import type LowPerformer from '@/types/api/dashboard/low-performer.type'
import {tableCurrencyFormatter} from '@/utils/formatter'
import useUrlParam from '@/utils/behaviours/use-url-param'
import {toggleValuesReverseMapping} from '@/utils/url/url-from-string'
import {useLowPerformerStore} from '@/stores/low-performer'
import useTableQuery from '@/utils/use-table-query'
import hasBeenCalledWithConfig from '@/utils/check-request-config'

export default function useLowPerformer(
  threshold: Ref<number> | number,
  baseConfig: Ref<DashboardParams>,
  date?: Ref<Date>,
) {
  const store = useLowPerformerStore()
  const {query, reload} = useTableQuery()
  const now = new Date()
  const month = now.toLocaleString('de-DE', {month: 'long'})
  const year = now.toLocaleString('de-DE', {year: 'numeric'})

  const globalOptions = {trueLabel: 'Global', trueValue: 'global', falseValue: 'filtered', falseLabel: 'Gefiltert'}

  // const globalOptions: Array<RadioOption> = [
  //   {label: 'Global', value: 'global'},
  //   {label: 'Gefiltert', value: 'filtered'},
  // ]

  const globalDefault = globalOptions.falseValue as string
  const globalChoice = useUrlParam<string, string | undefined>(
    'lpg',
    (string: string) => string,
    toggleValuesReverseMapping(globalOptions) as (string: string | string[]) => string,
    globalDefault)

  const timespanOptions = {falseLabel: 'Jahr', falseValue: 'year', trueValue: 'month', trueLabel: 'Monat'}
  // const timespanOptions: Array<RadioOption> = [
  //   {label: 'Jahr', value: 'year'},
  //   {label: 'Monat', value: 'month'},
  // ]

  const timespanDefault = timespanOptions.falseValue as string
  const timespanChoice = useUrlParam<string, string | undefined>(
    'lpt',
    (string: string) => string,
    toggleValuesReverseMapping(timespanOptions) as (string: string | string[]) => string,
    timespanDefault)

  const activePageLP = ref(1)

  const selectedTimespan = computed({
    get() {
      return unref(timespanChoice)
    },
    set(value?: string) {
      if (isRef(timespanChoice))
        timespanChoice.value = value

      activePageLP.value = 1
    },
  })

  const lowPerformerDefinition = computed(() => {
    if (selectedTimespan.value === timespanOptions.trueValue)
      return `Low-Performer: Kunden die mehr als ${unref(threshold)} € Umsatz YTD im letzten Jahr hatten und dieses Jahr darunter liegen.`

    else
      return `Low-Performer: Kunden die mehr als ${unref(threshold)} € Umsatz MTD letzten ${month} hatten und diesen ${month} darunter liegen.`
  })

  const computedLPTableHead = computed(() => {
    let list: (SlotTableHead | ObjectTableHead<LowPerformer>)[] = []
    list = list.concat(lowPerformersTableHeads)
    let revenueTitle = `Umsatz MTD ${month}`
    if (selectedTimespan.value === timespanOptions.trueValue)
      revenueTitle = `Umsatz YTD ${year}`

    list.push(
      new ObjectTableHead<LowPerformer>({
        title: revenueTitle,
        attribute: 'revenueSum',
        sortable: true,
        sortKey: 'revenue_sum',
        id: 1,
        formatter: tableCurrencyFormatter,
        alignment: 'right',
      }),
    )
    return list
  })

  const config = computed(() => {
    const timespan = unref(timespanChoice) ? unref(timespanChoice) as string : timespanDefault
    const filtering = unref(globalChoice) ? unref(globalChoice) as string : globalDefault
    const config: LowPerformerParams = {
      threshold: unref(threshold),
      timespan,
      filtering,
      date: unref(date),
      page: unref(activePageLP),
    }
    return {...config, ...baseConfig?.value, query: unref(query)}
  })

  const currentConfig = ref()

  watchEffect(() => {
    if (!hasBeenCalledWithConfig(currentConfig, config)) {
      store.loadLowPerformers(config.value)
      currentConfig.value = config.value
    }
  })

  const loadingLP = computed(() => store.lowPerformers.pending)

  const filtering = computed({
    get() {
      return unref(globalChoice)
    },
    set(value?: string) {
      if (isRef(globalChoice))
        globalChoice.value = value

      activePageLP.value = 1
    },
  })

  const lowPerformers = computed(() => {
    return store.lowPerformers.data as LowPerformer[]
  })

  const totalLP = computed(() => {
    return store.lowPerformers.total
  })

  const router = useRouter()

  function openCustomerDetailView(data: LowPerformer) {
    router.push({
      name: 'customer-detail',
      params: {
        customerId: data?.customer?.id,
      },
    })
  }

  return {
    selectedTimespan,
    timespanOptions,
    globalOptions,
    filtering,
    lowPerformers,
    computedLPTableHead,
    activePageLP,
    totalLP,
    lowPerformerDefinition,
    openCustomerDetailView,
    loadingLP,
    reload,
  }
}
