import type {ComputedRef} from 'vue'
import {computed, onBeforeMount} from 'vue'
import {useRouter} from 'vue-router'
import {useToast} from 'vue-toastification'
import {useUiFilterStore} from '@/stores/ui-filter'
import {useRightStore} from '@/stores/right'
import type {ViewName} from '@/types/api/ui-filter.type'
import {useAuthStore} from '@/stores/auth'
import type {UIFilterPostParams, UIFilterUpdateParams} from '@/api/modules/ui-filter'

export default function useUiFilter(viewName: ViewName) {
  const router = useRouter()
  const uiFilter = computed(() => router.currentRoute.value.fullPath)
  const authStore = useAuthStore()
  const filterStore = useUiFilterStore()
  const rightStore = useRightStore()
  const toast = useToast()

  const currentFilter = computed(() => {
    return filterStore.getUIFilter(viewName)
  })
  const hasFilterPermission = rightStore.hasFilterPermission

  const params: ComputedRef<UIFilterUpdateParams | UIFilterPostParams | undefined> = computed(() => {
    let config: UIFilterUpdateParams | UIFilterPostParams | undefined
    const accountID = authStore.getAccountId
    if (accountID) {
      config = {
        filter: {
          url: uiFilter.value,
        },
        viewName,
        isEditable: true,
        user: accountID,
      }
      if (currentFilter.value?.id)
        (config as UIFilterUpdateParams).filterId = currentFilter.value.id
    }
    // eslint-disable-next-line no-console
    console.log(`calc params: ${config}`)
    return config
  })

  const saveFilter = () => {
    if (params.value !== undefined) {
      let promise
      if ('filterId' in params.value && params.value.filterId)
        promise = filterStore.updateUifilter(params.value)
      else filterStore.postUifilter(params.value)

      if (promise) {
        promise.then(() => {
          toast.success('Filter erfolgreich gespeichert')
        }).catch(() => {
          toast.error('Filter konnte nicht gespeichert werden.')
        })
      }
    }
  }
  const loadFilter = () => {
    filterStore.loadUserUiFilters({})
  }

  const setUserFilter = () => {
    if (currentFilter.value !== undefined && currentFilter.value.filter)
      router.push(currentFilter.value.filter.url)
  }

  onBeforeMount(() => {
    if (Object.keys(router.currentRoute.value.query).length === 0)
      setUserFilter()
  })

  return {
    setUserFilter,
    loadFilter,
    saveFilter,
    hasFilterPermission,
  }
}
