import type {Option, RadioOption, ToggleValues} from '@singularit/kurz-components'
import type {Ref} from 'vue'
import type Hierarchy from '@/types/api/hierarchy.type'

export function toggleValuesReverseMapping(toggleValues: ToggleValues) {
  return (string: string | string[]) => {
    if (Array.isArray(string))
      return toggleValues.trueValue // TODO throw error

    else if (string === toggleValues.falseValue)
      return toggleValues.falseValue

    else
      return toggleValues.trueValue
  }
}

export function radioOptionValueReverseMapping(options: RadioOption[]) {
  return (string: string | string[]) => {
    const result = options.find((option) => {
      return string.includes(option.value)
    })
    return result?.value
  }
}

export function singleValueReverseMapping(options: Ref<Option[]>, optionValueIsObject?: boolean) {
  return (string: string | string[]) => {
    const result = options.value.find((option) => {
      let value = option.value.id?.toString()
      if (!optionValueIsObject)
        value = option.value.toString()
      if (Array.isArray(string))
        // TODO currently takes first option that is found in URL, maybe decide to take first or last string?
        return string.includes(value)
      else
        return string === value
    })
    return result?.value
  }
}

export function multipleValuesReverseMapping(options: Ref<Option[]>, optionValueIsObject?: boolean) {
  return (string: string | string[]) => {
    const resultOptions = options.value.filter((option) => {
      let value = option.value.id?.toString()
      if (!optionValueIsObject)
        value = option.value.toString()
      if (Array.isArray(string))
        return string.includes(value)
      else
        return string === value
    })
    return resultOptions.map(option => option.value)
  }
}

export const hierarchyMapping = (hierarchy: Hierarchy) => (hierarchy && hierarchy.id) ? hierarchy.id.toString() : ''
export const hierarchyListMapping = (list: Hierarchy[]) => list.map(hierarchyMapping)
export const hierarchyListReverseMapping = (hierarchies: Ref<Hierarchy[]>) => {
  return (value: string | string[]): Hierarchy[] => {
    return hierarchies.value.filter((hierarchy) => {
      const id = hierarchy?.id?.toString()
      if (Array.isArray(value))
        return id ? value.includes(id) : false
      else
        return value === id
    })
  }
}
export const hierarchyReverseMapping = (hierarchies: Ref<Hierarchy[]>) => {
  return (value: string | string[]): Hierarchy | undefined => {
    return hierarchies.value.find((hierarchy) => {
      const id = hierarchy.id?.toString()
      if (Array.isArray(value))
        return id ? value.includes(id) : false
      else
        return value === id
    })
  }
}
