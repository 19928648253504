import type {Ref} from 'vue'
import {computed, ref, watchEffect} from 'vue'
import type {ToggleValues} from '@singularit/kurz-components'
import {subYears} from 'date-fns'
import type {ChartEvent, LegendItem, LineOptions} from 'chart.js'
import type {LineChart} from 'vue-chart-3'

import {currencyFormatter, numberFormatter} from '@/utils/formatter'
import type {BenchmarkParams, DashboardParams} from '@/api/modules/dashboards'
import {mapGraphLabels, mapGraphValues} from '@/utils/charts/graph-mapper'
import useUrlParam from '@/utils/behaviours/use-url-param'
import {toggleValuesReverseMapping} from '@/utils/url/url-from-string'
import {useBenchmarkStore} from '@/stores/benchmark'
import type BenchmarkChart from '@/types/api/dashboard/benchmark-chart.type'
import hasBeenCalledWithConfig from '@/utils/check-request-config'

export default function useBenchmarkChart(baseConfig?: Ref<DashboardParams>) {
  const store = useBenchmarkStore()

  const loadingBenchmark = computed(() => store.benchmarkChart.pending)// Unit
  const unitOptions: ToggleValues = {trueLabel: 'Euro', trueValue: 'money', falseValue: 'sqm', falseLabel: 'm²'}
  const unit = useUrlParam(
    'bmu',
    (string: string) => string,
    toggleValuesReverseMapping(unitOptions) as () => string,
    unitOptions.trueValue as string)

  // Timespan
  const timeOptions: ToggleValues = {trueLabel: 'Monat', trueValue: 'month', falseValue: 'year', falseLabel: 'Jahr'}
  // const timeOptions: Array<ToggleValues> = [
  //   {label: 'Jahr', trueValue: 'year'},
  //   {label: 'Monat', falseValue: 'month'},
  // ]
  const timespan = useUrlParam(
    'bmt',
    (string: string) => string,
    toggleValuesReverseMapping(timeOptions) as () => string,
    timeOptions.falseValue as string)

  // Benchmark
  const benchmarkOptions: ToggleValues = {trueLabel: 'Vorjahr', trueValue: 'prior', falseValue: 'objective', falseLabel: 'Ziel'}
  // const benchmarkOptions: Array<ToggleValues> = [
  //   {label: 'Vorjahr', trueValue: 'prior'},
  //   {label: 'Ziel', falseValue: 'objective'},
  //   // { label: 'Rolling', value: 'rolling' },
  // ]
  const benchmark = useUrlParam(
    'bmo',
    (string: string) => string,
    toggleValuesReverseMapping(benchmarkOptions) as () => string,
    benchmarkOptions.trueValue as string)

  const cumulateOption: ToggleValues = {trueLabel: 'Ja', trueValue: true, falseValue: false, falseLabel: 'Nein'}
  // const cumulateOption: Array<ToggleValues> = [
  //   {label: 'Ja', trueValue: true},
  //   {label: 'Nein', falseValue: false},
  // ]

  // const cumulativeReverse = (string: string | string[]) => {
  //   let list: boolean[] = []
  //   if (Array.isArray(string))
  //     list = [true, false]
  //
  //   else
  //     list = [(string === 'true')]
  //
  //   const result = cumulateOption.find((option) => {
  //     return list.includes(option.value)
  //   })
  //   return result?.value
  // }
  const cumulate = useUrlParam(
    'bmc',
    (bool: boolean) => {
      const b = Boolean(bool)
      return b.toString()
    },
    toggleValuesReverseMapping(cumulateOption) as () => boolean,
    cumulateOption.falseValue as boolean)

  const config = computed(() => {
    const config: Partial<BenchmarkParams> = {
      timespan: timespan.value,
      unit: unit.value,
      benchmark: benchmark.value,
      cumulate: cumulate.value,
    }
    return {...config, ...baseConfig?.value} as BenchmarkParams
  })

  const currentConfig = ref()

  watchEffect(() => {
    if (!hasBeenCalledWithConfig(currentConfig, config)) {
      store.loadBenchmarks(config.value)
      currentConfig.value = config.value
    }
  })

  const now = new Date()
  const previousYear = subYears(now, 1)
  const month = now.toLocaleString('de-DE', {month: 'long'})
  const year = now.toLocaleString('de-DE', {year: 'numeric'})

  const lastYear = previousYear.toLocaleString('de-DE', {year: 'numeric'})

  const currentLabel = computed(() => {
    let label = ''
    if (timespan.value === timeOptions.falseValue)
      label = year

    else
      label = `${month} ${year}`

    return label
  })

  const benchmarkLabel = computed(() => {
    let label = ''
    if (timespan.value === timeOptions.falseValue)
      label = lastYear

    else
      label = `${month} ${lastYear}`

    return label
  })

  const data = computed(() => {
    const chartData = store.benchmarkChart.data as BenchmarkChart
    const currentData = mapGraphValues(chartData?.current)
    const benchmarkData = mapGraphValues(chartData?.benchmark)
    const labels = mapGraphLabels(chartData?.current)
    // currentData = store.getters.getBenchmarkChart.current.map((value: Coordinate) => value.y)
    // currentData = store.getters.getBenchmarkChart.benchmark.map((value: Coordinate) => value.y)
    return {
      labels,
      datasets: [
        {
          label: currentLabel.value,
          data: currentData,
          borderColor: 'rgb(236, 106, 29)',
          cubicInterpolationMode: 'monotone' as LineOptions['cubicInterpolationMode'],
        },
        {
          label: benchmarkLabel.value,
          data: benchmarkData,
          borderColor: 'rgb(251, 216, 190)',
          cubicInterpolationMode: 'monotone' as LineOptions['cubicInterpolationMode'],
        },
      ],
    }
  })

  const options = ref({
    scales: {
      y: {
        ticks: {
          // Include a dollar sign in the ticks
          callback(value: number) {
            return unit.value === 'money'
              ? currencyFormatter.format(value)
              : `${numberFormatter.format(value)} m²`
          },
        },
      },
    },
    plugins: {
      responsive: true,
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          sort: (a: LegendItem, b: LegendItem) => a.text < b.text ? -1 : 1,
        },
        onHover: (event: ChartEvent) => {
          const target = event.native?.target
          if (target)
            (target as HTMLElement).style.cursor = 'pointer'
        },
        onLeave: (event: ChartEvent) => {
          const target = event.native?.target
          if (target)
            (target as HTMLElement).style.cursor = 'default'
        },
      },
      tooltip: {
        callbacks: {
          title(context: typeof LineChart) {
            context = context[0]
            return `${context.label.split(' ')[0]} ${context.dataset.label}`
          },
          label(context: typeof LineChart) {
            return unit.value === 'money' // TODO
              ? currencyFormatter.format(context.raw)
              : `${numberFormatter.format(context.raw)} m²`
          },
        },
      },
    },
  })
  return {
    loadingBenchmark,
    data,
    options,
    unitOptions,
    unit,
    timeOptions,
    timespan,
    benchmarkOptions,
    benchmark,
    cumulateOption,
    cumulate,
  }
}
