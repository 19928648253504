import {computed} from 'vue'

export default function useSaleOrganisationFilter() {
  // TODO implement when backend offers data
  // const store = useStore<HierarchyModule>();
  //
  // const config = {
  //   page: 1,
  // };
  //
  // store.dispatch(ActionTypes.GET_ALL_HIERARCHIES, config);

  const salesOrganizations = computed(() => {
    const list = []
    list.push({
      value: 0,
      label: 'alle (Deutschland gesamt)',
    },
    )
    list.push({
      value: 1,
      label: '0001 (Fürth)',
    },
    )
    list.push({
      value: 5,
      label: '0005 (Typofol)',
    },
    )
    // if (store.getters.getSalesOrganization){
    //   list = list.concat(store.getters.getSalesOrganization)
    // }
    return list
  })

  return {
    salesOrganizations,
  }
}
