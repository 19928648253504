<template>
  <div class="xl:h-full flex flex-col">
    <!-- Headline -->
    <div
      class="w-[96%] z-30 bg-lightwhite flex flex-col lg:flex-row mb-3 justify-between lg:basis-1/3  fixed mb-10 pb-10 lg:pb-0"
    >
      <div class="flex flex-row">
        <h1 class="page-head">
          Dashboard VK
        </h1>
        <div class="h-10 w-10 mt-1 flex items-center justify-center cursor-pointer">
          <font-awesome-icon
            :icon="[isFilterActive ? 'fas' : 'far', 'filter']"
            class="text-2xl"
            @click="showFilter = true"
          />
        </div>
      </div>
      <div class="order-last lg:order-none flex justify-center">
        <div class="flex flex-row gap-2 self-center mt-4 items-center">
          <font-awesome-icon
            class="hover:cursor-pointer select-none"
            icon="angle-left"
            @click="prevWeek"
          />
          <k-week-picker
            v-model="watchedWeek"
            :label-format="weekPickerFormat"
            :preview-format="weekPickerFormat"
            class="w-40"
          />
          <font-awesome-icon
            class="hover:cursor-pointer select-none"
            icon="angle-right"
            @click="nextWeek"
          />
        </div>
      </div>
      <div>
        <ImportTimestamp
          dependant-on="invoices"
          class="ml-4 lg:ml-0 mr-4"
        />
      </div>
    </div>

    <!-- content -->
    <div class="grid xl:grid-cols-2 lg:grid-cols-2 sm:grid-cols-1 xl:h-full overflow-auto auto-rows-fr  mt-48 lg:mt-16">
      <!-- Umsätze Card -->
      <k-card class="dashboard-card">
        <template #title>
          <div class="w-full justify-between flex flex-row">
            <h2>Umsätze</h2>
            <div class="mx-5 flex flex-row gap-4">
              <font-awesome-icon
                v-if="salesDisplay !== 'overview'"
                class="hover:cursor-pointer"
                size="lg"
                icon="eye"
                @click="changeSalesDisplay('overview')"
              />
              <font-awesome-icon
                v-if="salesDisplay !== 'chart'"
                class="hover:cursor-pointer"
                size="lg"
                icon="chart-line"
                @click="changeSalesDisplay('chart')"
              />
              <font-awesome-icon
                v-else
                class="hover:cursor-pointer"
                size="lg"
                icon="table"
                @click="changeSalesDisplay('table')"
              />
            </div>
          </div>
        </template>
        <div class="w-full">
          <div
            v-if="loadingOverview && salesDisplay === 'overview'"
            class="h-full w-full flex justify-center"
          >
            <k-kurz-dot-loader class="self-center" />
          </div>
          <div
            v-else-if="loadingBenchmark && salesDisplay === 'chart'"
            class="h-full w-full flex justify-center"
          >
            <k-kurz-dot-loader class="self-center" />
          </div>
          <div
            v-else-if="salesDisplay === 'overview'"
            class="h-full flex flex-col justify-center gap-20 w-full"
          >
            <div class="flex flex-row justify-center gap-3">
              <p
                v-if="revenueTarget"
                class="align-center text-center font-bold"
              >
                Umsatzsumme: {{ revenueString }} --> {{ reachedGoal }}% vom Wochenziel
              </p>
              <p
                v-else
                class="align-center text-center font-bold"
              >
                Umsatzsumme: {{ revenueString }}
              </p>
              <font-awesome-icon
                v-if="reachedGoal && reachedGoal < 100"
                icon="exclamation-triangle"
                class="self-center"
                :class="{'text-trafficlight-red': reachedGoal < 95, 'text-trafficlight-yellow': 95 <= reachedGoal && reachedGoal < 100}"
              />
              <font-awesome-icon
                v-else-if="reachedGoal && reachedGoal >= 100"
                icon="check-circle"
                class="self-center text-trafficlight-green"
              />
            </div>
            <font-awesome-icon
              class="hover:cursor-pointer self-center"
              size="2x"
              icon="table"
              @click="changeSalesDisplay('table')"
            />
          </div>
          <div
            v-else-if="salesDisplay === 'table' && totalInvoices > 0 || loadingInvoices"
            class="h-full"
          >
            <k-table
              v-model:page="activePageInvoices"
              class="flex-1"
              :head="salesTHeads"
              :data="invoices"
              :total-rows="totalInvoices"
              :default-sort-id="1"
              :data-loading="loadingInvoices"
              :default-sort-order="OrderDirection.DESCENDING"
              async
              @reload="salesReload"
              @change-page="activePageInvoices = $event"
              @row-select="openCustomerDetailViewSales"
            >
              <template #customer-slot="{ content }">
                <k-attribute-display
                  class="customer-name-wrapper"
                  :value="content?.customer"
                >
                  {{ content.customer.name }}
                  <span class="customer-id invisible">
                    ID: {{ content.customer.id }}
                  </span>
                </k-attribute-display>
              </template>
            </k-table>
          </div>
          <p
            v-else-if="salesDisplay === 'table' && totalInvoices < 1 && !loadingInvoices"
            class="font-bold w-full text-center mt-2"
          >
            Keine Daten für diese Woche vorhanden
          </p>
          <div
            v-else-if="salesDisplay === 'chart'"
            class="h-[90%]"
          >
            <LineChart
              :chart-data="salesChartData"
              :options="salesChartOptions"
              class="m-5 w-full pr-5 h-full"
              :height="300"
            />
          </div>
        </div>
      </k-card>

      <!-- ehemalige Aufträge card, noch nicht klar, was hierher soll -->
      <!--      <k-card-->
      <!--        class="dashboard-card"-->
      <!--        :header="false"-->
      <!--      >-->
      <!--        <template #title>-->
      <!--          <h2>Aufträge</h2>-->
      <!--        </template>-->
      <!--        <div class="flex flex-col w-full">-->
      <!--          <Table-->
      <!--            class="flex-1"-->
      <!--            :head="orderTableHeads"-->
      <!--            :data="orders"-->
      <!--            :total-rows="totalOrders"-->
      <!--            async-->
      <!--            searchable-->
      <!--            @reload="orderReload"-->
      <!--            @change-page="activePageOrders = $event"-->
      <!--          >-->
      <!--            <template #customer-slot="{ content }">-->
      <!--              <span-->
      <!--                v-if="content && content.customer"-->
      <!--                class="whitespace-nowrap"-->
      <!--              >-->
      <!--                {{ content.customer.name }}-->
      <!--              </span>-->
      <!--              <div v-else>-->
      <!--                - -->
      <!--              </div>-->
      <!--            </template>-->
      <!--          </k-table>-->
      <!--          <hr class="mt-1">-->
      <!--          <div class="flex flex-row justify-center my-5">-->
      <!--            <span class="mr-5"> Anzahl: </span>-->
      <!--            <span class="font-bold"> {{ totalOrders }}</span>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </k-card>-->

      <!-- Angebote Card -->
      <k-card class="dashboard-card">
        <template #title>
          <div
            class="flex flex-row"
            :class="{'justify-between': offerDisplay==='table'}"
          >
            <h2>Angebote</h2>
            <div class="mx-5">
              <font-awesome-icon
                v-if="offerDisplay==='table'"
                class="hover:cursor-pointer"
                size="lg"
                icon="eye"
                @click="changeOfferDisplay('overview')"
              />
            </div>
          </div>
        </template>
        <div class="w-full flex flex-col">
          <div
            v-if="loadingOverview && offerDisplay === 'overview'"
            class="h-full w-full flex justify-center"
          >
            <k-kurz-dot-loader class="self-center" />
          </div>
          <div
            v-else-if="offerDisplay === 'overview'"
            class="h-full flex flex-col justify-center gap-4 w-full"
          >
            <div class="flex flex-row justify-center gap-3">
              <p class="align-center text-center font-bold">
                Deadline 1 Woche: {{ criticalOfferCounts.criticalOfferCount }}
              </p>
              <font-awesome-icon
                v-if="criticalOfferCounts.criticalOfferCount > 0"
                icon="exclamation-triangle"
                class="text-trafficlight-red self-center"
              />
            </div>
            <div class="flex flex-row justify-center gap-3">
              <p class="align-center text-center font-bold">
                Deadline 2 Wochen: {{ criticalOfferCounts.semiCriticalOfferCount }}
              </p>
              <font-awesome-icon
                v-if="criticalOfferCounts.semiCriticalOfferCount > 0"
                icon="exclamation-triangle"
                class="self-center text-trafficlight-yellow"
              />
            </div>
            <font-awesome-icon
              class="hover:cursor-pointer self-center mt-10"
              size="2x"
              icon="table"
              @click="changeOfferDisplay('table')"
            />
          </div>
          <div
            v-if="offerDisplay==='table'"
            class="h-full"
          >
            <k-table
              v-model:page="offerActivePage"
              class="flex-1"
              :head="offerTableHeadVK"
              :data="offers"
              :total-rows="totalOffers"
              :default-sort-id="0"
              :data-loading="loadingOffers"
              async
              @reload="offerReload"
              @change-page="offerActivePage=$event"
              @row-select="openOfferOverview"
            >
              <template #date-slot="{ content }">
                <div
                  v-if="content && content.end"
                  class="flex flex-row gap-3 whitespace-nowrap"
                >
                  <font-awesome-icon
                    v-if="isCritical(content) || isSemiCritical(content)"
                    icon="exclamation-triangle"
                    class="self-center"
                    :class="{'text-trafficlight-red': isCritical(content), 'text-trafficlight-yellow': isSemiCritical(content)}"
                  />
                  <span class="mr-0 ml-auto whitespace-nowrap">
                    {{ dateFormatter(content.end) }}
                  </span>
                </div>
                <span v-else>
                  -
                </span>
              </template>
              <template #customer-slot="{ content }">
                <span
                  v-if="content && content.customer"
                  class="whitespace-nowrap"
                >
                  {{ content.customer.name }}
                </span>
                <div v-else>
                  -
                </div>
              </template>
            </k-table>
          </div>
        </div>
      </k-card>

      <!-- Churn Card -->
      <k-card class="dashboard-card">
        <template #title>
          <h2>Abgesprungen</h2>
        </template>
        <k-table
          v-model:page="churnActivePage"
          :head="churnTableHeadVK"
          :data="churns"
          async
          :total-rows="totalChurns"
          :default-sort-id="1"
          :data-loading="loadingChurns"
          class="w-full"
          @reload="churnReload"
          @change-page="churnActivePage = $event"
        >
          <template #customer-slot="{ content }">
            <span>
              {{ content.customer.name }}
            </span>
          </template>
        </k-table>

        <div
          v-if="!churns"
          class="text-center"
        >
          Keine Daten vorhanden
        </div>
      </k-card>

      <!-- Risiko Card -->
      <!--      <k-card class="dashboard-card">-->
      <!--        <template #title>-->
      <!--          <h2>Risiko</h2>-->
      <!--        </template>-->
      <!--      </k-card>-->

      <!-- Low Performance Card -->
      <k-card
        :info-text="lowPerformerDefinition"
        class="dashboard-card"
      >
        <template #title>
          <h2>Low Performance</h2>
        </template>
        <k-table
          v-model:page="activePageLP"
          class="flex-1"
          :head="computedLPTableHead"
          :data="lowPerformers"
          :total-rows="totalLP"
          :default-sort-id="2"
          :data-loading="loadingLP"
          async
          @reload="lPReload"
          @change-page="activePageLP = $event"
          @row-select="openCustomerDetailViewLP"
        >
          <template #customer-slot="{content}">
            <div
              v-if="content.customer"
              class="customer-name-wrapper whitespace-nowrap"
            >
              {{ content.customer.name }}
              <span class="customer-id invisible">
                ID: {{ content.customer.id }}
              </span>
            </div>
            <div v-else>
              -
            </div>
          </template>
        </k-table>
      </k-card>

      <k-modal
        :modal-active="showFilter"
        @close="showFilter=false"
      >
        <template #head>
          <h1 class="page-head">
            Filter
          </h1>
        </template>
        <hr>
        <div
          v-if="filtersLoading"
          class="flex justify-center py-10"
        >
          <k-kurz-dot-loader />
        </div>
        <div
          v-else
          class="flex flex-col pb-5"
        >
          <hr>
          <div class="filter-list flex flex-col gap-5 py-5">
            <k-select-input
              v-model="selectedSalesOrganization"
              class="max-w-xs m-0"
              placeholder="Verkaufsorganisation"
              clear-able
              :options="salesOrganizations"
            />
            <k-select-input
              v-model="selectedCountryCode"
              class="max-w-xs m-0"
              placeholder="Land"
              clear-able
              :options="countryCodes"
            />
            <div class="filter-list-group">
              <h1>Produkthierarchien:</h1>
              <k-kurz-multi-select
                v-model="ph1selected"
                placeholder="PH1"
                clearable
                :options="level1"
                :label-function="hierarchyLabelFunction"
              />
              <k-kurz-multi-select
                v-model="ph2selected"
                class="max-w-xs m-0"
                placeholder="PH2"
                :disabled="level2Disabled"
                clearable
                :options="level2"
                :label-function="hierarchyLabelFunction"
              />
              <k-kurz-multi-select
                v-model="ph3selected"
                class="max-w-xs m-0"
                placeholder="PH3"
                :disabled="level3Disabled"
                clearable
                :options="level3"
                :label-function="hierarchyLabelFunction"
              />
            </div>
          </div>
          <hr>
          <div class="flex justify-between pt-5">
            <k-kurz-button
              text="Filter laden"
              @click="setUserFilter"
            />
            <k-kurz-button
              text="Filter speichern"
              @click="saveFilter"
            />
          </div>
        </div>
      </k-modal>
    </div>
  </div>
</template>

<script lang="ts">

import {computed, defineComponent, ref, unref, watch} from 'vue'
import {LineChart} from 'vue-chart-3'
import {addWeeks, endOfISOWeek, format, getISOWeek, getISOWeekYear, parseISO, startOfISOWeek, subWeeks} from 'date-fns'
import {OrderDirection} from '@singularit/kurz-components'
import useSales from '@/views/dashboard/vk-dashboard/use-sales'
import useChurnList from '@/views/churn/use-churn-list'
// import useOrderList from '@/views/order/use-order-list';
// import orderTableHeads from '@/views/order/order-table-heads';
import useLowPerformer from '@/views/dashboard/vkl-dashboard/use-low-performer'
import useHierarchyFilters from '@/views/dashboard/vkl-dashboard/use-hierarchy-filters'
import type {DashboardParams} from '@/api/modules/dashboards'
import ImportTimestamp from '@/components/timestamp/ImportTimestamp.vue'
import {churnTableHeadVK, offerTableHeadVK} from '@/views/dashboard/vk-dashboard/vk-dashboard-table-heads'
import useWeeklyOffers from '@/views/dashboard/vk-dashboard/use-weekly-offers'
import useOverview from '@/views/dashboard/vk-dashboard/use-overview'
import {dateFormatter} from '@/utils/formatter'
import useUrlParam from '@/utils/behaviours/use-url-param'
import useSaleOrganisationFilter from '@/views/dashboard/vkl-dashboard/use-sales-organisation-filter'
import {singleValueReverseMapping} from '@/utils/url/url-from-string'
import useCountryCodeFilter from '@/views/dashboard/vkl-dashboard/use-country-code-filter'
import useUiFilter from '@/utils/use-ui-filter'

export default defineComponent({
  name: 'VKDashboard',
  components: {ImportTimestamp, LineChart},
  setup() {
    function watchedWeekReverse(paramString: string | string[]) {
      let watchedString = ''
      if (Array.isArray(paramString))
        watchedString = paramString[0]

      else
        watchedString = paramString

      const watchedStringArray = watchedString.split(',')
      return [parseISO(watchedStringArray[0]), parseISO(watchedStringArray[1])]
    }

    const watchedWeekDefault = [startOfISOWeek(new Date()), endOfISOWeek(new Date())]
    const watchedWeek = useUrlParam(
      'week',
      (dates: Date[]) => {
        const date1 = format(dates[0], 'yyyy-MM-dd')
        const date2 = format(dates[1], 'yyyy-MM-dd')
        return `${date1},${date2}`
      },
      watchedWeekReverse,
      watchedWeekDefault,
    )

    const watchedWeekEndDate = computed(() => {
      return watchedWeek.value[1]
    })

    const watchedYear = computed(() => {
      let value = watchedWeekDefault[0]
      if (watchedWeek && watchedWeek.value && watchedWeek.value[0])
        value = watchedWeek.value[0]

      return getISOWeekYear(value)
    })

    function nextWeek() {
      watchedWeek.value = [
        addWeeks(watchedWeek.value[0], 1),
        addWeeks(watchedWeek.value[1], 1),
      ]
    }

    function prevWeek() {
      watchedWeek.value = [
        subWeeks(watchedWeek.value[0], 1),
        subWeeks(watchedWeek.value[1], 1),
      ]
    }

    const weekPickerFormat = (dates: Date[]) => {
      return `KW ${getISOWeek(dates[0])} ${getISOWeekYear(dates[0])}`
    }

    const {salesOrganizations} = useSaleOrganisationFilter()

    const selectedSalesOrganization = useUrlParam('orga', (value: number) => value ? value.toString() : '', singleValueReverseMapping(salesOrganizations), undefined)

    const {countryCodes} = useCountryCodeFilter()

    const selectedCountryCode = useUrlParam('country', (value: string) => value, singleValueReverseMapping(countryCodes), undefined)

    const {
      level1,
      level2,
      level3,
      level2Disabled,
      level3Disabled,
      ph1selected,
      ph2selected,
      ph3selected,
      hierarchyLabelFunction,
      hierarchiesLoading,
    } = useHierarchyFilters()

    const filtersLoading = computed(() => {
      return hierarchiesLoading.value // || countriesLoading.value || orgasLoading.value
    })

    const baseConfig = computed(() => {
      const config: DashboardParams = {}
      if (unref(ph1selected)) {
        const hierarchies1 = []
        for (const ph1s of unref(ph1selected)) {
          if (ph1s.id !== undefined)
            hierarchies1.push(ph1s.id)
        }
        config.hierarchies1 = hierarchies1
      }
      if (unref(ph2selected)) {
        const hierarchies2 = []
        for (const ph2s of unref(ph2selected)) {
          if (ph2s.id !== undefined)
            hierarchies2.push(ph2s.id)
        }
        config.hierarchies2 = hierarchies2
      }
      if (unref(ph3selected)) {
        const hierarchies3 = []
        for (const ph3s of unref(ph3selected)) {
          if (ph3s.id !== undefined)
            hierarchies3.push(ph3s.id)
        }
        config.hierarchies3 = hierarchies3
      }
      if (unref(selectedSalesOrganization) && selectedSalesOrganization.value !== 0)
        config.salesOrganization = unref(selectedSalesOrganization)

      if (unref(selectedCountryCode) && selectedCountryCode.value !== '')
        config.country = unref(selectedCountryCode)

      return config
    })

    const {
      revenueTarget,
      revenueString,
      reachedGoal,
      criticalOfferCounts,
      loadingOverview,
    } = useOverview(watchedWeek, baseConfig)

    const {
      salesDisplay, changeSalesDisplay, invoices, totalInvoices,
      activePageInvoices, salesChartData, salesChartOptions, salesTHeads, salesReload,
      openCustomerDetailView: openCustomerDetailViewSales, loadingBenchmark, loadingInvoices,
    } = useSales(watchedWeek, baseConfig)

    const {
      offers, totalOffers, offerActivePage, openOfferOverview, loadingOffers,
      offerDisplay, changeOfferDisplay, criticalOffers, isCritical, isSemiCritical, offerReload,
    } = useWeeklyOffers(watchedWeek, baseConfig)

    // const {query: offerQuery, reload: offerReload} = useTableQuery()
    // const {offers, totalOffers, activePage: offerActivePage, openDetailView} = useOfferList(offerQuery)

    const {churns, totalChurns, activePage: churnActivePage, loadingChurns, reload: churnReload} = useChurnList(watchedWeek)
    // const {query: orderQuery, reload: orderReload} = useTableQuery()
    // const {orders, totalOrders, activePage: activePageOrders} = useOrderList(orderQuery)

    const {setUserFilter, saveFilter, loadFilter, hasFilterPermission} = useUiFilter('vkDashboard')

    const showFilter = ref(false)
    const isFilterActive = computed(() => {
      return ph1selected.value.length > 0
          || ph2selected.value.length > 0
          || ph3selected.value.length > 0
          || selectedCountryCode.value !== undefined
          || selectedSalesOrganization.value !== undefined
    })

    const {
      lowPerformers,
      totalLP,
      activePageLP,
      openCustomerDetailView: openCustomerDetailViewLP,
      computedLPTableHead,
      loadingLP,
      reload: lPReload,
      lowPerformerDefinition,
    } = useLowPerformer(5000, baseConfig, watchedWeekEndDate)

    watch(watchedWeek, () => {
      // eslint-disable-next-line no-console
      console.log('watch Week')
      activePageInvoices.value = 1
      activePageLP.value = 1
      offerActivePage.value = 1
      churnActivePage.value = 1
      // eslint-disable-next-line no-console
      console.log(offerActivePage)
    })

    return {
      watchedWeek,
      nextWeek,
      prevWeek,
      weekPickerFormat,
      salesDisplay,
      changeSalesDisplay,
      level1,
      level2,
      level3,
      level2Disabled,
      level3Disabled,
      ph1selected,
      ph2selected,
      ph3selected,
      hierarchyLabelFunction,
      salesOrganizations,
      selectedSalesOrganization,
      countryCodes,
      selectedCountryCode,
      filtersLoading,
      loadingInvoices,
      invoices,
      watchedYear,
      totalInvoices,
      revenueTarget,
      revenueString,
      reachedGoal,
      loadingOverview,
      activePageInvoices,
      loadingBenchmark,
      salesChartData,
      salesChartOptions,
      salesTHeads,
      salesReload,
      openCustomerDetailViewSales,
      loadingOffers,
      offerDisplay,
      changeOfferDisplay,
      offerReload,
      offers,
      totalOffers,
      criticalOfferCounts,
      criticalOffers,
      isCritical,
      isSemiCritical,
      offerActivePage,
      openOfferOverview,
      offerTableHeadVK,
      loadingChurns,
      churns,
      churnTableHeadVK,
      totalChurns,
      churnActivePage,
      churnReload,
      // orderReload,
      // orders,
      // totalOrders,
      // activePageOrders,
      // orderTableHeads,

      loadingLP,
      lowPerformers,
      totalLP,
      lowPerformerDefinition,
      activePageLP,
      lPReload,
      computedLPTableHead,
      openCustomerDetailViewLP,

      dateFormatter,

      isFilterActive,
      setUserFilter,
      loadFilter,
      saveFilter,
      hasFilterPermission,
      showFilter,
      OrderDirection,
    }
  },
})
</script>

<style scoped>

.dashboard-card {
  max-height: calc(60vh - 10rem);
}

.customer-name-wrapper:hover .customer-id {
  @apply visible;
}

.filter-list-group {
  @apply flex flex-col gap-2;
}
</style>
