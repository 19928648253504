import type {Ref} from 'vue'
import {computed, ref, unref, watchEffect} from 'vue'
import {format} from 'date-fns'
import type {ChurnListParams} from '@/api/modules/churns'
import {useChurnStore} from '@/stores/churn'
import useTableQuery from '@/utils/use-table-query'

export default function useChurnList(
  timespan?: Ref<Date[]>,
) {
  const store = useChurnStore()

  const {query, reload} = useTableQuery()
  const activePage = ref(1)
  const config = computed(() => {
    const config: ChurnListParams = {
      page: unref(activePage),
      query: unref(query),
      likelihood__gte: 0.8,
    }
    if (timespan && timespan?.value[0])
      config.date__gte = format(timespan?.value[0], 'yyyy-MM-dd')
    if (timespan && timespan?.value[1])
      config.date__lte = format(timespan?.value[1], 'yyyy-MM-dd')
    return config
  })

  watchEffect(() => {
    store.loadChurns(config.value)
  })

  const loadingChurns = computed(() => store.churns.pending)

  const churns = computed(() => {
    return store.churns.data
  })
  const totalChurns = computed(() => {
    return store.churns.total
  })

  return {
    loadingChurns,
    totalChurns,
    activePage,
    churns,
    reload,
  }
}
