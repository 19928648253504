import type {AxiosResponse} from 'axios'
import type PaginationListParameters from '@/api/modules/pagination-list-parameters'
import type QueryParameters from '@/api/modules/query-parameters'
import BaseModule from '@/api/modules/base-module'
import type Page from '@/types/api/page.type'
import axiosInstance from '@/api/axios'
import type Account from '@/types/api/account.type'
import type Right from '@/types/api/right.type'
import type Group from '@/types/api/group.type'

export interface AccountListParams extends PaginationListParameters, QueryParameters {
}

export interface AccountDetailParams {
  id?: number
}

export interface AccountUpdateParams extends AccountDetailParams {
  password?: string
}

export interface AccountRightsPostParams extends AccountDetailParams {
  rights?: Array<number>
}

export interface AccountGroupPostParams extends AccountDetailParams {
  group?: number
}

class AccountModule extends BaseModule {
  getAccounts(params: AccountListParams, controller?: AbortController): Promise<AxiosResponse<Page<Account>>> {
    const signal = controller ? controller.signal : undefined
    const data = {
      params,
      signal,
    }
    return axiosInstance.get('accounts/', data).then((response) => {
      return response
    })
  }

  getAccountDetails(params: AccountDetailParams, controller?: AbortController): Promise<AxiosResponse<Account>> {
    const signal = controller ? controller.signal : undefined
    const accountId = params.id
    delete params.id
    const data = {
      params,
      signal,
    }
    return axiosInstance.get(`accounts/${accountId}/`, data).then((response: AxiosResponse) => {
      return response
    })
  }

  getAccountRights(params: AccountDetailParams, controller?: AbortController): Promise<AxiosResponse<Array<Right>>> {
    const signal = controller ? controller.signal : undefined

    const accountId = params.id
    delete params.id

    const data = {
      signal,
    }
    return axiosInstance.get(`accounts/${accountId}/rights/`, data).then((response: AxiosResponse) => {
      return response
    })
  }

  getAccountGroups(params: AccountDetailParams, controller?: AbortController): Promise<AxiosResponse<Array<Group>>> {
    const signal = controller ? controller.signal : undefined

    const accountId = params.id
    delete params.id

    const data = {
      signal,
    }
    return axiosInstance.get(`accounts/${accountId}/group/`, data).then((response: AxiosResponse) => {
      return response
    })
  }

  updateAccountDetails(params: AccountUpdateParams): Promise<AxiosResponse> {
    const accountId = params.id
    return axiosInstance.patch(`accounts/${accountId}/`, params).then((response: AxiosResponse) => {
      return response
    })
  }

  postAccountRights(params: AccountRightsPostParams): Promise<AxiosResponse> {
    const accountId = params.id
    return axiosInstance.post(`accounts/${accountId}/rights/`, params).then((response: AxiosResponse) => {
      return response
    })
  }

  postAccountGroup(params: AccountGroupPostParams): Promise<AxiosResponse> {
    const accountId = params.id
    return axiosInstance.post(`accounts/${accountId}/group/`, params).then((response: AxiosResponse) => {
      return response
    })
  }
}

const module = new AccountModule()
export default module
