import type {Ref} from 'vue'
import {computed, ref, watchEffect} from 'vue'
import {format, getISOWeek, subWeeks} from 'date-fns'
import {ObjectTableHead, SlotTableHead} from '@singularit/kurz-components'
import {useRouter} from 'vue-router'
import type {ChartData} from 'chart.js'
import type {LineChart} from 'vue-chart-3'
import {mapGraphValues} from '@/utils/charts/graph-mapper'
import {currencyFormatter, tableCurrencyFormatter} from '@/utils/formatter'
import {daysOfWeekLabels} from '@/utils/charts/graph-util'
import type Invoice from '@/types/api/invoice.type'
import {useInvoiceStore} from '@/stores/invoice'
import {useBenchmarkStore} from '@/stores/benchmark'
import useTableQuery from '@/utils/use-table-query'
import type {DashboardParams} from '@/api/modules/dashboards'
import type {InvoiceAggregationParams} from '@/api/modules/invoices'
import {AggregationFunction} from '@/api/params/aggregation.type'
import type {InvoiceAggregation} from '@/types/api/aggregation.type'
import {parseSorting} from '@/utils/parser'

export default function useSales(watchedWeek: Ref<Date[]>, baseConfig: Ref<DashboardParams>) {
  const salesDisplay = ref('overview')

  function changeSalesDisplay(newDisplay: string) {
    salesDisplay.value = newDisplay
  }

  const startDate = computed(() => format(watchedWeek.value[0], 'yyyy-MM-dd'))
  const endDate = computed(() => format(watchedWeek.value[1], 'yyyy-MM-dd'))

  const invoiceStore = useInvoiceStore()

  const activePageInvoices = ref(1)
  const {query: salesQuery, reload: salesReload} = useTableQuery()

  const invoiceConfig = computed(() => {
    let orderString
    if (salesQuery.value && salesQuery.value.orderEntry) {
      const entry = salesQuery.value.orderEntry
      orderString = parseSorting(entry)
    }
    const config: InvoiceAggregationParams = {
      page: activePageInvoices.value,
      invoice_date__gte: startDate.value,
      invoice_date__lte: endDate.value,
      query: salesQuery.value,
      aggregationField: 'revenue',
      groupBy: 'customer',
      orderBy: orderString,
      aggregation: AggregationFunction.SUM,
    }
    return {...config, ...baseConfig?.value}
  })

  watchEffect(() => {
    if (salesDisplay.value === 'table')
      // invoiceStore.loadInvoices(invoiceConfig.value)
      invoiceStore.loadInvoiceAggregate(invoiceConfig.value)
  })

  const loadingInvoices = computed(() => {
    return invoiceStore.aggregates.pending
  })

  const invoices = computed(() => {
    return invoiceStore.aggregates.data as InvoiceAggregation[]
  })

  const totalInvoices = computed(() => {
    return invoiceStore.aggregates.total
  })

  // const {invoices, totalInvoices, activePage: activePageInvoices} = useInvoiceList(undefined, start_date, end_date)

  const benchmarkStore = useBenchmarkStore()

  const benchmarkConfig = computed(() => {
    const config = {
      timespan: 'week',
      unit: 'money',
      benchmark: 'prior',
      cumulate: false,
      date: watchedWeek.value[0],
    }
    return {...config, ...baseConfig?.value}
  })

  watchEffect(() => {
    if (salesDisplay.value === 'chart')
      benchmarkStore.loadBenchmarks(benchmarkConfig.value)
  })

  const loadingBenchmark = computed(() => {
    return benchmarkStore.benchmarkChart.pending
  })

  const salesChartData = computed<ChartData<'line'>>(() => {
    const chartData = benchmarkStore.benchmarkChart
    const currentData = mapGraphValues(chartData.data?.current)
    const benchmarkData = mapGraphValues(chartData.data?.benchmark)
    const labels = daysOfWeekLabels(watchedWeek?.value[0])
    return {
      labels,
      datasets: [
        {
          label: `Umsatz KW ${getISOWeek(watchedWeek.value[0])}`,
          data: currentData,
          borderColor: 'rgb(236, 106, 29)',
          cubicInterpolationMode: 'monotone',
        },
        {
          label: `Umsatz KW ${getISOWeek(watchedWeek.value[0])} Vorjahr`,
          data: benchmarkData,
          borderColor: 'rgb(251, 216, 190)',
          cubicInterpolationMode: 'monotone',
        },
      ],
    }
    // as ChartData<'line',DefaultDataPoint<'line'>,string>
  })

  const salesChartOptions = ref({
    scales: {
      y: {
        ticks: {
          callback(value: number) {
            return currencyFormatter.format(value)
          },
        },
      },
    },
    plugins: {
      responsive: true,
      legend: {
        display: true,
        position: 'bottom',
        onHover: (event: any) => {
          event.native.target.style.cursor = 'pointer'
        },
        onLeave: (event: any) => {
          event.native.target.style.cursor = 'default'
        },
      },
      tooltip: {
        callbacks: {
          title(context: typeof LineChart) {
            context = context[0]
            if (context.dataset.borderColor === '#96ff00') {
              const dateSplit = context.label.split('.')
              return subWeeks(new Date(dateSplit[2], dateSplit[1] - 1, dateSplit[0]), 1)
                .toLocaleDateString('DE-de', {day: '2-digit', month: '2-digit', year: 'numeric'})
            }
            return context.label
          },
          label(context: typeof LineChart) {
            return currencyFormatter.format(context.raw)
          },
        },
      },
    },
  })

  const salesTHeads = [
    new SlotTableHead({
      title: 'Kunde',
      sortable: true,
      id: 0,
      sortKey: 'customer__name',
      slotName: 'customer-slot',
    }),
    new ObjectTableHead({
      title: 'Umsatz',
      attribute: 'value',
      sortable: true,
      id: 1,
      formatter: tableCurrencyFormatter,
    }),
  ]

  const router = useRouter()

  function openCustomerDetailView(data: Invoice) {
    router.push({
      name: 'customer-detail',
      params: {
        customerId: data.customer?.id,
      },
    })
  }

  return {
    salesDisplay,
    changeSalesDisplay,
    watchedWeek,
    invoices,
    totalInvoices,
    activePageInvoices,
    salesChartData,
    salesChartOptions,
    salesTHeads,
    openCustomerDetailView,
    loadingInvoices,
    loadingBenchmark,
    salesReload,
  }
}
