import type {AxiosResponse} from 'axios'
import Qs from 'qs'
import axiosInstance from '@/api/axios'
import type Page from '@/types/api/page.type'
import type Offers from '@/types/api/offer.type'
import type QueryParameters from '@/api/modules/query-parameters'
import type Customer from '@/types/api/customer.type'
import BaseModule from '@/api/modules/base-module'
import type {DashboardParams} from '@/api/modules/dashboards'
import type {AggregationParams} from '@/api/params/aggregation.type'
import type {OfferAggregation} from '@/types/api/aggregation.type'

export interface OffersListParams extends DashboardParams, QueryParameters {
  customer?: Customer
  receipt?: number
  position?: number
  start__gte?: string
  end__gte?: string
}

export type OffersAggregationParams = OffersListParams & AggregationParams

export interface OfferDetailParams {
  receipt?: number
  position?: number
}

export interface OfferOverviewParams {
  receipt: number
}

class OfferModule extends BaseModule {
  getOffers(params: OffersListParams, controller?: AbortController): Promise<AxiosResponse<Page<Offers>>> {
    const signal = controller ? controller.signal : undefined
    const data = {
      params,
      signal,
      // paramsSerializer: (params: OffersListParams) => Qs.stringify(params, {arrayFormat: 'repeat'}),
    }
    const date = data.params.start__gte
    delete data.params.start__gte
    return axiosInstance.get(`/offers/${date ? `?start__gte=${date}` : ''}`, data)
  }

  getOfferAggregations(params: OffersAggregationParams, controller?: AbortController): Promise<AxiosResponse<Page<OfferAggregation>>> {
    const signal = controller ? controller.signal : undefined
    const data = {
      params,
      signal,
      paramsSerializer: (params: OffersAggregationParams) => Qs.stringify(params, {arrayFormat: 'repeat', format: 'RFC1738'}),
    }
    return axiosInstance.get('offers/aggregation', data)
  }

  getOfferDetails(params: OfferDetailParams, controller?: AbortController): Promise<AxiosResponse<Offers>> {
    const signal = controller ? controller.signal : undefined
    const receipt = params.receipt
    const position = params.position
    delete params.receipt
    delete params.position
    const data = {
      params,
      signal,
    }

    return axiosInstance.get(`/offers/${receipt}-${position}/`, data)
  }

  getOfferOverview(params: OfferOverviewParams, controller?: AbortController): Promise<AxiosResponse<Page<Offers>>> {
    const signal = controller ? controller.signal : undefined

    const data = {
      params,
      signal,
    }

    return axiosInstance.get('/offers/', data)
  }
}

const module = new OfferModule()
export default module
