import type {Ref} from 'vue'
import {computed} from 'vue'
import {currencyFormatter} from '@/utils/formatter'
import type CustomerDetail from '@/types/api/customer-detail.type'

export default function useRevenueYear(customerDetails: Ref<CustomerDetail>, year: number) {
  return computed(() => {
    const revenues = customerDetails.value?.revenuePerYear
    let value = null
    if (revenues) {
      const revenue = revenues.find(el => el.year === year)
      if (revenue && revenue.revenueSum)
        value = currencyFormatter.format(revenue.revenueSum)
    }
    return value
  })
}
