<template>
  <k-card class="grid grid-cols-1">
    <template #title>
      <span class="card-head">
        <h2>{{ title }}</h2>
      </span>
    </template>
    <k-table
      v-if="totalOfferPositions > 0"
      class="flex-1"
      :head="positionTableHeads"
      :data="offerPositions"
      :total-rows="totalOfferPositions"
      :active-page="activePage"
      :default-sort-id="0"
      :data-loading="loadingOffers"
      async
      @reload="reload"
      @change-page="activePage = $event"
      @row-select="openDetailView"
    >
      <template #material-slot="{ content }">
        <k-attribute-display :value="content?.material?.materialDescription" />
      </template>
      <template #quantity-slot="{ content }">
        <k-attribute-display :value="getBaseQuantity(content)">
          <span class="whitespace-nowrap">
            {{ quantityFormatter.format(getBaseQuantity(content).quantity) }}
            <span>{{ unitFormatter(getBaseQuantity(content).unit) }}</span>
          </span>
        </k-attribute-display>
      </template>
    </k-table>
    <p
      v-else
      class="w-full text-center my-2"
    >
      keine weiteren Positionen vorhanden
    </p>
  </k-card>
</template>

<script lang="ts">
import {computed, defineComponent} from 'vue'
import {positionTableHeads} from '@/views/offer/offers-table-heads'
import useOfferList from '@/views/offer/use-offer-list'
import {quantityFormatter, unitFormatter} from '@/utils/formatter'
import {getBaseQuantity} from '@/utils/use-quantities'
import type Offer from '@/types/api/offer.type'

export default defineComponent({
  name: 'OfferPositionsCard',
  props: {
    offerReceipt: {
      type: Number,
      required: true,
    },
    currentPosition: {
      type: Number,
      required: false,
      default: undefined,
    },
  },
  setup(props) {
    const offerReceipt = computed(() => props.offerReceipt)

    const {
      offers,
      totalOffers,
      activePage,
      openDetailView,
      loadingOffers,
      reload,
    } = useOfferList(undefined, offerReceipt)

    const offerPositions = computed(() => {
      let positions = offers.value
      if (props.currentPosition) {
        positions = positions.filter((offer: Offer) => {
          return offer.position !== props.currentPosition
        })
      }
      return positions
    })

    const totalOfferPositions = computed(() => {
      const total = totalOffers.value
      if (props.currentPosition)
        return total - 1
      else
        return total
    })

    const title = computed(() => {
      if (props.currentPosition)
        return 'Andere Positionen'
      else
        return 'Positionen'
    })

    return {
      title,
      offerPositions,
      totalOfferPositions,
      loadingOffers,
      activePage,
      reload,
      openDetailView,
      positionTableHeads,

      quantityFormatter,
      unitFormatter,
      getBaseQuantity,
    }
  },
})

</script>

<style scoped>

</style>
