import axios from 'axios'
import applyCaseMiddleware from 'axios-case-converter'
import {TableQuery} from '@singularit/kurz-components'
import {handleDates, parseSorting} from '@/utils/parser'
import {useAuthStore} from '@/stores/auth'

const preserveRegex = /.*__[g,l]te?/
const preserveList = ['aggregationField', 'groupBy', 'orderBy']

const axiosInstance = applyCaseMiddleware(
  axios.create({
    withCredentials: false,
    timeout: 28000,
    baseURL: import.meta.env.VITE_APP_BACKEND_URL,
  }),
  {
    preservedKeys: (input) => {
      return preserveRegex.test(input) || preserveList.includes(input)
    },
  },
)

axiosInstance.interceptors.response.use((originalResponse) => {
  handleDates(originalResponse.data)
  return originalResponse
})

axiosInstance.interceptors.response.use(
  (response) => {
    return response
  },
  (err) => {
    if (err.response && err.response.status === 401) {
      const authStore = useAuthStore()
      authStore.logout()
    }
    else {
      throw err
    }
  },
)

axiosInstance.interceptors.request.use(
  (config) => {
    const authStore = useAuthStore()
    const token = authStore.token
    if (token && config.headers)
      config.headers.Authorization = `Token ${token}`

    return config
  },
  (err) => {
    return Promise.reject(err)
  },
)

axiosInstance.interceptors.request.use(
  (config) => {
    const params = config.params
    if ((config.method === 'post' || config.method === 'patch') && !(params instanceof FormData)) {
      const params = config.data
      delete config.data
      const form = new FormData()
      for (const key in params) {
        if (Array.isArray(params[key])) {
          params[key].forEach((el: any, index: number) => {
            form.append(`${key.toString()}[${index}]`, el.toString())
          })
        }
        else if (params[key]) {
          form.append(key, params[key])
        }
      }
      config.data = form
      return config
    }

    // maps objects to ids
    for (const key in params) {
      if (params[key] instanceof Object) {
        // check for table query
        const obj = params[key]
        const discriminator = obj.discriminator
        // check if the object is a tablequery
        if (discriminator && discriminator === TableQuery.discriminator) {
          delete params[key]
          const query = obj as TableQuery
          if (query.searchQuery) config.params.search = query.searchQuery
          if (query.orderEntry && !params.orderBy) config.params.ordering = parseSorting(query.orderEntry)
          if (query.filter) {
            for (const [key, value] of query.filter)
              config.params[key] = value
          }
        }
        else {
          if (obj.id)
            config.params[key] = obj.id
        }
      }
    }
    return config
  },
  (err) => {
    return Promise.reject(err)
  },
)
export default axiosInstance
