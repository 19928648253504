export default interface Right {
  name: RightName
  id: number
}

export const enum RightName {
  VK = 'dashboard-vk',
  VKL = 'dashboard-vkl',
  ADMIN = 'admin-panel',
  IMPORT = 'import-panel',
  USER = 'user',
  UI_FILTER = 'ui-filter',
}
