import {ObjectTableHead, SlotTableHead} from '@singularit/kurz-components'
import type Offer from '@/types/api/offer.type'
import {dateFormatter, tableCurrencyFormatter} from '@/utils/formatter'

const offerTableHeads = [
  new ObjectTableHead({
    title: 'Beleg',
    attribute: 'receipt',
    sortable: true,
    id: 0,
  }),
  new ObjectTableHead({
    title: 'Position',
    attribute: 'position',
    id: 1,
  }),

  new SlotTableHead({
    title: 'Kunde',
    sortable: true,
    id: 2,
    sortKey: 'customer__name',
    slotName: 'customer-slot',
  }),

  new SlotTableHead({
    title: 'Material',
    sortable: true,
    id: 3,
    sortKey: 'material__material_description',
    slotName: 'material-slot',
  }),

  new SlotTableHead({
    title: 'Gepl. Menge',
    // sortable: true, //TODO sorting
    slotName: 'quantity-slot',
    id: 4,
  }),

  new ObjectTableHead({
    title: 'Gepl. Umsatz',
    attribute: 'revenue',
    sortable: true,
    id: 5,
    formatter: tableCurrencyFormatter,
  }),

  new ObjectTableHead({
    title: 'Gültig Ab',
    attribute: 'start',
    sortable: true,
    id: 6,
    formatter: dateFormatter,
    alignment: 'right',
  }),

  new ObjectTableHead<Offer>({
    title: 'Gültig Bis',
    attribute: 'end',
    sortable: true,
    id: 7,
    formatter: dateFormatter,
    alignment: 'right',
  }),

  new SlotTableHead({
    title: 'Zugeordneter Auftrag',
    slotName: 'order-slot',
    sortKey: 'order__receipt',
    sortable: true,
    id: 8,
    alignment: 'right',
  }),

  new SlotTableHead({
    title: 'Zugeordneter Kontrakt',
    slotName: 'contract-slot',
    sortable: true,
    sortKey: 'contract__receipt',
    id: 9,
    alignment: 'right',
  }),

  new ObjectTableHead({
    title: 'Erstellungsdatum',
    attribute: 'creationDate',
    sortable: true,
    sortKey: 'creation_date',
    id: 10,
    formatter: dateFormatter,
    alignment: 'right',
  }),
]

const positionTableHeads = [
  new ObjectTableHead({
    title: 'Position',
    attribute: 'position',
    sortable: true,
    sortKey: 'position',
    id: 0,
    alignment: 'center',
  }),
  new SlotTableHead({
    title: 'Material',
    sortable: true,
    id: 1,
    sortKey: 'material__material_description',
    slotName: 'material-slot',
  }),
  new ObjectTableHead({
    title: 'Umsatz',
    attribute: 'revenue',
    sortable: true,
    id: 2,
    formatter: tableCurrencyFormatter,
  }),
  new SlotTableHead({
    title: 'Gepl. Menge',
    // sortable: true, //TODO sorting
    slotName: 'quantity-slot',
    id: 3,
  }),
]

export {offerTableHeads, positionTableHeads}
