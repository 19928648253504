import {defineStore} from 'pinia'
import type {UIFilter, UIFilterMap, ViewName} from '@/types/api/ui-filter.type'
import api from '@/api'
import type {UIFilterPostParams, UIFilterUpdateParams, UiFilterParams} from '@/api/modules/ui-filter'

interface State {
  userFilters: UIFilterMap
  uiFilters: UIFilter[]
}

export const useUiFilterStore = defineStore('ui-filter', {
  state: (): State => ({
    userFilters: {} as UIFilterMap,
    uiFilters: [] as UIFilter[],
  }),

  getters: {
    getUIFilter: (state) => {
      return (viewName: ViewName) => {
        return state.userFilters[viewName]
      }
    },
  },

  actions: {
    /**
         * load uiFilters
         * @param {UiFilterParams} config - the config for the call
         */
    async loadAllUiFilters(config: UiFilterParams) {
      return api.uiFilter.loadUiFilter(config)
        .then((data) => {
          const page = data.data
          this.uiFilters = page.results
        }).catch((error) => {
          console.error(error)
          throw error
        })
    },

    async loadUserUiFilters(config: UiFilterParams) {
      return api.uiFilter.loadUiFilter(config)
        .then((response) => {
          const data = response.data
          data.results.forEach((uiFilter) => {
            if (uiFilter.viewName) this.userFilters[uiFilter.viewName] = uiFilter
          })
          this.fillLocalStore()
        }).catch((error) => {
          console.error(error)
          throw error
        })
    },
    async postUifilter(config: UIFilterPostParams) {
      return api.uiFilter.postFilter(config).then((response) => {
        if (response.status === 200) {
          this.userFilters[config.viewName] = config
          this.fillLocalStore()
        }
        return response
      }).catch((error) => {
        console.error(error)
        throw error
      })
    },
    async updateUifilter(config: UIFilterUpdateParams) {
      return api.uiFilter.updateFilter(config).then((response) => {
        if (response.status === 200) {
          this.userFilters[config.viewName].filter = config.filter
          this.fillLocalStore()
        }
        return response
      }).catch((error) => {
        throw error
      })
    },

    fillLocalStore() {
      localStorage.setItem('ui-filter', String(JSON.stringify(this.userFilters)))
    },
    async loadLocalUIFilters() {
      const jsonFilters = localStorage.getItem('ui-filter')
      if (jsonFilters !== null) {
        let filters = JSON.parse(jsonFilters)
        if (filters === null)
          filters = {}

        this.userFilters = filters
      }
    },

    /**
         * resets the store to the initial state.
         */
    clearUiFilters() {
      this.$reset()
    },
  },
})
