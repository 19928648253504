<template>
  <k-card>
    <template #title>
      <span class="card-head">
        <h2>
          Feedbacks
        </h2>
      </span>
    </template>
    <div class="w-full flex flex-col">
      <k-table
        v-model:page="activePageFB"
        class="flex-1"
        :data="feedbacks"
        :head="feedbacksTableHeads"
        :total-rows="totalFeedbacks"
        :data-loading="loadingFeedbacks"
        async
        @reload="reload"
        @change-page="activePageFB = $event"
        @row-select="openFeedback"
      >
        <template #channel-slot="{ content }">
          <div class="whitespace-nowrap">
            {{ feedbackChannelString(content.channel) }}
          </div>
        </template>
        <template #contact-slot="{ content }">
          <div class="whitespace-nowrap">
            {{ content.firstName }} {{ content.lastName }}
          </div>
        </template>
        <template #result-slot="{ content }">
          <div class="whitespace-nowrap">
            {{ feedbackResultString(content.result) }}
          </div>
        </template>
        <template #creator-slot="{ content }">
          <div
            v-if="content.creator && content.creator.firstName && content.creator.lastName"
            class="whitespace-nowrap"
          >
            {{ content.creator?.firstName }} {{ content.creator?.lastName }}
          </div>
          <div
            v-else-if="content.creator && content.creator.username"
            class="whitespace-nowrap"
          >
            {{ content.creator?.username }}
          </div>
          <div
            v-else
            class="whitespace-nowrap"
          >
            -
          </div>
        </template>
      </k-table>
      <div class="flex flex-row-reverse">
        <!--              <router-link :to="{ name: 'feedback', params: { customerId } }">-->
        <k-kurz-button
          class="align-end mr-10 mb-5 mt-1"
          text="Neues Feedback abgeben"
          @clicked="openCreateFeedback"
        />
        <!--              </router-link>-->
      </div>
      <k-modal
        :modal-active="showFeedbackModal"
        @close="closeFeedback"
      >
        <template #head>
          <h1 class="page-head">
            {{ feedbackHead }}
          </h1>
        </template>
        <FeedbackDetailView
          :customer="customer"
          :feedback="selectedFeedback"
          :create="createFeedback"
          @save="feedbackSave"
        />
      </k-modal>
    </div>
  </k-card>
</template>

<script setup lang="ts">
import {computed, ref, toRef} from 'vue'
import type Customer from '@/types/api/customer.type'
import {feedbacksTableHeads} from '@/views/feedback/feedback-table-heads'
import useFeedbackList from '@/views/feedback/use-feedback-list'
import {feedbackChannelString, feedbackResultString} from '@/views/feedback/feedback-mapping'
import FeedbackDetailView from '@/views/feedback/FeedbackDetailView.vue'
import type Feedback from '@/types/api/feedback.type'

interface Props {
  customer: Customer
}

const props = defineProps<Props>()
const customer = toRef(props, 'customer')

const {
  feedbacks,
  totalFeedbacks,
  activePage: activePageFB,
  loadingFeedbacks,
  loadFeedbacks,
  reload,
} = useFeedbackList(customer)
const showFeedbackModal = ref(false)
const createFeedback = ref(false)
const selectedFeedback = ref()

function openCreateFeedback() {
  createFeedback.value = true
  showFeedbackModal.value = true
}

function openFeedback(data: Feedback) {
  createFeedback.value = false
  showFeedbackModal.value = true
  selectedFeedback.value = data
}

function closeFeedback() {
  showFeedbackModal.value = false
  selectedFeedback.value = undefined
}

function feedbackSave() {
  showFeedbackModal.value = false
  loadFeedbacks()
}

const feedbackHead = computed(() => {
  let head = 'Neues Feedback anlegen'
  if (!createFeedback.value && customer && customer.value.name)
    head = customer.value.name

  return head
})

</script>
