import type {AxiosRequestConfig, AxiosResponse} from 'axios'
import Qs from 'qs'
import type {TableQuery} from '@singularit/kurz-components'
import axiosInstance from '@/api/axios'
import type Page from '@/types/api/page.type'
import type Customer from '@/types/api/customer.type'
import type PaginationListParameters from '@/api/modules/pagination-list-parameters'
import BaseModule from '@/api/modules/base-module'
import type CustomerDetail from '@/types/api/customer-detail.type'
import type HierarchyOverview from '@/types/api/hierarchy-overview.type'
import type QueryParameters from '@/api/modules/query-parameters'
import type Hierarchy from '@/types/api/hierarchy.type'
import type IncludeExcludeParameters from '@/api/modules/include-exclude-parameters'

export interface CustomerListParams extends PaginationListParameters, QueryParameters, IncludeExcludeParameters {
  archived?: boolean
  partners?: number
}

export interface CustomerListQLParams extends PaginationListParameters, IncludeExcludeParameters {
  archived?: boolean
  partners?: number
  query?: string // TODO @Merlin improve
  tableQuery?: TableQuery
}

export interface CustomerParams {
  id?: number
}

interface CustomerDetailHierarchyParams {
  hierarchy1?: Hierarchy
  hierarchy2?: Hierarchy
  hierarchy3?: Hierarchy
}

export interface CustomerDetailParams extends CustomerParams, CustomerDetailHierarchyParams {
  revenue_years?: number[]
}

export interface CustomerHierarchyOverviewParams extends CustomerParams, CustomerListParams, CustomerDetailHierarchyParams {
}

class CustomerModule extends BaseModule {
  getCustomers(params: CustomerListParams | CustomerListQLParams, controller?: AbortController): Promise<AxiosResponse<Page<Customer>>> {
    const signal = controller ? controller.signal : undefined
    const data = {
      params,
      signal,
      paramsSerializer: (params: CustomerListParams | CustomerListQLParams) => Qs.stringify(params, {arrayFormat: 'comma'}),
    }
    return axiosInstance.get('/customers/', data)
  }

  getCustomer(params: CustomerParams, controller?: AbortController): Promise<AxiosResponse<Customer>> {
    const signal = controller ? controller.signal : undefined
    const id = params.id
    delete params.id
    const data: AxiosRequestConfig = {
      params,
      signal,
    }
    return axiosInstance.get(`/customers/${id}/`, data)
  }

  getCustomerDetails(params: CustomerDetailParams, controller?: AbortController): Promise<AxiosResponse<CustomerDetail>> {
    const signal = controller ? controller.signal : undefined
    const id = params.id
    delete params.id
    const data: AxiosRequestConfig = {
      params,
      signal,
      paramsSerializer: params => Qs.stringify(params, {arrayFormat: 'repeat'}),
    }
    return axiosInstance.get(`/customers/${id}/details/`, data)
  }

  getCustomerHierarchyOverviews(params: CustomerHierarchyOverviewParams, controller?: AbortController): Promise<AxiosResponse<Array<HierarchyOverview>>> {
    const signal = controller ? controller.signal : undefined
    const id = params.id
    delete params.id
    const data: AxiosRequestConfig = {
      params,
      signal,
      paramsSerializer: params => Qs.stringify(params, {arrayFormat: 'repeat'}),
    }
    return axiosInstance.get(`/customers/${id}/hierarchy-overview/`, data)
  }

  getAllCustomerIds(params: null, controller?: AbortController): Promise<AxiosResponse<Array<number>>> {
    const signal = controller ? controller.signal : undefined
    const data: AxiosRequestConfig = {
      signal,
    }
    return axiosInstance.get('/customers/ids/', data)
  }
}

const module = new CustomerModule()
export default module
