import {ObjectTableHead, SlotTableHead} from '@singularit/kurz-components'
import {dateFormatter, tableCurrencyFormatter} from '@/utils/formatter'
import type LowPerformer from '@/types/api/dashboard/low-performer.type'
import {lowPerformersTableHeads} from '@/views/dashboard/vkl-dashboard/dashboard-table-heads'

const offerTableHeadVK = [
  new SlotTableHead({
    title: 'Enddatum',
    sortable: true,
    id: 0,
    alignment: 'right',
    sortKey: 'end',
    slotName: 'date-slot',
  }),
  new ObjectTableHead({
    title: 'Beleg',
    attribute: 'receipt',
    sortable: true,
    id: 1,
  }),
  new SlotTableHead({
    title: 'Kunde',
    sortable: true,
    id: 2,
    sortKey: 'customer__name',
    slotName: 'customer-slot',
  }),
  new ObjectTableHead({
    title: 'Umfang',
    attribute: 'value',
    sortable: true,
    id: 3,
    formatter: tableCurrencyFormatter,
  }),
]

const churnTableHeadVK = [
  new SlotTableHead({
    title: 'Kunde',
    slotName: 'customer-slot',
    sortKey: 'customer',
    sortable: false,
    id: 0,
  }),
  new ObjectTableHead({
    title: 'Datum der Markierung',
    attribute: 'date',
    sortable: true,
    id: 1,
    formatter: dateFormatter,
    alignment: 'right',
  }),
  new ObjectTableHead({
    title: 'Wahrscheinlichkeit',
    attribute: 'likelihood',
    sortable: true,
    formatter: (a: number) => `${a * 100} %`,
    id: 2,
  }),
]

const lpTableHeadVK: (SlotTableHead | ObjectTableHead<LowPerformer>)[] = [
  ...lowPerformersTableHeads,
  new ObjectTableHead<LowPerformer>({
    title: 'Umsatz',
    attribute: 'revenueSum',
    sortKey: 'revenue_sum',
    sortable: true,
    id: 1,
    formatter: tableCurrencyFormatter,
    alignment: 'right',
  }),
]
export {offerTableHeadVK, churnTableHeadVK, lpTableHeadVK}
