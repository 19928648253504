<template>
  <div class="flex flex-col">
    <div class="flex">
      <div class="flex justify-center items-center gap-5">
        <h1 class="page-head uppercase">
          Trend-Analyse {{ customer?.name }}, {{ customer?.id }} ({{ customer?.countryCode }})
        </h1>
        <k-tooltip
          :text="infoText"
        />
      </div>
      <ImportTimestamp
        class="items-center mr-4 ml-auto"
        dependant-on="customers"
      />
    </div>
    <div class="flex justify-evenly flex-wrap">
      <TrendCard
        v-for="(config, index) in trendCardsConfigs"
        :key="index"
        class="trend-card"
        :title="config.title"
        :months="config.months"
        :customer="customer"
      />
    </div>
    <div class="flex">
      <PurchaseFrequencyCard
        title="Kauffrequenz"
        :timespan="'month'"
        :customer="customer"
      />
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
import useCustomer from '@/views/customer/customer-detail/use-customer'
import TrendCard from '@/components/cards/TrendCard.vue'
import useInvoiceTrendGraph from '@/views/trend/use-invoice-trend-graph'
import ImportTimestamp from '@/components/timestamp/ImportTimestamp.vue'
import PurchaseFrequencyCard from '@/components/cards/PurchaseFrequencyCard.vue'

export default defineComponent({
  name: 'CustomerTrendView',
  components: {PurchaseFrequencyCard, ImportTimestamp, TrendCard},
  props: {
    customerId: {
      type: Number,
      required: true,
    },

  },

  setup(props) {
    // const router = useRouter()

    const customer = useCustomer(props.customerId)

    const {graphData} = useInvoiceTrendGraph(customer)

    const infoText = `Der Trend wird nach der Steigung der Regression bewertet:
          Gut: < 0.05
          Neutral: < -0.05
          Schlecht: > -0.05
      `

    const trendCardsConfigs = [
      {title: 'Kurzfristiger Trend (3 Kaufmonate)', months: 3},
      {title: '6 Monats-Trend (6 Kaufmonate)', months: 6},
      {title: '12 Monats-Trend (12 Kaufmonate)', months: 12},
      {title: '24 Monats-Trend (24 Kaufmonate)', months: 24},
      {title: 'Gesamt-Trend', months: undefined},
    ]

    return {
      customer,
      graphData,
      trendCardsConfigs,
      infoText,
    }
  },
})
</script>
<style scoped>
.trend-card{
  flex: 1 1 49%;
}
</style>
