import type {Ref} from 'vue'
import {computed} from 'vue'
import {currencyFormatter, numberFormatter} from '@/utils/formatter'

export function useComputedNumber<T>(object: Ref<T | undefined>, attribute: keyof T) {
  return computed(() => {
    let result = null
    if (object.value && object.value[attribute] && typeof object.value[attribute] === 'number') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      const value = object.value[attribute as string]
      result = numberFormatter.format(value)
    }
    return result
  })
}

export function useComputedCurrency<T>(object: Ref<T | undefined>, attribute: keyof T) {
  return computed(() => {
    let result = null
    if (object.value && object.value[attribute] && typeof object.value[attribute] === 'number') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      const value = object.value[attribute as string]
      result = currencyFormatter.format(value)
    }
    return result
  })
}

export function useComputedDate<T>(
  object: Ref<T>,
  attribute: keyof T,
  formatOptions: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  },
) {
  return computed(() => {
    let result = null
    if (object.value && object.value[attribute] && object.value[attribute] instanceof Date) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      const value = object.value[attribute as string]
      result = value.toLocaleDateString('de-DE', formatOptions)
    }
    return result
  })
}
