import type {AxiosResponse} from 'axios'
import Qs from 'qs'
import axiosInstance from '@/api/axios'
import BaseModule from '@/api/modules/base-module'
import type QueryParameters from '@/api/modules/query-parameters'
import type Customer from '@/types/api/customer.type'
import type Invoice from '@/types/api/invoice.type'
import type Page from '@/types/api/page.type'
import type GraphData from '@/types/api/chart/graph.type'
import type {DashboardParams} from '@/api/modules/dashboards'
import type {AggregationParams} from '@/api/params/aggregation.type'
import type {InvoiceAggregation} from '@/types/api/aggregation.type'

export interface InvoicesListParams extends DashboardParams, QueryParameters {
  customer?: Customer
  invoice_date__gte?: Date | string
  invoice_date__lte?: Date | string
  invoice_date?: Date | string
}

export type InvoiceAggregationParams = InvoicesListParams & AggregationParams

export interface InvoiceGraphParams {
  customer?: Customer
  acuteness?: string
  beginning?: Date
  end?: Date
}

class InvoiceModule extends BaseModule {
  getInvoices(params: InvoicesListParams, controller?: AbortController): Promise<AxiosResponse<Page<Invoice>>> {
    const signal = controller ? controller.signal : undefined
    const data = {
      params,
      signal,
      paramsSerializer: (params: InvoicesListParams) => Qs.stringify(params, {arrayFormat: 'repeat'}),
    }
    return axiosInstance.get('invoices/', data)
  }

  getInvoiceAggregations(params: InvoiceAggregationParams, controller?: AbortController): Promise<AxiosResponse<Page<InvoiceAggregation>>> {
    const signal = controller ? controller.signal : undefined
    const data = {
      params,
      signal,
      paramsSerializer: (params: InvoicesListParams) => Qs.stringify(params, {arrayFormat: 'repeat', format: 'RFC1738'}),
    }
    return axiosInstance.get('invoices/aggregation', data)
  }

  getInvoiceGraph(params: InvoiceGraphParams, controller?: AbortController): Promise<AxiosResponse<GraphData>> {
    const signal = controller ? controller.signal : undefined
    const customer = params.customer
    delete params.customer
    const data = {
      params,
      signal,
    }
    return axiosInstance.get(`invoices/chart/${customer?.id}`, data)
  }
}

const module = new InvoiceModule()
export default module
