import {defineStore} from 'pinia'
import api from '@/api'
import type {InvoiceAggregationParams, InvoiceGraphParams, InvoicesListParams} from '@/api/modules/invoices'
import {PaginatedData, RequestData} from '@/stores/index'
import type Invoice from '@/types/api/invoice.type'
import GraphData from '@/types/api/chart/graph.type'
import type {InvoiceAggregation} from '@/types/api/aggregation.type'

interface State {
  invoiceGraph: RequestData<GraphData>
  invoices: PaginatedData<Invoice>
  aggregates: PaginatedData<InvoiceAggregation>
}

export const useInvoiceStore = defineStore('invoice', {
  // see https://pinia.vuejs.org/core-concepts/state.html
  state: (): State => ({
    // define initial values
    invoiceGraph: new RequestData<GraphData>(new GraphData()),
    invoices: new PaginatedData<Invoice>(),
    aggregates: new PaginatedData<InvoiceAggregation>(),
  }),
  // see https://pinia.vuejs.org/core-concepts/getters.html
  getters: {
    // define getters to access compound or calculated data
  },
  // see https://pinia.vuejs.org/core-concepts/actions.html
  actions: {

    /**
         * load invoices
         * @param {InvoicesListParams}config - the config for the call
         * @param cancel
         * @param requestID
         */
    async loadInvoices(config: InvoicesListParams, cancel = true, requestID?: string) {
      const id = requestID || api.invoices.getInvoices.name
      if (cancel)
        api.invoices.cancel(id)
      this.invoices.pending = true
      return api.invoices.cancelableCall(api.invoices.getInvoices, config, id)
        .then((data) => {
          const page = data.data
          this.invoices.data = page.results
          this.invoices.total = page.count
          if (!api.invoices.hasPending(id))
            this.invoices.pending = false
        }).catch((error) => {
          if (!api.invoices.hasPending(id))
            this.invoices.pending = false
          throw error
        })
    },

    /**
         * load invoices
         * @param {InvoiceAggregationParams}config - the config for the call
         * @param cancel
         * @param requestID
         */
    async loadInvoiceAggregate(config: InvoiceAggregationParams, cancel = true, requestID?: string) {
      const id = requestID || api.invoices.getInvoiceAggregations.name
      if (cancel)
        api.invoices.cancel(id)
      this.aggregates.pending = true
      return api.invoices.cancelableCall(api.invoices.getInvoiceAggregations, config, id)
        .then((data) => {
          const page = data.data
          this.aggregates.data = page.results
          this.aggregates.total = page.count
          if (!api.invoices.hasPending(id))
            this.aggregates.pending = false
        }).catch((error) => {
          if (!api.invoices.hasPending(id))
            this.aggregates.pending = false
          throw error
        })
    },

    /**
         * load invoice graph
         * @param {InvoiceGraphParams}config - the config for the call
         * @param cancel
         * @param requestID
         */
    async loadInvoiceGraph(config: InvoiceGraphParams, cancel = true, requestID?: string) {
      const id = requestID || api.invoices.getInvoiceGraph.name
      if (cancel)
        api.invoices.cancel(id)
      this.invoiceGraph.pending = true
      return api.invoices.cancelableCall(api.invoices.getInvoiceGraph, config, id)
        .then((data) => {
          this.invoiceGraph.data = data.data
          if (!api.invoices.hasPending(id))
            this.invoiceGraph.pending = false
        }).catch((error) => {
          if (!api.invoices.hasPending(id))
            this.invoiceGraph.pending = false
          throw error
        })
    },

    /**
         * resets the store to the initial state.
         */
    clearInvoices() {
      this.$reset()
    },
  },
})
