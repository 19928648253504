<template>
  <div class="flex flex-col">
    <div class="flex">
      <h1 class="page-head uppercase">
        Abgesprungene Kunden: {{ showDate }}
      </h1>
      <ImportTimestamp
        class="items-center mr-4 ml-auto"
        dependant-on="invoices"
      />
    </div>
    <div class="grid grid-cols-2 mb-10">
      <div class="col-span-2 lg:col-span-1">
        <k-card>
          <template #title>
            <span class="card-head">
              <h2>Allgemeine Informationen</h2>
            </span>
          </template>
          <div
            v-if="loadingData"
            class="h-32 w-full flex justify-center"
          >
            <k-kurz-dot-loader class="self-center" />
          </div>
          <k-list-layout class="w-full">
            <k-list-entry
              label="abgesprungene Kunden"
              value="0"
              info="Abgesprungene Kunden"
            />
            <k-list-entry
              label="Einmalkunden"
              value="0"
            />
          </k-list-layout>
        </k-card>
        <k-card>
          <template #title>
            <span class="card-head">
              <h2>Kunde</h2>
            </span>
          </template>
          <div class="w-full">
            <k-table
              v-if="churns.length > 0"
              v-model:page="activePage"
              :head="customerTableHead"
              :data="churns"
              :total-rows="totalChurns"
              :data-loading="loadingData"
              async
              @reload="reload"
              @change-page="activePage = $event"
            />
            <div
              v-else
              class="text-center"
            >
              Keine Daten vorhanden
            </div>
          </div>
        </k-card>
      </div>
      <div class="col-span-2 lg:col-span-1">
        <k-card>
          <div class="w-full">
            <k-table
              v-if="churns.length > 0"
              :head="churnMonthlyTableHeads"
              :data="churns"
              :total-rows="totalChurns"
              :active-page="activePage"
              :data-loading="loadingData"
              async
              searchable
              @reload="reload"
              @change-page="activePage = $event"
            />
            <div
              v-else
              class="text-center"
            >
              Keine Daten vorhanden
            </div>
          </div>
        </k-card>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import {computed, defineComponent} from 'vue'
import {churnMonthlyTableHeads, customerTableHead} from '@/views/churn/churn-table-heads'
import ImportTimestamp from '@/components/timestamp/ImportTimestamp.vue'
import useMonthlyChurnList from '@/views/churn/use-monthly-churn'

export default defineComponent({
  name: 'ChurnMonthList',
  components: {ImportTimestamp},
  props: {
    month: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const date = computed(() => new Date(`${props.month}-01`))
    const showDate = computed(() => (date.value.toLocaleDateString('de-DE', {year: 'numeric', month: 'long'})))

    const {
      churns,
      totalChurns,
      activePage,
      loadingMonthlyChurns: loadingData,
      reload,
    } = useMonthlyChurnList(props.month)

    return {
      churns,
      totalChurns,
      showDate,
      churnMonthlyTableHeads,
      customerTableHead,
      activePage,
      reload,
      loadingData,
    }
  },
})
</script>

<style scoped></style>
