import useUrlParam from '@/utils/behaviours/use-url-param'
import type {Month} from '@/types/api/trend.type'

export default function useMonth() {
  function watchedMonthReverse(paramString: string | string[]) {
    let watchedString = ''
    if (Array.isArray(paramString))
      watchedString = paramString[0]

    else
      watchedString = paramString

    const watchedStringArray = watchedString.split('-')
    return {
      month: Number(watchedStringArray[0]) - 1,
      year: Number(watchedStringArray[1]),
    }
  }

  const watchedMonthDefault: Month = {
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
  }

  const watchedMonth = useUrlParam(
    'month',
    (month: Month) => {
      return `${month.month + 1}-${month.year}`
    },
    watchedMonthReverse,
    watchedMonthDefault,
  )

  const monthPickerFormat = (month: Month) => {
    const date = new Date()
    date.setMonth(month.month)
    date.setFullYear(month.year)
    return date.toLocaleString('de-DE', {month: 'long', year: 'numeric'})
  }

  function prevMonth() {
    watchedMonth.value = {
      month: watchedMonth.value.month === 0 ? 11 : watchedMonth.value.month - 1,
      year: watchedMonth.value.month === 0 ? watchedMonth.value.year - 1 : watchedMonth.value.year,
    }
  }

  function nextMonth() {
    watchedMonth.value = {
      month: watchedMonth.value.month === 11 ? 0 : watchedMonth.value.month + 1,
      year: watchedMonth.value.month === 11 ? watchedMonth.value.year + 1 : watchedMonth.value.year,
    }
  }

  return {
    watchedMonth,
    monthPickerFormat,
    prevMonth,
    nextMonth,
  }
}
