<template>
  <k-card>
    <template #title>
      <span class="card-head">
        <h2>
          Aktive Angebote
        </h2>
      </span>
    </template>
    <k-table
      v-model:page="activePage"
      class="flex-1"
      :head="customerOfferTableHeads"
      :data="offers"
      :total-rows="totalOffers"
      :data-loading="loadingOffers"
      async
      @change-page="activePage = $event"
      @row-select="openDetailView"
      @reload="reload"
    >
      <template #material-slot="{ content }">
        <k-attribute-display
          class="whitespace-nowrap"
          :value="content?.material?.materialDescription"
        />
      </template>
      <template #quantity-slot="{ content }">
        <k-attribute-display :value="getBaseQuantity(content)">
          <span class="whitespace-nowrap">
            {{ quantityFormatter.format(getBaseQuantity(content).quantity) }}
            <span>{{ unitFormatter(getBaseQuantity(content).unit) }}</span>
          </span>
        </k-attribute-display>
      </template>
      <template #order-slot="{content}">
        <AttributeDisplay
          v-if="content && content.order"
          :value="content?.order.id"
        />
      </template>
    </k-table>
  </k-card>
</template>

<script setup lang="ts">
import {toRef} from 'vue'
import type Customer from '@/types/api/customer.type'
import useOfferList from '@/views/offer/use-offer-list'
import {offerTableHeads} from '@/views/offer/offers-table-heads'
import {quantityFormatter, unitFormatter} from '@/utils/formatter'
import {getBaseQuantity} from '@/utils/use-quantities'

interface Props {
  customer: Customer
}

const props = defineProps<Props>()
const customer = toRef(props, 'customer')
const customerOfferTableHeads = offerTableHeads.filter(a => (a.title !== 'Kunde')) // TODO

const {offers, totalOffers, activePage, loadingOffers, openDetailView, reload} = useOfferList(customer)

</script>
