import type {ChartType, ChartTypeRegistry} from 'chart.js'
import GraphData from '@/types/api/chart/graph.type'
import type GraphCoordinate from '@/types/api/chart/graph-coordinate.type'

export function mapGraph(data: GraphData) {
  const split = new Map()
  data.values.forEach((coordinate) => {
    const x = coordinate.x
    if (x instanceof Date) {
      const year = x.toLocaleDateString('DE-de', {year: 'numeric'})
      let entry = split.get(year)
      if (!entry) {
        entry = new GraphData()
        entry.ordinate = data.ordinate
        entry.abscissa = data.abscissa
      }
      entry.values.push(coordinate)
      split.set(year, entry)
    }
  })
  return split
}

export function mapGraphValues<TType extends ChartType = 'line'>(data?: GraphData): ChartTypeRegistry[TType]['defaultDataPoint'][] {
  let list: ChartTypeRegistry[TType]['defaultDataPoint'][] = []
  if (data && data.values) {
    list = data.values.map((value) => {
      if (value.y instanceof Date)
        return value.y.getTime()

      else if (typeof value.y === 'string')
        return null

      else
        return value.y
    })
  }
  return list
}

export function dateLabelFormatFunction(config: Record<string, string>, list: (string | number | Date)[]) {
  return (value: GraphCoordinate) => {
    let label = value.x
    if (value.x instanceof Date)
      label = value.x.toLocaleDateString('DE-de', config)

    list.push(label)
  }
}

export function mapGraphLabels(data?: GraphData) {
  const labels: (string | number | Date)[] = []
  if (data && data.values) {
    let func = (value: GraphCoordinate) => { labels.push(value.x) }
    if (data.ordinate) {
      if (data.ordinate === 'day')
        func = dateLabelFormatFunction({day: 'numeric'}, labels)

      else if (data.ordinate === 'month')
        func = dateLabelFormatFunction({year: 'numeric', month: 'long'}, labels)

      else if (data.ordinate === 'year')
        func = dateLabelFormatFunction({year: 'numeric'}, labels)
    }
    data.values.forEach(func)
  }
  return labels
}
