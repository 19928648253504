import type {AxiosResponse} from 'axios'
import type PaginationListParameters from '@/api/modules/pagination-list-parameters'
import type QueryParameters from '@/api/modules/query-parameters'
import BaseModule from '@/api/modules/base-module'
import type Branch from '@/types/api/branch.type'
import type Page from '@/types/api/page.type'
import axiosInstance from '@/api/axios'

export interface BranchListParams extends PaginationListParameters, QueryParameters {}

class BranchModule extends BaseModule {
  getBranches(params: BranchListParams, controller?: AbortController): Promise<AxiosResponse<Page<Branch>>> {
    const signal = controller ? controller.signal : undefined
    const data = {
      params,
      signal,
    }
    return axiosInstance.get('/branches/', data)
  }
}

const module = new BranchModule()
export default module
