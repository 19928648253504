import type {Ref} from 'vue'
import {computed, ref, unref, watchEffect} from 'vue'
import type Customer from '@/types/api/customer.type'
import type {ContractListParams} from '@/api/modules/contracts'
import {useContractStore} from '@/stores/contract'
import useTableQuery from '@/utils/use-table-query'

export default function useContractList(customerFilter?: Ref<Customer> | Customer, inactive?: Ref<boolean> | boolean) {
  const store = useContractStore()

  const activePage = ref(1)

  const {query, reload} = useTableQuery()

  // initial loading
  const config = computed(() => {
    const config: ContractListParams = {
      page: unref(activePage),
      is_active: !unref(inactive),
      customer: unref(customerFilter),
      query: unref(query),
    }
    return config
  })

  watchEffect(() => {
    if (inactive)
      store.loadInactiveContracts(config.value)
    else
      store.loadContracts(config.value)
  })

  const loadingContracts = computed(() => {
    if (inactive)
      return store.inactiveContracts.pending

    else
      return store.contracts.pending
  })

  const contracts = computed(() => {
    if (inactive)
      return store.inactiveContracts.data

    else
      return store.contracts.data
  })
  const totalContracts = computed(() => {
    if (inactive)
      return store.inactiveContracts.total

    else
      return store.contracts.total
  })

  return {contracts, totalContracts, activePage, loadingContracts, reload}
}
