import type {AxiosResponse} from 'axios'
import axiosInstance from '@/api/axios'
import BaseModule from '@/api/modules/base-module'
import type {AuthInfo} from '@/types/api/auth/auth-info.type'

export interface LoginParams {
  username: string
  password: string
}

export interface RegisterParams extends LoginParams {
  firstName: string
  lastName: string
  email: string
}

class AuthModule extends BaseModule {
  login(params: LoginParams): Promise<AxiosResponse<AuthInfo>> {
    return axiosInstance.post('/auth/user/login', params)
  }

  logout(): Promise<AxiosResponse> {
    return axiosInstance.post('/auth/user/logout')
  }

  register(user: RegisterParams): Promise<AxiosResponse> {
    return axiosInstance.post('/auth/user/register', user)
  }
}

const module = new AuthModule()
export default module
