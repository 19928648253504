<template>
  <div class="flex flex-col">
    <div class="flex flex-row gap-3 items-right mt-2">
      <p>heutiges Datum: {{ todaysDate.dateString }}</p>
      <p>Datum des letzten Imports: {{ dateLatestImport.dateString }}</p>
      <k-tooltip
        v-if="dependantOn && dateLatestSpecificImport && (dateLatestImport.dateString !== dateLatestSpecificImport.dateString)"
        class="self-center"
        :text="'Der Datensatz, von dem diese Ansicht abhängig ist, wurde zuletzt am ' + dateLatestSpecificImport.dateString + ' importiert'"
        slotted
        position="bottom"
      >
        <template #tooltip-hover>
          <font-awesome-icon
            icon="exclamation-circle"
            class="text-blue-kurz-900"
          />
        </template>
      </k-tooltip>
    </div>
    <p
      v-if="warn"
      class="text-red-400 "
    >
      !! Der letzte Datenimport hat vor mehr als 2 Tagen stattgefunden !!
    </p>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent} from 'vue'
import {isBefore, subDays} from 'date-fns'
import {getTodaysDate, useLatestTimestamp} from '@/utils/behaviours/use-data-import-timestamps'

export default defineComponent({
  name: 'ImportTimestamp',
  props: {
    dependantOn: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const todaysDate = getTodaysDate()
    const {
      latestTimestamp: dateLatestImport,
      latestSpecificImport: dateLatestSpecificImport,
    } = useLatestTimestamp(props.dependantOn as string)

    const warn = computed(() => {
      return isBefore(dateLatestImport.value.date, subDays(todaysDate.value.date, 2))
    })

    return {
      todaysDate,
      dateLatestImport,
      dateLatestSpecificImport,
      warn,
    }
  },
})
</script>

<style scoped>

</style>
