export default class GraphCoordinate {
  x: string | number | Date = 0
  y: string | number | Date = 0
}

export function graphCoordinateComparator(a: GraphCoordinate, b: GraphCoordinate) {
  if (typeof a.x === 'number' && typeof b.x === 'number')
    return a.x - b.x

  else if (a.x instanceof Date && b.x instanceof Date)
    return a.x.getTime() - b.x.getTime()

  else
    return 0
}
