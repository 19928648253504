import type {Ref} from 'vue'
import {computed, ref, unref, watchEffect} from 'vue'
import type Customer from '@/types/api/customer.type'
import type {FeedbackListParams} from '@/api/modules/feedbacks'
import {useFeedbackStore} from '@/stores/feedback'
import useTableQuery from '@/utils/use-table-query'

export default function useFeedbackList(customerFilter: Ref<Customer>) {
  const store = useFeedbackStore()

  const activePage = ref(1)

  const {query, reload} = useTableQuery()

  const config = computed(() => {
    const config: FeedbackListParams = {
      page: unref(activePage),
      customer: unref(customerFilter),
      query: unref(query),
    }
    return config
  })

  watchEffect(() => {
    store.loadFeedbacks(config.value)
  })

  const loadFeedbacks = () => store.loadFeedbacks(config.value)

  const loadingFeedbacks = computed(() => store.feedbacks.pending)

  // const feedbacks = computed(() => store.getters.getFeedbacks)
  const feedbacks = computed(() => store.feedbacks.data)

  const totalFeedbacks = computed(() => {
    return store.feedbacks.total
  })

  return {feedbacks, totalFeedbacks, activePage, loadingFeedbacks, loadFeedbacks, reload}
}
