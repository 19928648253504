import {computed} from 'vue'
import type Hierarchy from '@/types/api/hierarchy.type'
import {useHierarchyStore} from '@/stores/hierarchy'

export default function useAllHierarchies() {
  const store = useHierarchyStore()

  const config = {
    page: 1,
    pagesize: 1000,
  }
  store.loadHierarchies(config)

  const hierarchiesLoading = computed(() => {
    return store.hierarchies.pending
  })

  const hierarchies = computed(() => {
    return store.hierarchies.data as Hierarchy[]
  })

  return {
    hierarchiesLoading,
    hierarchies,
  }
}
