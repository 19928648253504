<template>
  <div
    class="flex justify-center pt-32"
  >
    <k-card
      :header="false"
      class="lg:w-1/4 lg:h-1/3 px-4"
    >
      <div class="flex-1">
        <div class="flex-auto px-4 lg:px-10 mt-10">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-gray-700 text-xs font-bold mb-2">Username</label><input
              v-model="loginDetails.username"
              :class="{'ring ring-red-400': highlightUsername}"
              class="
                border-0
                px-3
                py-3
                placeholder-gray-400
                text-gray-700
                bg-white
                rounded
                text-sm
                shadow
                focus:outline-none focus:ring
                w-full
              "
              placeholder="Username"
              style="transition: all 0.15s ease 0s"
              @input="highlightUsername = false"
            >
          </div>
          <div class="relative w-full">
            <label class="block uppercase text-gray-700 text-xs font-bold mb-2">Password</label><input
              v-model="loginDetails.password"
              :class="{'ring ring-red-400': highlightPassword}"
              class="
                border-0
                px-3
                py-3
                placeholder-gray-400
                text-gray-700
                bg-white
                rounded
                text-sm
                shadow
                focus:outline-none focus:ring
                w-full
              "
              placeholder="Password"
              style="transition: all 0.15s ease 0s"
              type="password"
              @input="highlightPassword = false"
            >
          </div>
          <!--          <div>-->
          <!--            <label class="inline-flex items-center cursor-pointer"-->
          <!--            ><input-->
          <!--                id="customCheckLogin"-->
          <!--                type="checkbox"-->
          <!--                class="form-checkbox border-0 rounded text-gray-800 ml-1 w-5 h-5"-->
          <!--                style="transition: all 0.15s ease 0s;"-->
          <!--            /><span class="ml-2 text-sm font-semibold text-gray-700"-->
          <!--            >Remember me</span-->
          <!--            ></label-->
          <!--            >-->
          <!--          </div>-->
          <div class="text-center mt-6">
            <k-kurz-button
              text="Login"
              @clicked="login()"
            />
          </div>
        </div>
        <div class="text-center mt-4">
          <router-link
            class="text-gray-300"
            to=""
            @click="() => $emit('redirect')"
          >
            <small>Create new account</small>
          </router-link>
        </div>
      </div>
    </k-card>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, ref} from 'vue'
import type {AxiosError} from 'axios'
import {useAuthStore} from '@/stores/auth'
import type {LoginParams} from '@/api/modules/auth'
import highlightError from '@/utils/highlight-auth-errors'

export default defineComponent({
  emits: ['redirect'],
  setup() {
    const store = useAuthStore()
    const loginDetails = ref<LoginParams>({
      username: 'supervisor',
      password: 'secret',
    })

    const authenticated = computed(() => store.isAuthenticated)

    const highlightUsername = ref(false)
    const highlightPassword = ref(false)

    function login() {
      store.login(loginDetails.value).catch((error: AxiosError) => {
        highlightError(error, highlightUsername, highlightPassword)
      })
    }

    function handleEnter(keyup: KeyboardEvent) {
      if (keyup.key === 'Enter')
        login()
    }

    return {loginDetails, authenticated, login, handleEnter, highlightPassword, highlightUsername}
  },
  mounted() {
    window.addEventListener('keyup', this.handleEnter)
  },
  unmounted() {
    window.removeEventListener('keyup', this.handleEnter)
  },
})
</script>

<style scoped>

</style>
