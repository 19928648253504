import {defineStore} from 'pinia'
import type Customer from '@/types/api/customer.type'
import api from '@/api'
import type CustomerDetail from '@/types/api/customer-detail.type'
import type {
  CustomerDetailParams,
  CustomerHierarchyOverviewParams,
  CustomerListParams,
  CustomerListQLParams,
  CustomerParams,
} from '@/api/modules/customers'
import {PaginatedData, RequestData} from '@/stores/index'
import type HierarchyOverview from '@/types/api/hierarchy-overview.type'

interface State {
  customer: RequestData<Customer>
  customerDetails: RequestData<CustomerDetail>
  customers: PaginatedData<Customer>
  hierarchyOverviews: PaginatedData<HierarchyOverview>
  ids: RequestData<Array<number>>
}

export const useCustomerStore = defineStore('customer', {
  // see https://pinia.vuejs.org/core-concepts/state.html
  state: (): State => ({
    // define initial values
    customer: new RequestData(),
    customerDetails: new RequestData<CustomerDetail>(),
    customers: new PaginatedData<Customer>(),
    hierarchyOverviews: new PaginatedData<HierarchyOverview>(),
    ids: new RequestData<Array<number>>([]),
  }),
  // see https://pinia.vuejs.org/core-concepts/getters.html
  getters: {
    // define getters to access compound or calculated data
  },
  // see https://pinia.vuejs.org/core-concepts/actions.html
  actions: {

    /**
         * load customers
         * @param {CustomerListParams} config - the config for the call
         * @param cancel
         * @param requestID
         */
    async loadCustomers(config: CustomerListParams | CustomerListQLParams, cancel = true, requestID?: string) {
      const id = requestID || api.customer.getCustomers.name
      if (cancel)
        api.customer.cancel(id)
      this.customers.pending = true
      return api.customer.cancelableCall(api.customer.getCustomers, config, id)
        .then((data) => {
          const page = data.data
          this.customers.data = page.results
          this.customers.total = page.count
          if (!api.customer.hasPending(id))
            this.customers.pending = false
        }).catch((error) => {
          if (!api.customer.hasPending(id))
            this.customers.pending = false
          return error
        })
    },

    /**
         * load customers
         * @param {CustomerHierarchyOverviewParams} config - the config for the call
         * @param cancel
         * @param requestID
         */
    async loadHierarchyOverview(config: CustomerHierarchyOverviewParams, cancel = true, requestID?: string) {
      const id = requestID || api.customer.getCustomerHierarchyOverviews.name
      if (cancel)
        api.customer.cancel(id)
      this.hierarchyOverviews.pending = true
      return api.customer.cancelableCall(api.customer.getCustomerHierarchyOverviews, config, id)
        .then((data) => {
          const page = data.data
          this.hierarchyOverviews.data = page.results
          this.hierarchyOverviews.total = page.count
          if (!api.customer.hasPending(id))
            this.hierarchyOverviews.pending = false
        }).catch((error) => {
          if (!api.customer.hasPending(id))
            this.hierarchyOverviews.pending = false
          return error
        })
    },

    /**
         *
         * @param {CustomerParams}config
         * @param cancel
         * @param requestID
         */
    async loadCustomer(config: CustomerParams, cancel = true, requestID?: string) {
      const id = requestID || api.customer.getCustomer.name
      if (cancel)
        api.customer.cancel(id)
      this.hierarchyOverviews.pending = true
      return api.customer.cancelableCall(api.customer.getCustomer, config, id)
        .then((data) => {
          this.customer.data = data.data
          if (!api.customer.hasPending(id))
            this.customer.pending = false
          return data
        }).catch((error) => {
          if (!api.customer.hasPending(id))
            this.customer.pending = false
          throw error
        })
    },

    /**
         *
         * @param {CustomerDetailParams}config
         * @param cancel
         * @param requestID
         */
    async loadCustomerDetails(config: CustomerDetailParams, cancel = true, requestID?: string) {
      const id = requestID || api.customer.getCustomerDetails.name
      if (cancel)
        api.customer.cancel(id)
      this.customerDetails.pending = true
      return api.customer.cancelableCall(api.customer.getCustomerDetails, config, id)
        .then((data) => {
          this.customerDetails.data = data.data
          if (!api.customer.hasPending(id))
            this.customerDetails.pending = false
        }).catch((error) => {
          if (!api.customer.hasPending(id))
            this.customerDetails.pending = false
          return error
        })
    },

    async loadAllCustomerIds(cancel = true, requestID?: string) {
      const id = requestID || api.customer.getAllCustomerIds.name
      if (cancel)
        api.customer.cancel(id)
      this.ids.pending = true
      return api.customer.cancelableCall(api.customer.getAllCustomerIds, null, id)
        .then((data) => {
          this.ids.data = data.data
          if (!api.customer.hasPending(id))
            this.ids.pending = false
        }).catch((error) => {
          if (!api.customer.hasPending(id))
            this.ids.pending = false
          return error
        })
    },

    /**
         * resets the store to the initial state.
         */
    clearCustomers() {
      this.$reset()
    },
  },
})
