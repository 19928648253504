<template>
  <div class="h-full flex flex-col overflow-hidden">
    <div class="flex">
      <h1 class="page-head">
        Angebote
      </h1>
      <ImportTimestamp
        class="items-center mr-4 ml-auto"
        dependant-on="offers"
      />
    </div>
    <k-card>
      <template #title>
        <span class="card-head">
          <h2>Alle Angebote</h2>
        </span>
      </template>
      <k-table
        v-model:page="activePage"
        class="flex-1"
        :head="offerTableHeads"
        :data="offers"
        :total-rows="totalOffers"
        :searchable="true"
        :filter-configs="offerFilterSlots"
        :data-loading="loadingOffers"
        async
        @reload="reload"
        @change-page="activePage = $event"
        @row-select="openDetailView"
      >
        <template #customer-slot="{ content }">
          <span
            v-if="content && content.customer"
            class="whitespace-nowrap"
          >
            {{ content.customer.name }}
          </span>
          <div v-else>
            -
          </div>
        </template>
        <template #material-slot="{ content }">
          <k-attribute-display :value="content?.material?.materialDescription" />
        </template>
        <template #quantity-slot="{ content }">
          <k-attribute-display :value="getBaseQuantity(content)">
            <span class="whitespace-nowrap">
              {{ quantityFormatter.format(getBaseQuantity(content).quantity) }}
              <span>{{ unitFormatter(getBaseQuantity(content).unit) }}</span>
            </span>
          </k-attribute-display>
        </template>
        <template #order-slot="{content}">
          <k-attribute-display :value="content?.order?.receipt">
            <span class="whitespace-nowrap">
              {{ content?.order?.receipt }}
            </span>
          </k-attribute-display>
        </template>
        <template #contract-slot="{content}">
          <k-attribute-display :value="content?.contract?.receipt">
            <span class="whitespace-nowrap">
              {{ content?.contract?.receipt }}
            </span>
          </k-attribute-display>
        </template>
      </k-table>
    </k-card>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
import {addMonths, format} from 'date-fns'
import type {TableFilter} from '@singularit/kurz-components'
import {offerTableHeads} from '@/views/offer/offers-table-heads'
import useOfferList from '@/views/offer/use-offer-list'
import ImportTimestamp from '@/components/timestamp/ImportTimestamp.vue'
import {quantityFormatter, unitFormatter} from '@/utils/formatter'
import {getBaseQuantity} from '@/utils/use-quantities'

export default defineComponent({
  name: 'OffersView',
  components: {ImportTimestamp},
  setup() {
    const {offers, totalOffers, activePage, openDetailView, loadingOffers, reload} = useOfferList()

    const today = new Date()
    const target = addMonths(today, -3)

    const offerFilterSlots: Array<TableFilter> = [
      {
        displayText: 'aktuelle Angebote',
        default: true,
        key: 'start__gte',
        filter: format(target, 'yyyy-MM-dd'),
      },
      {
        displayText: 'nur offene Angebote',
        default: false,
        key: 'open',
        filter: true,
      },
    ]

    return {
      activePage,
      offers,
      totalOffers,
      offerTableHeads,
      openDetailView,
      offerFilterSlots,
      reload,
      loadingOffers,
      getBaseQuantity,
      quantityFormatter,
      unitFormatter,
    }
  },
})
</script>
