import type {Ref} from 'vue'
import {computed, ref, unref, watchEffect} from 'vue'
import {useRouter} from 'vue-router'
import type {OffersAggregationParams, OffersListParams} from '@/api/modules/offers'
import type Customer from '@/types/api/customer.type'
import {useOfferStore} from '@/stores/offer'
import type Offer from '@/types/api/offer.type'
import useTableQuery from '@/utils/use-table-query'
import {AggregationFunction} from '@/api/params/aggregation.type'

export default function useOfferList(
  customerFilter?: Ref<Customer>,
  receiptFilter?: Ref<number>,
) {
  const store = useOfferStore()
  const router = useRouter()

  const activePage = ref(1)

  const {query, reload} = useTableQuery()

  const config = computed(() => {
    const config: OffersListParams = {
      page: unref(activePage),
      customer: unref(customerFilter),
      receipt: unref(receiptFilter),
      query: unref(query),
    }
    return config
  })

  watchEffect(() => {
    store.loadOffers(config.value)
  })

  const loadingOffers = computed(() => store.offers.pending)
  const offers = computed(() => {
    return store.offers.data
  })

  const totalOffers = computed(() => {
    return store.offers.total
  })

  function openDetailView(data: Offer) {
    router.push({
      name: 'offer-detail',
      params: {
        offerReceipt: data.receipt,
        offerPosition: data.position,
      },
    })
  }

  return {
    offers,
    totalOffers,
    activePage,
    openDetailView,
    loadingOffers,
    reload,
  }
}

export function useAggregatedOfferList(customerFilter?: Ref<Customer>) {
  const store = useOfferStore()
  const router = useRouter()

  const activePage = ref(1)

  const {query, reload} = useTableQuery()

  const config = computed(() => {
    const config: OffersAggregationParams = {
      page: unref(activePage),
      customer: unref(customerFilter),
      query: unref(query),
      aggregationField: 'revenue',
      groupBy: 'customer,end,receipt',
      aggregation: AggregationFunction.SUM,
    }
    return config
  })

  watchEffect(() => {
    store.loadOfferAggregate(config.value)
    // store.dispatch(ActionTypes.GET_OFFERS, config.value)
  })

  const loadingOffers = computed(() => store.aggregates.pending)

  const offers = computed(() => {
    return store.aggregates.data
  })

  const totalOffers = computed(() => {
    return store.aggregates.total
  })

  function openDetailView(data: Offer) {
    router.push({
      name: 'offer-detail',
      params: {
        offerReceipt: data.receipt,
        offerPosition: data.position,
      },
    })
  }

  return {
    offers,
    totalOffers,
    activePage,
    openDetailView,
    loadingOffers,
    reload,
  }
}
