import {defineStore} from 'pinia'
import type HierarchyStat from '@/types/api/dashboard/hierarchy-stats.type'
import {PaginatedData} from '@/stores/index'
import api from '@/api'
import type {HierarchyStatParams} from '@/api/modules/dashboards'

interface State {
  hierarchyStats: PaginatedData<HierarchyStat>
}

export const useHierarchyStatStore = defineStore('hierarchy-stat', {
  // see https://pinia.vuejs.org/core-concepts/state.html
  state: (): State => ({
    // define initial values
    hierarchyStats: new PaginatedData<HierarchyStat>(),
  }),
  // see https://pinia.vuejs.org/core-concepts/getters.html
  getters: {
    // define getters to access compound or calculated data
  },
  // see https://pinia.vuejs.org/core-concepts/actions.html
  actions: {

    /**
         * load hierarchyStats
         * @param {HierarchyStatParams} config - the config for the call
         * @param cancel
         * @param requestID
         */
    async loadHierarchyStats(config: HierarchyStatParams, cancel = true, requestID?: string) {
      const id = requestID || api.dashboard.getHierarchiesStats.name
      if (cancel)
        api.dashboard.cancel(id)
      this.hierarchyStats.pending = true
      return api.dashboard.cancelableCall(api.dashboard.getHierarchiesStats, config, id)
        .then((data) => {
          const page = data.data
          this.hierarchyStats.total = page.count
          this.hierarchyStats.data = page.results
          if (!api.dashboard.hasPending(id))
            this.hierarchyStats.pending = false
        }).catch((error) => {
          if (!api.dashboard.hasPending(id))
            this.hierarchyStats.pending = false
          throw error
        })
    },

    /**
         * resets the store to the initial state.
         */
    clearHierarchyStats() {
      this.$reset()
    },
  },
})
