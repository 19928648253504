import type Branch from '@/types/api/branch.type'
import type Partner from '@/types/api/partner.type'
import CreateUpdateModel from '@/types/api/create-update-model.type'

export default class Customer extends CreateUpdateModel {
  public id?: number
  public name?: string
  public branch?: Partial<Branch>
  public classification?: Record<string, unknown>
  public concern?: Record<string, unknown>
  public countryCode?: string
  public division?: number
  public isActive?: boolean
  public partners?: Partner[]
}
