<template>
  <div class="h-full flex flex-col">
    <hr>
    <div class="flex flex-col gap-3 m-5 pb-5">
      <k-base-radio-group
        v-model="computedFeedback.gender"
        class="mx-0"
        label="Gender"
        :options="genderOptions"
        :disabled="!create"
        horizontal
      />

      <k-text-input
        v-model="computedFeedback.firstName"
        label="Vorname"
        :disabled="!create"
      />
      <k-text-input
        v-model="computedFeedback.lastName"
        label="Nachname"
        :disabled="!create"
      />
      <k-base-radio-group
        v-model="computedFeedback.channel"
        label="Channel"
        :options="channelOptions"
        :disabled="!create"
        horizontal
      />

      <k-select-input
        v-model="computedFeedback.result"
        placeholder="Ergebnis"
        label="Ergebnis"
        :options="resultOptions"
        :disabled="!create"
        :classes="{
          containerActive: 'h-auto relative mx-auto w-full flex items-center justify-end box-border cursor-pointer border rounded text-base leading-snug outline-none rounded-b-none',
          containerDisabled: 'h-auto relative mx-auto w-full flex items-center justify-end box-border border rounded text-base leading-snug outline-none bg-white',
          optionSelected: 'flex items-center justify-start box-border text-left cursor-pointer text-base leading-snug bg-gray-600 hover:bg-gray-700 text-white py-2 px-3'
        }"
      />
      <k-text-input
        v-model="computedFeedback.comment"
        class="min-h-[4rem]"
        textarea
        label="Kommentar"
        :disabled="!create"
      />
      <k-select-input
        v-model="computedAuthor"
        placeholder="author"
        label="Author"
        searchable
        :options="userOptions"
        :disabled="!create"
        :classes="{
          containerActive: 'h-auto relative mx-auto w-full flex items-center justify-end box-border cursor-pointer border rounded text-base leading-snug outline-none rounded-b-none',
          containerDisabled: 'h-auto relative mx-auto w-full flex items-center justify-end box-border border rounded text-base leading-snug outline-none bg-white',
          optionSelected: 'flex items-center justify-start box-border text-left cursor-pointer text-base leading-snug bg-gray-600 hover:bg-gray-700 text-white py-2 px-3',
          dropdown: 'max-h-60 absolute -left-px -right-px bottom-0 transform border -mt-px overflow-y-scroll z-50 flex flex-col -translate-y-full top-px bottom-auto rounded-t bg-white',
        }"
      />

      <div class="text-center">
        <k-kurz-button
          v-if="create"
          text="Speichern"
          @clicked="saveFeedback"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {computed, ref, toRefs, unref} from 'vue'
import {useToast} from 'vue-toastification'
import useFeedbackOptions from '@/views/feedback/use-feedback-options'
import type Customer from '@/types/api/customer.type'
import Feedback from '@/types/api/feedback.type'
import type Churn from '@/types/api/churn.type'
import Account from '@/types/api/account.type'
import api from '@/api'

const emit = defineEmits<{
  (e: 'save'): void
}>()

interface Props {
  customer: Customer
  feedback?: Feedback
  churn?: Churn
  create: boolean
}

const props = withDefaults(defineProps<Props>(), {
  feedback: undefined,
  churn: undefined,
  create: false,
})
const {customer, feedback, churn} = toRefs(props)

const fillDefaults = (feedback: Feedback) => {
  if (!feedback.customer)
    feedback.customer = customer.value as Customer

  if (!feedback.churn)
    feedback.churn = churn.value as Churn

  if (!feedback.creator)
    feedback.creator = new Account() // placeholder creator is being set at the backend

  return feedback
}

const internalFeedback = ref(new Feedback())

const computedFeedback = computed(() => {
  let result = internalFeedback.value
  result = fillDefaults(result)
  if (feedback.value !== undefined)
    result = {...unref(feedback)} // needs destructuring to avoid manipulation the reference
  return result
})

const toast = useToast()
const saveFeedback = () => {
  if (computedFeedback.value !== undefined) {
    api.feedbacks.saveFeedback(computedFeedback.value).then(() => {
      internalFeedback.value = fillDefaults(new Feedback())
      toast.success('Feedback wurde erfolgreich erstellt')
      emit('save')
    }).catch(() => {
      toast.error('Feedback konnte nicht erstellt werden')
    })
  }
}

const computedAuthor
    = computed({
      // getter
      get() {
        return computedFeedback.value.author?.id
      },
      // setter
      set(authorId) {
        const account = new Account()
        account.id = authorId
        computedFeedback.value.author = account
      },
    })

const {genderOptions, resultOptions, channelOptions, userOptions} = useFeedbackOptions()
</script>

<style scoped></style>
