import type {AxiosResponse} from 'axios'
import axiosInstance from '@/api/axios'
import type Page from '@/types/api/page.type'
import type PaginationListParameters from '@/api/modules/pagination-list-parameters'
import type Hierarchy from '@/types/api/hierarchy.type'
import type QueryParameters from '@/api/modules/query-parameters'
import BaseModule from '@/api/modules/base-module'

export interface HierarchyListParams extends PaginationListParameters, QueryParameters {
  level?: number
  superior?: number
}

class HierarchyModule extends BaseModule {
  getHierarchies(params: HierarchyListParams, controller?: AbortController): Promise<AxiosResponse<Page<Hierarchy>>> {
    const signal = controller ? controller.signal : undefined
    const data = {
      params,
      signal,
    }
    return axiosInstance.get('/hierarchies/', data).then((response) => {
      return response
    })
  }
}

const module = new HierarchyModule()
export default module
