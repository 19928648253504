<template>
  <k-card
    class="h-full"
    header
  >
    <template #title>
      Benutzerpasswort ändern
    </template>
    <div class="mx-auto mt-10 w-3/5 flex flex-col gap-6">
      <div class="flex flex-row justify-center">
        <span class="w-1/3 font-bold mt-1">Passwort für {{ account?.firstName }} ändern : </span>
        <k-text-input
          v-model="accountUpdateParams.password"
          type="password"
          class=""
        />
      </div>
      <k-kurz-button
        class="w-1/6 mt-7 mx-auto"
        @clicked="changePassword"
      >
        Passwort ändern
      </k-kurz-button>
    </div>
  </k-card>
</template>

<script setup lang="ts">
import {computed, ref, watchEffect} from 'vue'
import {useToast} from 'vue-toastification'
import {useAccountStore} from '@/stores/account'

interface Props {
  accountId: number
}

const props = defineProps<Props>()

const store = useAccountStore()
watchEffect(() => {
  store.loadAccount({id: props.accountId})
})
const account = computed(() => store.account.data)

const accountUpdateParams = ref({
  password: '',
  id: props.accountId,
})

const toast = useToast()
const changePassword = () => {
  store.updateAccountDetails(accountUpdateParams.value).then(() =>
    toast.success('Passwort wurde erfolgreich geändert'),
  ).catch(() => {
    toast.error('Passwort konnte nicht geändert werden.')
  })
}

</script>
