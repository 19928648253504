import {ObjectTableHead, SlotTableHead} from '@singularit/kurz-components'
import {dateFormatter, tableCurrencyFormatter} from '@/utils/formatter'
import type HierarchyOverview from '@/types/api/hierarchy-overview.type'

const hierarchiesTableHeads = [
  new SlotTableHead({
    title: 'Name',
    sortable: false,
    id: 1,
    sortKey: 'name',
    slotName: 'hierarchy-name',
  }),
  new SlotTableHead({
    title: 'ID',
    sortable: false,
    id: 2,
    sortKey: 'number',
    slotName: 'hierarchy-number',
  }),
  new ObjectTableHead<HierarchyOverview>({
    title: 'erste Rechnung',
    attribute: 'firstInvoiceDate',
    sortable: false,
    id: 3,
    formatter: dateFormatter,
    alignment: 'right',
  }),
  new ObjectTableHead<HierarchyOverview>({
    title: 'letzte Rechnung',
    attribute: 'mostRecentInvoiceDate',
    sortable: false,
    id: 4,
    formatter: dateFormatter,
    alignment: 'right',
  }),
  new ObjectTableHead<HierarchyOverview>({
    title: 'Gesamtumsatz',
    attribute: 'totalRevenue',
    sortable: false,
    id: 5,
    formatter: tableCurrencyFormatter,
  }),
  new SlotTableHead({
    title: 'Trend',
    sortable: false,
    id: 6,
    slotName: 'hierarchy-trend',
  }),
  // new SlotTableHead<Branch>({
  //   title: "2021",
  //   sortable: true,
  //   id: 4,
  //   slotName: "2021",
  //   comparator: (a, b) => {
  //     return 0; //TODO
  //   }
  // }),
  //
  // new SlotTableHead<Branch>({
  //   title: "2020",
  //   sortable: true,
  //   id: 5,
  //   slotName: "2020",
  //   comparator: (a, b) => {
  //     return 0; //TODO
  //   }
  // }),
  //
  // new SlotTableHead<Branch>({
  //   title: "2019",
  //   sortable: true,
  //   id: 6,
  //   slotName: "2019",
  //   comparator: (a, b) => {
  //     return 0; //TODO
  //   }
  // }),
  // new SlotTableHead({
  //   title: 'Trend',
  //   sortable: true,
  //   id: 9,
  //   sortKey: 'trend', // TODO
  //   slotName: 'trend',
  // }),
]

export {hierarchiesTableHeads}
