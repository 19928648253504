import {ObjectTableHead, SlotTableHead} from '@singularit/kurz-components'
import type {MonthlyTrend} from '@/types/api/trend.type'

const monthlyTrendsTableHeads = [
  new ObjectTableHead({
    title: 'Kunde',
    sortable: true,
    attribute: 'name',
    id: 0,
    alignment: 'left',
  }),
  new ObjectTableHead<MonthlyTrend>({
    id: 1,
    title: 'ID',
    attribute: 'id',
    sortable: true,
    alignment: 'left',
  }),
  new ObjectTableHead<MonthlyTrend>({
    id: 2,
    title: 'Land',
    attribute: 'countryCode',
    alignment: 'center',
  }),
  new SlotTableHead({
    id: 3,
    title: 'Branche',
    slotName: 'branch-slot',
    sortKey: 'branch__name',
    sortable: true,
  }),
  new SlotTableHead({
    id: 4,
    title: '3-Monats-Trend',
    sortKey: 'trend',
    slotName: '3-month-trend',
    sortable: true,
  }),
  new SlotTableHead({
    id: 5,
    title: '12-Monats-Trend',
    sortKey: 'trend',
    slotName: '12-month-trend',
    sortable: true,
  }),
  // new SlotTableHead({
  //   id: 6,
  //   title: `Umsatz ${watchedMonth.value.year}`,
  //   sortKey: 'current_year_revenue',
  //   sortable: true,
  //   slotName: 'current-year-revenue',
  //   alignment: 'right',
  // }),
  // new SlotTableHead({
  //   id: 7,
  //   title: `Umsatz ${watchedMonth.value.year - 1}`,
  //   sortKey: 'previous_year_revenue',
  //   sortable: true,
  //   alignment: 'right',
  //   slotName: 'previous-year-revenue',
  // }),
  new SlotTableHead({
    title: 'zuständiger Mitarbeiter',
    sortable: false,
    id: 8,
    slotName: 'partner-slot',
  }),
]

export {monthlyTrendsTableHeads}
