<template>
  <div class="flex flex-col">
    <div class="flex">
      <h1 class="page-head uppercase">
        Archiv für abgesprungene Kunden
      </h1>
      <ImportTimestamp
        class="items-center mr-4 ml-auto"
        dependant-on="invoices"
      />
    </div>
    <div class="w-full mx-10 mb-10">
      <div>
        <k-card>
          <template #title>
            <span class="card-head">
              <h2>Churns</h2>
            </span>
          </template>
          <div class="mr-20 ml-10 mb-10">
            <k-table
              v-model:page="activePage"
              :head="churnTableHeads"
              :data="churns"
              async
              :total-rows="totalChurns"
              :data-loading="loadingChurns"
              searchable
              @reload="reload"
              @change-page="activePage = $event"
            >
              <template #customer-slot="{ content }">
                <span>
                  {{ content.customer.name }}
                </span>
              </template>
            </k-table>
            <div
              v-if="!churns"
              class="text-center"
            >
              Keine Daten vorhanden
            </div>
          </div>
        </k-card>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import {defineComponent} from 'vue'
import {churnTableHeads} from '@/views/churn/churn-table-heads'
import ImportTimestamp from '@/components/timestamp/ImportTimestamp.vue'
import useChurnList from '@/views/churn/use-churn-list'

export default defineComponent({
  name: 'ChurnList',
  components: {ImportTimestamp},
  setup() {
    const {churns, totalChurns, activePage, loadingChurns, reload} = useChurnList()

    return {churnTableHeads, churns, totalChurns, activePage, reload, loadingChurns}
  },
})
</script>

<style scoped></style>
