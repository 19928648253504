<template>
  <k-card>
    <template #title>
      <div class="flex gap-2">
        <span class="card-head">
          <h2>Produkthierarchien</h2>
        </span>
        <k-tooltip
          class="mb-1.5"
          :text="infoText"
        />
      </div>
    </template>
    <k-table
      v-model:page="activePageHO"
      class="flex-1"
      :data="hierarchyOverviews"
      :head="hierarchiesTableHeads"
      :total-rows="totalHierarchyOverviews"
      :data-loading="loadingHierarchyOverviews"
      async
      @change-page="activePageHO = $event"
      @row-select="setHierarchy"
    >
      <template #hierarchy-name="{ content }">
        <div class="whitespace-nowrap">
          {{ content.hierarchy?.name }}
        </div>
      </template>
      <template #hierarchy-number="{ content }">
        <div class="whitespace-nowrap">
          {{ content.hierarchy?.number }}
        </div>
      </template>
      <template #hierarchy-trend="{ content }">
        <div class="flex justify-center">
          <k-traffic-light
            :value="calcTrendColor(content?.hierarchyTrend)"
          />
        </div>
      </template>
    </k-table>
  </k-card>
</template>

<script setup lang="ts">
import {toRef} from 'vue'
import type Customer from '@/types/api/customer.type'
import {hierarchiesTableHeads} from '@/views/customer/customer-detail/hierarchie-table-heads'
import useHierarchyOverviewList from '@/views/customer/customer-detail/use-hierarchy-overview-list'
import type HierarchyOverview from '@/types/api/hierarchy-overview.type'
import useHierarchyFilter from '@/views/customer/customer-detail/use-hierarchy-filter'
import {calcTrendColor} from '@/utils/traffic-light-colors'

interface Props {
  customer: Customer
}

const props = defineProps<Props>()
const customer = toRef(props, 'customer')

const {hierarchyFilter1, hierarchyFilter2} = useHierarchyFilter()

const {
  hierarchyOverviews,
  totalHierarchyOverviews,
  activePage: activePageHO,
  loadingHierarchyOverviews,
} = useHierarchyOverviewList(customer, hierarchyFilter1, hierarchyFilter2)

const infoText = `Der Trend wird nach der Steigung der Regression für die letzten 3 Kaufmonate bewertet:
          Gut: < 0.05
          Neutral: < -0.05
          Schlecht: > -0.05
      `

const setHierarchy = (value: HierarchyOverview) => {
  if (!hierarchyFilter1.value && value.hierarchy)
    hierarchyFilter1.value = value.hierarchy

  else if (!hierarchyFilter2.value && value.hierarchy)
    hierarchyFilter2.value = value.hierarchy
}

</script>
