import {computed, unref} from 'vue'
import hash from 'object-hash'
import type Customer from '@/types/api/customer.type'
import type {MaybeRef} from '@/types/mayberef.type'
import type CustomerTrendInfo from '@/types/api/customer-trend-info.type'
import {useTrendStore} from '@/stores/trend'

export function useTrendInfo(customer?: MaybeRef<Customer>, months?: MaybeRef<number>) {
  const store = useTrendStore()

  const dataKey = computed(() => {
    let key = ''
    const unrefCustomer = unref(customer)
    if (unrefCustomer) {
      const tuple = {first: unrefCustomer.id, second: unref(months)}
      key = hash(tuple)
    }
    return key
  })

  const trendInfo = computed(() => {
    const map = store.trendInfos.data as Record<string, CustomerTrendInfo>
    return map[dataKey.value]
  })

  return {trendInfo}
}
