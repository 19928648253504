import type {ComputedRef} from 'vue'
import {computed} from 'vue'
import type {Option} from '@singularit/kurz-components'

export default function useCountryCodeFilter() {
  const countryCodes: ComputedRef<Array<Option>> = computed(() => {
    const list = []
    list.push({
      value: 'DE',
      label: 'Deutschland',
    },
    )
    list.push({
      value: 'FR',
      label: 'Frankreich',
    },
    )
    list.push({
      value: 'AUT',
      label: 'Österreich',
    },
    )
    list.push({
      value: 'CHE',
      label: 'Schweiz',
    },
    )
    return list
  })

  return {
    countryCodes,
  }
}
