import {ObjectTableHead, SlotTableHead} from '@singularit/kurz-components'
import type Feedback from '@/types/api/feedback.type'
import {dateFormatter} from '@/utils/formatter'

const feedbacksTableHeads = [
  new ObjectTableHead<Feedback>({
    title: 'Wann',
    attribute: 'created',
    sortable: true,
    id: 0,
    formatter: dateFormatter,
    alignment: 'right',
  }),
  new SlotTableHead({
    title: 'Kanal',
    slotName: 'channel-slot',
    sortable: true,
    sortKey: 'channel',
    id: 1,
  }),
  new SlotTableHead({
    title: 'Kontaktperson',
    slotName: 'contact-slot',
    sortable: true,
    sortKey: 'first_name',
    id: 2,
  }),
  new SlotTableHead({
    title: 'Ergebnis',
    slotName: 'result-slot',
    sortable: true,
    sortKey: 'result',
    id: 3,
  }),

  new SlotTableHead({
    title: 'Ersteller',
    slotName: 'creator-slot',
    sortable: true,
    sortKey: 'creator',
    id: 4,
  }),
]

export {feedbacksTableHeads}
