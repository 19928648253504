<template>
  <div class="flex flex-col h-screen">
    <div class="flex">
      <h1 class="page-head uppercase">
        {{ account?.firstName }} {{ account?.lastName }}
      </h1>
    </div>
    <k-card
      :header="false"
      class="pt-10 pb-10"
    >
      <k-list-layout class="w-full h-1/2">
        <k-list-entry
          label="Vorname"
          :value="account?.firstName"
        />
        <k-list-entry
          label="Nachname"
          :value="account?.lastName"
        />
        <k-list-entry
          label="Email"
          :value="account?.email"
        />
        <k-list-entry
          label="Rolle"
          :value="account?.role"
        />
      </k-list-layout>
    </k-card>
    <k-tab-component
      class="m-12 h-1/2"
      :titles="tabs"
    >
      <template
        #Gruppen
      >
        <UserGroupsTab :account-id="accountId" />
      </template>

      <template #Rechte>
        <UserRightsTab :account-id="accountId" />
      </template>

      <template #UI-Filter>
        <k-card
          class="h-full"
          header
        >
          <template #title>
            UI-Filter verwalten
          </template>
          <div class="ml-10 mt-10 w-full">
            <div
              v-for="(uifilt, i) in uiFilter"
              :key="i"
            >
              <div class="flex flex-row gap-20 ">
                <div class="text-xl flex flex-row mt-2 w-1/2">
                  <span class="font-bold mr-5">{{ uifilt.viewName }} </span>
                  <div
                    v-for="(param, j) in uifilt.show"
                    :key="j"
                    class="mr-5"
                  >
                    <span class="font-bold mr-2"> {{ param[0] }}:</span> <span>{{ param[1] }}</span>
                  </div>
                  <span>{{ uifilt.filter.url }}</span>
                </div>
                <div class="flex flex-row w-1/2 ">
                  <input
                    v-model="uifilt.newUrl"
                    class="
                    w-80
                    border
                border-solid
                border-gray-300
                px-3
                placeholder-gray-400
                text-gray-700
                rounded
                focus:outline-none focus:ring
              "
                    placeholder="Neue URL"
                    style="transition: all 0.15s ease 0s"
                  >
                  <k-kurz-button
                    class="ml-4"
                    @click="updateUiFilter(uifilt)"
                  >
                    UI-Filter einfügen bearbeiten
                  </k-kurz-button>
                </div>
              </div>
            </div>
          </div>
        </k-card>
      </template>

      <template #Passwort>
        <UserPasswordTab :account-id="accountId" />
      </template>
    </k-tab-component>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, watchEffect} from 'vue'
import {useAccountStore} from '@/stores/account'
import useUIFilterManagemant from '@/views/user-managemant/useUIFilterManagemant'
import UserPasswordTab from '@/views/user-managemant/user-managemant-detail/UserPasswordTab.vue'
import UserRightsTab from '@/views/user-managemant/user-managemant-detail/UserRightsTab.vue'
import UserGroupsTab from '@/views/user-managemant/user-managemant-detail/UserGroupsTab.vue'

export default defineComponent({
  name: 'UserDetailView',
  components: {UserGroupsTab, UserPasswordTab, UserRightsTab},
  props: {
    accountId: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const store = useAccountStore()
    const {uiFilter, updateUiFilter} = useUIFilterManagemant(props.accountId)
    watchEffect(() => {
      store.loadAccount({id: props.accountId})
      store.loadAccountRights({id: props.accountId})
      store.loadAccountUIFilter({user: props.accountId})
    },
    )
    const loadingData = computed(() => store.account.pending)
    const account = computed(() => store.account.data)

    const tabs = ['Gruppen', 'Rechte', 'UI-Filter', 'Passwort']

    return {
      account,
      tabs,
      loadingData,
      uiFilter,
      updateUiFilter,
    }
  },
})

</script>

<style scoped>

</style>
