import type {ComputedRef, Ref} from 'vue'
import {computed, unref} from 'vue'
import type Hierarchy from '@/types/api/hierarchy.type'
import useAllHierarchies from '@/utils/behaviours/use-all-hierarchies'
import useUrlParam from '@/utils/behaviours/use-url-param'
import {hierarchyListMapping, hierarchyListReverseMapping} from '@/utils/url/url-from-string'

function createDisabledComputed(ref: Ref<Hierarchy[]>) {
  return computed(() => {
    let disabled = true
    if (ref && unref(ref) && unref(ref).length > 0)
      disabled = false

    return disabled
  })
}

function createHierarchyComputed(hierarchies: ComputedRef<Hierarchy[]>, superior?: Ref<Hierarchy[]>) {
  return computed(() => {
    let list: any [] = []
    const superiorHierarchies = unref(superior)
    if (hierarchies.value && hierarchies.value.length > 0) {
      list = hierarchies.value.filter((hierarchy) => {
        const superior = hierarchy.superior
        if (!superiorHierarchies) {
          return superior === null
        }
        else if (superior) {
          let superiorInHierarchy = false
          for (const superiorHierarchy of superiorHierarchies) {
            if (superior.id === superiorHierarchy.id)
              superiorInHierarchy = true
          }
          return superiorInHierarchy
        }
        else {
          return false
        }
      })
    }
    // list.sort((a, b) => getFullHierarchyNumber(a).localeCompare(getFullHierarchyNumber(b)));
    const result = []
    for (const entry of list) {
      const labelNumber = entry.fullNumber
      result.push({
        value: entry,
        label: `${entry.name} (${labelNumber})`,
        labelNumber,
      })
    }
    result.sort((a, b) => a.labelNumber - b.labelNumber)
    return result
  })
}

function getSelectLabel(values: any[]) {
  let label = null
  const list = unref(values)
  if (list && list.length > 0) {
    label = ''
    if (list.length <= 3) {
      for (const entry of list)
        label += `${entry.labelNumber}, `

      label = label.slice(0, label.length - 2)
    }
    else if (list.length > 3) {
      label = `${list.length} Hierarchien ausgewählt`
    }
  }
  return label
}

export default function useHierarchyFilters(
  selectedHierarchy1: Hierarchy[] = [],
  selectedHierarchy2: Hierarchy[] = [],
  selectedHierarchy3: Hierarchy[] = []) {
  const {hierarchies, hierarchiesLoading} = useAllHierarchies()

  const ph1selected = useUrlParam('ph1', hierarchyListMapping, hierarchyListReverseMapping(hierarchies), [])
  const ph2selected = useUrlParam('ph2', hierarchyListMapping, hierarchyListReverseMapping(hierarchies), [])
  const ph3selected = useUrlParam('ph3', hierarchyListMapping, hierarchyListReverseMapping(hierarchies), [])

  if (selectedHierarchy1 && selectedHierarchy1.length > 0)
    ph1selected.value = selectedHierarchy1

  if (selectedHierarchy2 && selectedHierarchy2.length > 0)
    ph2selected.value = selectedHierarchy2

  if (selectedHierarchy3 && selectedHierarchy3.length > 0)
    ph3selected.value = selectedHierarchy3

  const level1 = createHierarchyComputed(hierarchies, undefined)
  const level2 = createHierarchyComputed(hierarchies, ph1selected)
  const level3 = createHierarchyComputed(hierarchies, ph2selected)

  const level2Disabled = createDisabledComputed(ph1selected)
  const level3Disabled = createDisabledComputed(ph2selected)

  const hierarchyLabelFunction = getSelectLabel

  return {
    hierarchies,
    hierarchiesLoading,
    level1,
    level2,
    level3,
    level2Disabled,
    level3Disabled,
    ph1selected,
    ph2selected,
    ph3selected,
    hierarchyLabelFunction,
  }
}

