<template>
  <k-card class="trend-card">
    <template #title>
      <span class="card-head flex place-content-between">
        <h2>{{ cardTitle }}</h2>
        <k-traffic-light
          class="mr-5"
          :value="calcTrendColor(purchaseTrend?.assessment)"
          :info="`Regression Koeffizient: ${numberFormatter.format(purchaseTrend?.assessment)}`"
        />
      </span>
    </template>
    <div
      v-if="loadingData"
      class="h-32 w-full flex justify-center"
    >
      <k-kurz-dot-loader class="self-center" />
    </div>
    <div
      v-else-if="insufficientData"
      class="flex justify-center items-center h-full w-full"
    >
      <h2>
        Nicht genügend Daten vorhanden
      </h2>
    </div>
    <div
      v-else
      class="flex flex-1"
    >
      <LineChart
        :chart-data="data"
        :options="options"
        class="m-5 h-full w-full pr-5 max-h-96"
      />
    </div>
  </k-card>
</template>

<script lang="ts" setup>
import {computed, ref, watchEffect} from 'vue'
import {LineChart} from 'vue-chart-3'
import type {ChartEvent, LineOptions} from 'chart.js'

import type Customer from '@/types/api/customer.type'
import {dateLabelFormatFunction, mapGraphValues} from '@/utils/charts/graph-mapper'
import {numberFormatter} from '@/utils/formatter'
import type {CustomerPurchaseTrendInfoParams} from '@/api/modules/trends'
import {useTrendStore} from '@/stores/trend'
import {calcTrendColor} from '@/utils/traffic-light-colors'

interface Props {
  title: string
  customer: Customer
  timespan: string
}
const props = defineProps<Props>()

const store = useTrendStore()
const config = computed(() => {
  const config: CustomerPurchaseTrendInfoParams = {
    customer: props.customer,
    timespan: props.timespan,
  }
  return config
})

watchEffect(() => {
  if (config.value.customer)
    store.loadCustomerPurchaseTrend(config.value)
})

const loadingData = computed(() => store.purchaseTrendInfos.pending)

const dataKey = computed(() => {
  let key
  if (props.customer)
    key = props.customer.id

  return key
})

const purchaseTrend = computed(() => {
  let purchaseTrend
  if (dataKey.value !== undefined) {
    const map = store.purchaseTrendInfos.data
    if (map)
      purchaseTrend = map[dataKey.value]
  }
  // const map = store.getters.getCustomerPurchaseTrendInfos
  return purchaseTrend
})

const cardTitle = computed(() => {
  return props.title
})

const insufficientData = computed(() => {
  return !(purchaseTrend.value && purchaseTrend.value.frequency && purchaseTrend.value.frequency.values.length >= 2)
})

const data = computed(() => {
  const dataSets = []
  const labels: string[] = []
  if (purchaseTrend.value && purchaseTrend.value.frequency) {
    const frequencyGraph = purchaseTrend.value.frequency
    const func = dateLabelFormatFunction({year: 'numeric', month: 'long'}, labels)
    frequencyGraph.values.forEach(func)
    dataSets.push({
      label: 'Frequenz', // TODO i18n
      data: mapGraphValues(frequencyGraph),
      borderColor: 'rgb(236, 106, 29)',
      cubicInterpolationMode: 'monotone' as LineOptions['cubicInterpolationMode'],
    })
    const regressionGraph = purchaseTrend.value.regression
    if (regressionGraph !== undefined) {
      dataSets.push({
        label: 'Lineare Regression', // TODO i18n
        data: mapGraphValues(regressionGraph),
        borderColor: 'rgb(251, 216, 190)',
        cubicInterpolationMode: 'monotone' as LineOptions['cubicInterpolationMode'],
      })
    }
  }

  return {
    labels,
    datasets: dataSets,
  }
})

const options = ref({
  scales: {
    y: {
    },
  },
  plugins: {
    responsive: true,
    legend: {
      display: true,
      position: 'bottom',
      onHover: (event: ChartEvent) => {
        const target = event.native?.target
        if (target)
          (target as HTMLElement).style.cursor = 'pointer'
      },
      onLeave: (event: ChartEvent) => {
        const target = event.native?.target
        if (target)
          (target as HTMLElement).style.cursor = 'default'
      },
    },
  },
})

</script>
<style>
.trend-card{
  @apply w-full;
}

</style>
