import type {AxiosResponse} from 'axios'
import axiosInstance from '@/api/axios'
import type Page from '@/types/api/page.type'
import type PaginationListParameters from '@/api/modules/pagination-list-parameters'
import type Contract from '@/types/api/contract.type'
import BaseModule from '@/api/modules/base-module'
import type QueryParameters from '@/api/modules/query-parameters'
import type Customer from '@/types/api/customer.type'

export interface ContractListParams extends PaginationListParameters, QueryParameters {
  is_active?: boolean
  customer?: Customer
}

export interface ContractDetailParams {
  id?: number
}

class ContractModule extends BaseModule {
  getContracts(params: ContractListParams, controller?: AbortController): Promise<AxiosResponse<Page<Contract>>> {
    const signal = controller ? controller.signal : undefined
    const data = {
      params,
      signal,
    }
    return axiosInstance.get('/contracts/', data)
  }

  getContractDetails(params: ContractDetailParams, controller?: AbortController): Promise<AxiosResponse<Contract>> {
    const signal = controller ? controller.signal : undefined
    const id = params.id
    delete params.id
    const data = {
      params,
      signal,
    }
    return axiosInstance.get(`/contracts/${id}/`, data)
  }
}

const module = new ContractModule()
export default module
