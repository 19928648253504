import type {ComputedRef} from 'vue'
import {computed} from 'vue'
import {useUiFilterStore} from '@/stores/ui-filter'
import type {UIFilterPostParams} from '@/api/modules/ui-filter'
import {useAccountStore} from '@/stores/account'

export interface uiFilterForm extends UIFilterPostParams {
  show: URLSearchParams
  newUrl: string
}

export interface uiFilterUpdateForm extends uiFilterForm {
  id: number
}

export default function useUIFilterManagemant(accountId: number) {
  const accountStore = useAccountStore()
  const filter = computed(() => accountStore.getAccountUIFilter)

  const uiFilter: ComputedRef<uiFilterForm[] | uiFilterUpdateForm[] | undefined> = computed(() => {
    if (filter.value) {
      return filter.value.map((filter) => {
        const params = new URLSearchParams(filter.filter.url.split('?')[1])
        return {
          ...filter,
          show: params,
          newUrl: '',
        }
      })
    }
    else {
      return undefined
    }
  })
  const filterStore = useUiFilterStore()

  const updateUiFilter = (filter: uiFilterForm | uiFilterUpdateForm) => {
    accountStore.loadAccountUIFilter({user: accountId})
    const params = {
      filter: {
        url: filter.newUrl,
      },
      viewName: filter.viewName,
      isEditable: true,
      user: accountId,
    }
    // eslint-disable-next-line no-console
    console.log(params)
    if ('id' in filter && filter.id) filterStore.updateUifilter({...params, filterId: filter.id})
    else filterStore.postUifilter(params)

    accountStore.loadAccountUIFilter({user: accountId})
  }

  return {uiFilter, updateUiFilter}
}
