import type Right from '@/types/api/right.type'
import type {UIFilter} from '@/types/api/ui-filter.type'
import type Group from '@/types/api/group.type'

export default class Account {
  id?: number
  firstName?: string
  lastName?: string
  username?: string
  email?: string
  role?: number
  password?: string
  token?: string
  rights: Array<Right> = []
  groups: Array<Group> = []
  expiresIn?: string // TODO remove?
  uiFilter?: Array<UIFilter>
}
