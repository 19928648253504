import {defineStore} from 'pinia'
import api from '@/api'
import type {OrdersListParams} from '@/api/modules/orders'
import {PaginatedData} from '@/stores/index'
import type Order from '@/types/api/order.type'

interface State {
  orders: PaginatedData<Order>
}

export const useOrderStore = defineStore('order', {
  // see https://pinia.vuejs.org/core-concepts/state.html
  state: (): State => ({
    // define initial values
    orders: new PaginatedData<Order>(),
  }),
  // see https://pinia.vuejs.org/core-concepts/getters.html
  getters: {
    // define getters to access compound or calculated data
  },
  // see https://pinia.vuejs.org/core-concepts/actions.html
  actions: {

    /**
         * load orders
         * @param {OrdersListParams} config - the config for the call
         * @param cancel
         * @param requestID
         */
    async loadOrders(config: OrdersListParams, cancel = true, requestID?: string) {
      const id = requestID || api.order.getOrders.name
      if (cancel)
        api.order.cancel(id)
      this.orders.pending = true
      return api.order.cancelableCall(api.order.getOrders, config, id)
        .then((data) => {
          const page = data.data
          this.orders.total = page.count
          this.orders.data = page.results
          if (!api.order.hasPending(id))
            this.orders.pending = false
        }).catch((error) => {
          if (!api.order.hasPending(id))
            this.orders.pending = false
          throw error
        })
    },

    /**
         * resets the store to the initial state.
         */
    clearOrders() {
      this.$reset()
    },
  },
})
