import {ObjectTableHead} from '@singularit/kurz-components'

const dataTimestampDetailsTableHeades = [
  new ObjectTableHead({
    title: 'Name',
    attribute: 'model',
    sortable: false,
    id: 0,
    alignment: 'left',
  }),
  new ObjectTableHead({
    title: 'Neu',
    attribute: 'newDataSetsCount',
    sortable: false,
    id: 1,
    alignment: 'left',
  }),

  new ObjectTableHead({
    title: 'Geupdated',
    attribute: 'updatedDataSetsCount',
    sortable: false,
    id: 2,
    alignment: 'left',
  }),
]

export {dataTimestampDetailsTableHeades}
