<template>
  <k-card
    class="h-full"
    show-overflow
    header
  >
    <template #title>
      Benutzerrechte verwalten
    </template>
    <div class="mx-auto mt-10 w-3/5 flex flex-col gap-6">
      <div class="flex flex-row ">
        <span class="w-1/3 mt-1 font-bold">Benutzerrechte aktualisieren </span>
        <k-kurz-multi-select
          v-model="currentRight"
          placeholder="Benutzerrechte"
          clearable
          :options="rightOptions"
        />
      </div>
      <k-kurz-button
        class="w-1/6 mt-7 self-center"
        @click="updateRights"
      >
        Aktualisieren
      </k-kurz-button>
    </div>
  </k-card>
</template>

<script setup lang="ts">

import type {Ref} from 'vue'
import {computed, onBeforeMount, ref, toRef} from 'vue'
import {useToast} from 'vue-toastification'
import {useAccountStore} from '@/stores/account'
import type Right from '@/types/api/right.type'
import {RightName} from '@/types/api/right.type'
import type {AccountRightsPostParams} from '@/api/modules/accounts'

interface Props {
  accountId: number
}

const props = defineProps<Props>()
const user = toRef(props, 'accountId')

const currentRight = ref()
const store = useAccountStore()
const config: Ref<AccountRightsPostParams> = computed(() => {
  return {
    id: props.accountId,
    rights: currentRight.value,
  }
})

const toast = useToast()
const updateRights = () => {
  store.postAccountRights(config.value).then(() =>
    toast.success('Benutzerrechte wurden erfolgreich aktualisiert'))
    .catch(() => {
      toast.error('Benutzerrechte konnten nicht aktualisiert werden.')
    })
}

onBeforeMount(() => {
  store.loadAccountRights({id: props.accountId}).then((data: Right[]) => currentRight.value = data.map(right => right.id),
  )
})
const rightOptions = [
  {label: RightName.VK, value: 0},
  {label: RightName.USER, value: 1},
  {label: RightName.UI_FILTER, value: 2},
  {label: RightName.ADMIN, value: 3},
  {label: RightName.IMPORT, value: 4},
  {label: RightName.VKL, value: 5},
]

</script>

<style scoped>

</style>
