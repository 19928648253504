import type {Ref} from 'vue'
import {computed, watchEffect} from 'vue'
import {addWeeks, format, getISOWeek, getISOWeekYear} from 'date-fns'
import {currencyFormatter} from '@/utils/formatter'
import {useVkOverviewStore} from '@/stores/vk-overview'
import type {DashboardParams, VKOverviewParams} from '@/api/modules/dashboards'

export default function useOverview(watchedWeek: Ref<Date[]>, baseConfig: Ref<DashboardParams>) {
  const store = useVkOverviewStore()

  const date = computed(() => format(watchedWeek.value[0], 'yyyy-MM-dd'))

  const criticalWeekNumber = computed(() => getISOWeek(addWeeks(watchedWeek.value[0], 1)))
  const semiCriticalWeekNumber = computed(() => getISOWeek(addWeeks(watchedWeek.value[0], 2)))
  const criticalYear = computed(() => getISOWeekYear(addWeeks(watchedWeek.value[0], 1)))
  const semiCriticalYear = computed(() => getISOWeekYear(addWeeks(watchedWeek.value[0], 2)))

  const config = computed(() => {
    const config: VKOverviewParams = {
      timespan: 'week',
      date: date.value,
    }
    return {...config, ...baseConfig?.value}
  })

  watchEffect(() => {
    store.loadVkOverviews(config.value)
  })

  const loadingOverview = computed(() => {
    return store.pending
  })
  const invoiceData = computed(() => {
    return store.invoiceData
  })

  const offerData = computed(() => {
    return store.offerData
  })

  const revenueWatchedWeek = computed(() => {
    if (invoiceData.value && invoiceData.value.sum)
      return invoiceData.value.sum

    else
      return 0
  })

  const revenueString = computed(() => {
    return currencyFormatter.format(revenueWatchedWeek.value)
  })

  const revenueTarget = computed(() => {
    if (invoiceData.value && invoiceData.value.target)
      return invoiceData.value.target

    else
      return 0
  })

  const reachedGoal = computed(() => {
    if (revenueTarget.value)
      return Number(((100 / revenueTarget.value) * invoiceData.value.sum).toFixed(1))

    else
      return null
  })

  const criticalOfferCounts = computed(() => {
    let criticalOfferCount = 0
    let semiCriticalOfferCount = 0
    if (offerData.value && offerData.value.end) {
      for (const entry of offerData.value.end) {
        if (entry.number <= criticalWeekNumber.value && entry.year <= criticalYear.value)
          criticalOfferCount += entry.count

        else if (entry.number === semiCriticalWeekNumber.value && entry.year === semiCriticalYear.value)
          semiCriticalOfferCount += entry.count
      }
    }

    return {
      criticalOfferCount,
      semiCriticalOfferCount,
    }
  })

  return {
    revenueTarget,
    revenueString,
    reachedGoal,
    criticalOfferCounts,
    loadingOverview,
  }
}
