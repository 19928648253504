<template>
  <div
    class="h-full flex flex-col"
  >
    <h1 class="page-head">
      Den Status ändern
    </h1>
    <div>
      <div class="flex flex-col gap-3 m-5 pb-5">
        <label class="font-bold">Status</label>
        <p>derzeitiger Status: {{ stateOptions[currentValue?.state - 1].label }}</p>
        <k-base-radio-group
          v-if="stateRef.contract || stateRef.offer"
          v-model="status"
          :options="stateOptions"
          class="mx-0"
        />

        <div class="m-1">
          <label class="font-bold">Beauftragter</label>
          <p v-if="currentValue.appointee">
            derzeitiger Beauftragter: {{ currentAppointeeString }}
          </p>
          <k-select-input
            ref="appointeeSelect"
            v-model="appointee"
            :options="stateAppointeeOptions"
            class="max-w-xs"
            clear-able
            placeholder="Beauftragter"
            searchable
          />
        </div>

        <div class="text-center">
          <k-kurz-button
            text="Speichern"
            @clicked="saveState"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">

import {computed, ref, toRef, watch} from 'vue'
import type {RadioOption} from '@singularit/kurz-components'
import {useToast} from 'vue-toastification'
import type Offer from '@/types/api/offer.type'
import Status from '@/types/api/status.type'
import type Contract from '@/types/api/contract.type'
import useAllAccounts from '@/utils/behaviours/use-all-accounts'
import type Account from '@/types/api/account.type'
import api from '@/api'

const emit = defineEmits<{
  (e: 'save', stateRef: Status): void
}>()

interface Props {
  offer?: Offer
  contract?: Contract
}

const props = withDefaults(defineProps<Props>(), {
  offer: undefined,
  contract: undefined,
})

const currentValue = computed(() => {
  if (props.offer) {
    return {
      state: props.offer.state,
      appointee: props.offer.stateAppointee,
    }
  }
  else if (props.contract) {
    return {
      state: props.contract.state,
      appointee: props.contract.stateAppointee,
    }
  }
  return undefined
})

const currentAppointeeString = computed(() => {
  if (currentValue.value && currentValue.value.appointee) {
    const appointee = currentValue.value.appointee
    return `${appointee.firstName} ${appointee.lastName}`
  }
  return null
})

const stateOptions: RadioOption[] = [
  {label: 'Offen', value: 1},
  {label: 'In Absprache', value: 2},
  {label: 'Beendet', value: 3},
]

const status = ref<number | undefined>(currentValue?.value?.state)

watch(currentValue, (value) => {
  if (value && value.state !== 0 && status.value !== value.state)
    status.value = value.state
})

const appointee = ref<Account>()

const stateRef = computed(() => {
  const state = new Status()
  state.stateAppointee = appointee.value
  state.state = status.value
  if (props.offer)
    state.offer = toRef(props, 'offer').value

  else if (props.contract)
    state.contract = toRef(props, 'contract').value

  return state
})

const accounts = useAllAccounts()
const stateAppointeeOptions = computed(() => {
  const options = []
  for (const account of accounts.value) {
    options.push({
      label: `${account.firstName} ${account.lastName}`,
      value: account,
    })
  }
  return options
})

const toast = useToast()
const saveState = () => {
  if (stateRef.value) {
    api.status.saveState(stateRef.value).then((response) => {
      emit('save', stateRef.value)
      toast.success('Status erfolgreich gespeichert')
    }).catch((error) => {
      toast.error('Status konnte nicht gespeichert werden.')
      throw error
    })
  }
}

</script>

<style scoped>

</style>
