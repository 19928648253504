import type {Ref} from 'vue'
import {computed, unref, watchEffect} from 'vue'
import {useCustomerStore} from '@/stores/customer'
import type Customer from '@/types/api/customer.type'

export default function useCustomer(customerId: Ref<number> | number | undefined) {
  const store = useCustomerStore()
  const customerConfig = computed(() => {
    return {
      id: unref(customerId),
    }
  })
  watchEffect(() => {
    store.loadCustomer(customerConfig.value)
  })
  const customer = computed(() => {
    return store.customer.data as Customer
  })

  return customer
}
