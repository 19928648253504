import {ObjectTableHead, SlotTableHead} from '@singularit/kurz-components'
import type Customer from '@/types/api/customer.type'

const customerTableHeads = [
  new ObjectTableHead<Customer>({
    title: 'Name',
    attribute: 'name',
    sortable: true,
    id: 0,
  }),
  new ObjectTableHead<Customer>({
    title: 'ID',
    attribute: 'id',
    sortable: true,
    id: 1,
  }),
  new ObjectTableHead<Customer>({
    title: 'Land',
    attribute: 'countryCode',
    sortable: true,
    sortKey: 'country_code',
    id: 2,
  }),
  new SlotTableHead({
    title: 'Branche',
    sortable: true,
    id: 3,
    sortKey: 'branch__name',
    slotName: 'branch-slot',
  }),
  new SlotTableHead({
    title: 'zuständiger Mitarbeiter',
    sortable: false,
    id: 4,
    slotName: 'partner-slot',
  }),
]

export {customerTableHeads}
