<template>
  <k-card class="trend-card">
    <template #title>
      <span class="card-head flex place-content-between">
        <h2>{{ cardTitle }}</h2>
        <k-traffic-light
          class="mr-5"
          :value="calcTrendColor(trendInfo?.assessment)"
          :info="`Regression Koeffizient: ${numberFormatter.format(trendInfo?.assessment)}`"
        />
      </span>
    </template>
    <div
      v-if="loadingData"
      class="h-32 w-full flex justify-center"
    >
      <k-kurz-dot-loader class="self-center" />
    </div>
    <div
      v-else-if="insufficientData"
      class="flex justify-center items-center h-full w-full"
    >
      <h2>
        Nicht genügend Daten vorhanden
      </h2>
    </div>
    <div
      v-else
      class="flex flex-1"
    >
      <LineChart
        :chart-data="data"
        :options="options"
        class="m-5 h-full w-full pr-5 max-h-96"
      />
    </div>
  </k-card>
</template>

<script lang="ts" setup>
import {computed, ref, watchEffect} from 'vue'
import {LineChart} from 'vue-chart-3'
import type {ChartEvent, LineOptions} from 'chart.js'

import {calcTrendColor} from '@/utils/traffic-light-colors'
import type Customer from '@/types/api/customer.type'
import {currencyFormatter, numberFormatter} from '@/utils/formatter'
import {dateLabelFormatFunction, mapGraphValues} from '@/utils/charts/graph-mapper'
import type {CustomerTrendInfoParams} from '@/api/modules/trends'
import {useTrendStore} from '@/stores/trend'
import {useTrendInfo} from '@/utils/trends/use-trend-info'

interface Props {
  title: string
  customer: Customer
  months?: number
}
const props = defineProps<Props>()

const store = useTrendStore()
const config = computed(() => {
  const config: CustomerTrendInfoParams = {
    customer: props.customer,
    months: props.months,
  }
  return config
})

watchEffect(() => {
  if (config.value.customer) {
    if (config.value.months)
      store.loadTrendInfo(config.value, true, config.value.months.toString())
    else
      store.loadTrendInfo(config.value)
  }
})
const loadingData = computed(() => store.trendInfos.pending)

const trendInfo = computed(() => {
  const {trendInfo} = useTrendInfo(props.customer, props.months)
  return trendInfo.value
})

const cardTitle = computed(() => {
  return props.title
})

const insufficientData = computed(() => {
  return !(trendInfo.value && trendInfo.value.invoices && trendInfo.value.invoices.values.length >= 2)
})

const data = computed(() => {
  const dataSets = []
  const labels: string[] = []
  if (trendInfo.value && trendInfo.value.invoices) {
    const invoicesGraph = trendInfo.value.invoices
    const func = dateLabelFormatFunction({year: 'numeric', month: 'long'}, labels)
    invoicesGraph.values.forEach(func)
    dataSets.push({
      label: 'Umsatz', // TODO i18n
      data: mapGraphValues(invoicesGraph),
      borderColor: 'rgb(236, 106, 29)',
      cubicInterpolationMode: 'monotone' as LineOptions['cubicInterpolationMode'],
    })
    const regressionGraph = trendInfo.value.regression
    dataSets.push({
      label: 'Lineare Regression', // TODO i18n
      data: mapGraphValues(regressionGraph),
      borderColor: 'rgb(251, 216, 190)',
      cubicInterpolationMode: 'monotone' as LineOptions['cubicInterpolationMode'],
    })
    const distributedInvoices = trendInfo.value.distributedInvoices
    dataSets.push({
      label: 'Verteilter Umsatz', // TODO i18n
      data: mapGraphValues(distributedInvoices),
      borderColor: 'rgb(237, 170, 36)',
      cubicInterpolationMode: 'monotone' as LineOptions['cubicInterpolationMode'],
      hidden: true,
    })
    // const distributedRegression = trendInfo.value.distributedRegression
    // dataSets.push({
    //   label: 'Verteilte Lineare Regression', // TODO i18n
    //   data: mapGraphValues(distributedRegression),
    //   borderColor: 'rgb(183, 109, 252)',
    //   cubicInterpolationMode: 'monotone' as LineOptions['cubicInterpolationMode'],
    // })
  }

  return {
    labels,
    datasets: dataSets,
  }
})

const options = ref({
  scales: {
    y: {
      ticks: {
        callback(value: number) {
          return currencyFormatter.format(value)
        },
      },
    },
  },
  plugins: {
    responsive: true,
    legend: {
      display: true,
      position: 'bottom',
      onHover: (event: ChartEvent) => {
        const target = event.native?.target
        if (target)
          (target as HTMLElement).style.cursor = 'pointer'
      },
      onLeave: (event: ChartEvent) => {
        const target = event.native?.target
        if (target)
          (target as HTMLElement).style.cursor = 'default'
      },
    },
    tooltip: {
      callbacks: {
        label(context: typeof LineChart) {
          return currencyFormatter.format(context.raw)
        },
      },
    },
  },
})

</script>
<style>
.trend-card{
  @apply w-full;
  @apply xl:w-1/2;
  /*max-height: 30vh;*/
}

</style>
