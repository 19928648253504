<template>
  <div class="flex flex-col">
    <div class="flex">
      <h1
        class="page-head uppercase"
        :class="{'cursor-pointer': hierarchyFilter1 !== undefined}"
        @click="clearHierarchiesToLevel(0)"
      >
        {{ customer?.name }}, {{ customer?.id }} ({{ customer?.countryCode }})
      </h1>
      <div class="flex pl-5">
        <h1
          v-if="hierarchyFilter1 !== undefined"
          class="page-head uppercase cursor-pointer"
          @click="clearHierarchiesToLevel(1)"
        >
          {{ hierarchyFilter1?.number }}
        </h1>
        <div
          v-if="hierarchyFilter2 !== undefined"
          class="flex"
        >
          <h1 class="text-3xl font-bold uppercase my-2">
            /
          </h1>
          <h1
            class="page-head uppercase cursor-pointer"
            @click="clearHierarchiesToLevel(2)"
          >
            {{ hierarchyFilter2?.number }}
          </h1>
        </div>
      </div>
      <ImportTimestamp
        class="items-center mr-4 ml-auto"
        dependant-on="customers"
      />
    </div>
    <div class="customer-details-layout mb-10">
      <k-card class="xl:col-span-1">
        <template #title>
          <span class="card-head">
            <h2>Allgemeine Informationen</h2>
          </span>
        </template>
        <div
          v-if="loadingData"
          class="h-32 w-full flex justify-center"
        >
          <k-kurz-dot-loader class="self-center" />
        </div>
        <k-list-layout
          v-else
          class="w-full"
        >
          <k-list-entry
            label="Rechnungsdaten verfügbar seit"
            :value="firstInvoiceDate"
          />
          <k-list-entry
            label="letzte Datenaktualisierung"
            :value="updatedDate"
          />
          <k-list-entry
            label="Branche"
            :value="customer?.branch?.name"
          />
          <k-list-entry
            label="Offene Kontrakte"
            :value="customerDetails?.openContracts"
            info-text="Die offenen Kontrakte beinhalten alle Kontrakte, in denen der Meilenstein noch nicht Z ist und der Kontrakt nicht händisch deaktiviert wurde."
          >
            <div class="text-left w-1/2">
              <span>{{ customerDetails?.openContracts }}</span>
            </div>
          </k-list-entry>
          <k-list-entry
            label="Offene Angebote"
            :value="customerDetails?.openOffers"
            info-text="Ein Angebot ist offen, wenn es nicht älter als 3 Monate ist und keinen dazugehörigen Kontrakt oder Auftrag hat."
          />
          <k-list-entry
            label="Letzte Rechnung"
            :value="lastInvoiceDate"
          />
          <k-list-entry
            label="Gesamtumsatz"
            :value="totalRevenue"
          />
          <k-list-entry
            label="durchnittl. Jahresumsatz"
            :info-text="averageYearlyRevenueDescription"
            :value="averageYearlyRevenue"
          />
          <k-list-entry
            :label="`Umsatz ${revenueYears[0]}`"
            :value="year1Value"
          />
          <k-list-entry
            :label="`Umsatz ${revenueYears[1]}`"
            :value="year2Value"
          />
          <k-list-entry
            :label="`Umsatz ${revenueYears[2]}`"
            :value="year3Value"
          />
          <k-list-entry
            label="Kurzfristiger Trend"
            info-text="Trend der letzten der 3 Monate"
            :value="customer?.id"
          >
            <template #icon>
              <router-link
                class="link"
                :to="{ name: 'customer-trend-detail', params: { contractId: customer?.id } }"
              >
                <font-awesome-icon
                  class="icon"
                  icon="external-link-alt"
                />
              </router-link>
            </template>
            <div class="text-left w-1/2">
              <k-traffic-light
                :value="calcTrendColor(trendInfo?.assessment)"
              />
            </div>
          </k-list-entry>
        </k-list-layout>
      </k-card>
      <k-card class="xl:col-span-1">
        <template #title>
          <span class="card-head">
            <h2>Gesamtumsatz</h2>
          </span>
        </template>
        <div class="w-full flex flex-col h-full">
          <k-base-radio-group
            v-model="timespan"
            class="mx-5"
            :horizontal="true"
            :options="timeOptions"
          />

          <div
            v-if="loadingChart"
            class="h-full w-full flex justify-center"
          >
            <k-kurz-dot-loader class="self-center" />
          </div>
          <LineChart
            v-else
            :chart-data="data"
            :options="options"
            class="m-5 h-full w-full pr-5"
            :height="300"
          />
        </div>
      </k-card>
    </div>
    <k-tab-component
      class="col-span-2 mt-5"
      :titles="tabs"
    >
      <template #Hierarchien>
        <CustomerHierarchyTab :customer="customer" />
      </template>

      <template #Feedback>
        <CustomerFeedbackTab :customer="customer" />
      </template>
      <template #Angebote>
        <CustomerOfferTab :customer="customer" />
      </template>
      <template #Kontrakte>
        <CustomerContractTab :customer="customer" />
      </template>
      <template #Rechnungen>
        <CustomerInvoiceTab :customer="customer" />
      </template>
    </k-tab-component>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, watchEffect} from 'vue'
import {LineChart} from 'vue-chart-3'
import {useRouter} from 'vue-router'
import {calcTrendColor} from '@/utils/traffic-light-colors'
import useInvoicesChart from '@/views/customer/customer-detail/use-invoices-chart'
import type {CustomerDetailParams} from '@/api/modules/customers'
import {useComputedCurrency, useComputedDate} from '@/utils/behaviours/use-formatter'
import range from '@/utils/range'
import useRevenueYear from '@/views/customer/customer-detail/use-revenue-per-year'
import CustomerOfferTab from '@/views/customer/customer-detail/CustomerOfferTab.vue'
import CustomerInvoiceTab from '@/views/customer/customer-detail/CustomerInvoiceTab.vue'
import CustomerHierarchyTab from '@/views/customer/customer-detail/CustomerHierarchyTab.vue'
import CustomerContractTab from '@/views/customer/customer-detail/CustomerContractTab.vue'
import ImportTimestamp from '@/components/timestamp/ImportTimestamp.vue'
import CustomerFeedbackTab from '@/views/customer/customer-detail/CustomerFeedbackTab.vue'
import useHierarchyFilter from '@/views/customer/customer-detail/use-hierarchy-filter'
import {currencyFormatter} from '@/utils/formatter'
import {useCustomerStore} from '@/stores/customer'
import type Customer from '@/types/api/customer.type'
import type CustomerDetail from '@/types/api/customer-detail.type'
import {useTrendStore} from '@/stores/trend'
import type {CustomerTrendInfoParams} from '@/api/modules/trends'
import {useTrendInfo} from '@/utils/trends/use-trend-info'

export default defineComponent({
  name: 'CustomersDetailView',
  components: {
    CustomerFeedbackTab,
    ImportTimestamp,
    CustomerContractTab,
    CustomerInvoiceTab,
    CustomerOfferTab,
    CustomerHierarchyTab,
    LineChart,
  },
  props: {customerId: Number},

  setup(props) {
    const store = useCustomerStore()
    const trendStore = useTrendStore()
    const router = useRouter()
    const currentYear = new Date().getFullYear() // 2020
    const revenueYears = range(3, currentYear - 2).reverse()

    const {hierarchyFilter1, hierarchyFilter2} = useHierarchyFilter()

    const clearHierarchiesToLevel = (level: number) => {
      if (level < 2)
        hierarchyFilter2.value = undefined

      if (level < 1)
        hierarchyFilter1.value = undefined
    }

    const customerConfig = computed(() => {
      return {
        id: props.customerId,
      }
    })
    const customerDetailConfig = computed(() => {
      const config: CustomerDetailParams = {
        id: props.customerId,
        hierarchy1: hierarchyFilter1.value,
        hierarchy2: hierarchyFilter2.value,
        revenue_years: revenueYears,
      }
      return config
    })

    watchEffect(() => {
      store.loadCustomer(customerConfig.value).catch(() =>
        router.push({name: 'customers-list'}),
      )
    })

    watchEffect(() => {
      store.loadCustomerDetails(customerDetailConfig.value)
    })

    const loadingData = computed(() => store.customerDetails.pending)

    const customer = computed(() => store.customer.data as Customer)
    const customerDetails = computed(() => store.customerDetails.data as CustomerDetail)

    const {data, options, timeOptions, timespan, loadingChart} = useInvoicesChart(customer)
    const tabs = ['Kontrakte', 'Angebote', 'Rechnungen', 'Hierarchien', 'Feedback']

    // TODO ändern sobald daten in customer details sind
    const createdDate = useComputedDate(customer, 'created')
    const updatedDate = useComputedDate(customer, 'updated')

    const lastInvoiceDate = useComputedDate(customerDetails, 'lastInvoiceDate')
    const firstInvoiceDate = useComputedDate(customerDetails, 'firstInvoiceDate')
    const totalRevenue = useComputedCurrency(customerDetails, 'totalRevenue')
    const averageYearlyRevenue = computed(() => {
      if (customerDetails.value && customerDetails.value.averageYearlyRevenue)
        return currencyFormatter.format(customerDetails.value.averageYearlyRevenue.revenue)

      else
        return null
    })

    const averageYearlyRevenueDescription = computed(() => {
      if (customerDetails.value && customerDetails.value.averageYearlyRevenue) {
        const description = 'Berechnet aus den vollständigen Jahresumsätzen von '
        const prevYear = new Date().getFullYear() - 1
        if (customerDetails.value.averageYearlyRevenue.startingYear) {
          if (customerDetails.value.averageYearlyRevenue.startingYear === prevYear)
            return description.concat(`${prevYear}`)

          else
            return description.concat(`${customerDetails.value.averageYearlyRevenue.startingYear} bis ${prevYear}`)
        }
      }
      return 'Zu diesem Kunden is noch kein vollständiges Umsatzjahr vorhanden'
    })

    const year1Value = useRevenueYear(customerDetails, revenueYears[0])
    const year2Value = useRevenueYear(customerDetails, revenueYears[1])
    const year3Value = useRevenueYear(customerDetails, revenueYears[2])

    watchEffect(() => {
      const config: CustomerTrendInfoParams = {
        customer: customer.value,
        months: 3,
      }
      if (customer.value)
        trendStore.loadTrendInfo(config)
    })

    const {trendInfo} = useTrendInfo(customer, 3)

    return {
      customer,
      customerDetails,
      loadingData,

      firstInvoiceDate,
      lastInvoiceDate,
      totalRevenue,
      averageYearlyRevenue,
      averageYearlyRevenueDescription,

      revenueYears,

      tabs,
      data,
      options,
      timeOptions,
      timespan,
      loadingChart,

      year1Value,
      year2Value,
      year3Value,

      createdDate,
      updatedDate,

      hierarchyFilter1,
      hierarchyFilter2,
      clearHierarchiesToLevel,

      trendInfo,
      calcTrendColor,
    }
  },
})
</script>

<style scoped>

.customer-details-layout {
  @apply flex flex-col;
  @apply xl:grid xl:grid-cols-2 xl:gap-4;
  @apply xl:overflow-y-hidden;
  @apply xl:h-full;
}

</style>
